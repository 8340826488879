import L from "leaflet"
import "leaflet/dist/leaflet.css"
import React, { useEffect, useRef, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import AppState from "../../../js/AppState"
import SGMSClient from "../../../js/SGMS/SGMSClient"
import ToolkitMap from '../../../js/ToolkitMap'
import BurgerMenu from "../../Shared/BurgerMenu/BurgerMenu"
import SidePanel from '../../Shared/SidePanel/SidePanel'
import './Map.css'

const icon = L.divIcon({className: 'map-marker', iconSize: [20, 20]});
const zoomOptions = {duration: 1.2}

const Map = () => {

  const [currentMarker, setCurrentMarker] = useState(null);
  const [sidepanelVisible, setSidepanelVisible] = useState(false);

  const mapRef = useRef(null)
  const selectedMarkerRef = useRef(null)




  function handleMarkerClick(marker, mapMarker) {
    SGMSClient.analyticsApi.action("map.marker." + marker.name + ".select")
    SGMSClient.analyticsApi.contentShow("map.placeinfo." + marker.name)

    setSidepanelVisible(true)
    setCurrentMarker(marker)

    // switch active map marker
    mapMarkerNormalStyle()
    selectedMarkerRef.current = mapMarker
    mapMarkerActiveStyle()


    // mapRef.current.flyTo(marker.coords, 10, zoomOptions)
    mapRef.current.flyToBounds([marker.coords, marker.coords], {maxZoom: 10})
  }


  function handleClosePanel() {
    SGMSClient.analyticsApi.action("map.btn.panelclose.select")
    SGMSClient.analyticsApi.contentHide("map.placeinfo." + currentMarker.name)

    setSidepanelVisible(false)
    mapMarkerNormalStyle()

    mapRef.current.flyToBounds(ToolkitMap.bounds, zoomOptions)
  }

  function handlePanelClosed() {
    setCurrentMarker(null)

  }


  function mapMarkerActiveStyle() {
    if (selectedMarkerRef.current) {
      selectedMarkerRef.current._icon.classList.add("active")
    }
  }

  function mapMarkerNormalStyle() {
    if (selectedMarkerRef.current) {
      selectedMarkerRef.current._icon.classList.remove("active")
    }
    selectedMarkerRef.current = null
  }

  useEffect(() => {
    mapRef.current = L.map('map-ctnr', {
      maxBoundsViscosity: 1.0
    })

    mapRef.current.zoomControl.setPosition('bottomright')

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1Ijoic3VjY3VidXMtc2VydmljZSIsImEiOiJja3F3ZjF2a3owbDc5Mm5wYzducDQ5OHZiIn0.jZqxcCpLfAYpJNDWCJnq8A'
    })
    .addTo(mapRef.current)

    ToolkitMap.all
    .filter(marker => marker.name !== null)
    .forEach(marker => {
      L.marker(marker.coords, {icon})
      // L.circleMarker(marker.coords, markerStyle)
      .addTo(mapRef.current)
      .bindTooltip(marker.name)
      .on('click', e => handleMarkerClick(marker, e.target))

    })

    const bounds = ToolkitMap.bounds

    mapRef.current.fitBounds(bounds)
    mapRef.current.setMaxBounds(bounds.pad(0.3))
    mapRef.current.setMinZoom(mapRef.current.getZoom())

    mapRef.current.on('contextmenu', ()=>{
      console.log("ZOOM", mapRef.current.getZoom())
    })



    return () => {
      mapRef.current.remove()
    }

  }, []);

  const top = <div className="map-panel-close"><FaChevronLeft onClick={handleClosePanel} /></div>


  return (
    <div className={"Map" + (sidepanelVisible ? " padded" : "")}>
      <div id="map-ctnr" />

      <SidePanel top={top} visible={sidepanelVisible} onClosed={handlePanelClosed} >
        {currentMarker && <>
          <img height="100" className="logo" src={currentMarker.logo_path} alt="logo" />
          <div className="marker-location">
            <h1>{currentMarker.country}</h1>
            <h3>{currentMarker.city}</h3>
          </div>
          <h2>{currentMarker.name}</h2>

          <img className="image" src={currentMarker.img_path} alt="image" />
          <p className="description">{currentMarker.description}</p>

          {currentMarker.links.map(l => {
            return <p key={l.href}>
              <a href={l.href} target="_blank">{l.text}</a>
            </p>
          })}
        </>}
      </SidePanel>


      <BurgerMenu />

      {/* <Button
      className="map-backbutton"
      onClick={() => AppState.setScreen(SCREENS.MENU)}>Menu</Button> */}

    </div>
  );
};

export default Map;
