import { makeAutoObservable, toJS } from "mobx";
import Axes from './Axes';
import Cards from "./Cards";
import Categories from "./Categories";
import Characters from "./Characters";
import Criterias from "./Criterias";
import DataLoading from './DataLoading';
import FtueManager from "./FtueManager";
import Places from "./Places";
import Project from "./Project";
import Projects from "./Projects";
import ScaleManager from "./ScaleManager";
import SequencesManager, { SequencesFile } from "./SequencesManager/SequencesManager";
import Openid from "./SGMS/Openid";
import SGMSClient from "./SGMS/SGMSClient";
import Sounds from "./Sounds";
import Texts from "./Texts";
import ToolkitMap from "./ToolkitMap";


function getUrlVar(name) {
  var url = new URL(window.location.href);
  return url.searchParams.get(name);
}


export const SCREENS = {
  LOADING :"LOADING",
  MENU: "MENU",
  LOGIN :"LOGIN",

  TOOLKIT_MAP: "TOOLKIT_MAP",
  TOOLKIT_QUIZ: "TOOLKIT_QUIZ",

  START :"START",

  PHASE1: "PHASE1",
  PHASE2 :"PHASE2",
  PHASE3 :"PHASE3",
  WRAPUP :"WRAPUP"
}

const GAME_SCREENS = [ SCREENS.PHASE1, SCREENS.PHASE2, SCREENS.PHASE3, SCREENS.WRAPUP ]




class AppState {

  debugMode = false
  savedGame = null

  numPartie = 1 // lors de la première partie, seul l'axe 1 est unlock, puis ensuite on unlocke les autres

  screen = SCREENS.LOADING
  showBoussolePopup = false // ca peut être false|true, ou une id de Criteria, ca le fera clignoter dans la boussole

  menuShowSavegamePopup = false

  openid = null


  showMeetingOverlay = false

  constructor() {
    this.debugMode = getUrlVar("debug")
    makeAutoObservable(this)

    const { ServerIP, ServerPort, LocalStorageUserKey, Universe, App } = window.CONFIG.server
    SGMSClient.init('wss://' + ServerIP, ServerPort)

    this.openid = new Openid(window.CONFIG.keycloak)
    ScaleManager.init(1600, 900)

    const {genericFiles, root} = window.CONFIG

    DataLoading
    .getAllFiles(genericFiles, root)
    .then(() => {
      Texts.init(DataLoading.files.texts)
      FtueManager.init(DataLoading.files.ftue)
      Sounds.init(DataLoading.files.sounds)
      Places.init(DataLoading.files.places) // on charge les places dès maintenant pour les utiliser dans preloadAudios

      this.loadSavegame()

      window.SpeechAPI.setLogLevel(5)

      return SGMSClient.anonymousReconnect(Universe, App, LocalStorageUserKey)
    })
    .then(() => {
      // this.loadMap() // DEBUG MAP
      // this.loadQuiz() // DEBUG QUIZ

      this.setScreen(SCREENS.MENU)

      window.SpeechAPI.enableBackToNeutral(true, 0.5)

      this.preloadGameAudios()
      this.preloadGameCharacters()


      // debug load
      // this.loadPhase1(true)
      // .then(() => Axes.select("AXE_LIEU_UTILITE_PUBLIQUE"))
      // .then(() => Projects.select("culturalCentre"))
      // .then(() => this.loadPhase2())
      // .then(() => this.loadPhase3())
      // .then(() => {
      //   Projects.selected.categories.forEach(c => c.playableCards[0].pick())
      //   Projects.submit()
      //   this.loadPhase4()

      //   FtueManager.visible = false
      // })
    })
    .catch(err => {
      console.log(err)
    })
  }

  setScreen(id) {
    SGMSClient.analyticsApi.navigationLeave(this.screen)
    this.screen = id
    SGMSClient.analyticsApi.navigationEnter(this.screen)

    this.meetingSendScores()

    if(id === SCREENS.MENU)   Sounds.playMusic("loop1")
    if(id === SCREENS.PHASE2)   Sounds.playMusic("loop4")
    if(id === SCREENS.PHASE3)   Sounds.playMusic("loop6")
    if(id === SCREENS.WRAPUP)   Sounds.playMusic("loop5")

    if(id === SCREENS.TOOLKIT_QUIZ)   Sounds.playMusic("loop4")
    if(id === SCREENS.TOOLKIT_MAP)   Sounds.playMusic("loop4")

  }

  savetimeout = null
  saveGame(eraseAllButNumPartie = false) {
    // petit délai, car parfois les save peuvent arriver en rafale
    clearTimeout(this.savetimeout)
    this.savetimeout = setTimeout(() => {
      let save = {}
      if(!eraseAllButNumPartie) {
        save= {
          screen: this.screen,
          places: Places.save(),
          axes: Axes.save(),
          projects: Projects.save(),
          categories: Categories.save(),
          cards: Cards.save(),
          criterias: Criterias.save(),
          sequenceManager: SequencesManager.stateToJson(),
        }
      }
      save.numPartie = this.numPartie
      save.date = new Date().getTime()

      this.savedGame = save

      if(!SGMSClient.currentUser || SGMSClient.isAnonymous) {
        localStorage.setItem(window.CONFIG.localStorageSavegameKey, JSON.stringify(save))
      } else {
        // on est connect !

        SGMSClient.userUpdateCustomData(save)
        .then(() => {
          console.log("SAVED IN CUSTOMDATAS !")
        })
      }



    }, 500)
  }

  loadSavegame() {
    const data = localStorage.getItem(window.CONFIG.localStorageSavegameKey)
    if(data) {
      try {
        this.savedGame = JSON.parse(data)
        // console.log("this.savedGame", toJS(this.savedGame))
      }
      catch(err) {
        console.log("Error extracting saved game", err)
      }
    }else {
      this.savedGame = null // on force si on se déco du profil, il faut effacer la sauvegarde
    }
  }

  checkProfileSavegame() {
    if(!SGMSClient.userCustomDatas) return

    if(this.savedGame && this.savedGame !== SGMSClient.userCustomDatas) { // si meme date => meme sauvegarde !
      this.menuShowSavegamePopup = true
    } else {
      this.savedGame = SGMSClient.userCustomDatas
    }
  }


  preloadGameAudios() {

    // 1 on charge les fichiers de dial
    let proms = Places.all
    .filter(p => p.preload)
    .map(place => place.loadDials())

    Promise.all(proms)
    .then((dialsArray) => {
      // ici tous les fichiers de dial sont chargés
      const {audios_path} = window.CONFIG.speech
      let audios = []
      dialsArray.forEach(dials => {
        dials.forEach(dial => {

          if(dial.Type !== "choice_answer" && dial.Audio) {
            // if(dial.Audio.indexOf('choice') > -1) console.log("dial", dial)
            audios.push(audios_path + dial.Audio)
          }
        })
      })


      // console.log("audios", audios)

      window.SpeechAPI.streamAudios(audios)


    })



  }

  preloadGameCharacters() {

    let persos = ["mark", "monica",  "leonarda", "archie", "naomi",  "angela", "prof", "susan", "david", "randolph"]
    .map(k => window.CONFIG.speech.persos[k])

    window.SpeechAPI.streamCharacters(persos)
  }


  loadMenu() {
    FtueManager.hide()
    this.setScreen(SCREENS.MENU)
  }
  loadPhase1(eraseSave) {
    SGMSClient.analyticsApi.action("mainmenu.btn.game.select")

    if(eraseSave) {
      this.savedGame = null
      localStorage.removeItem(window.CONFIG.localStorageSavegameKey)
    }

    this.setScreen(SCREENS.LOADING)

    return DataLoading
    .getAllFiles(window.CONFIG.gameFiles, window.CONFIG.root)
    .then(() => {
      FtueManager.init(DataLoading.files.ftue)
      Places.reset()
      Characters.init(DataLoading.files.characters)
      Criterias.init(DataLoading.files.base_criterias)
      Axes.init(DataLoading.files.axes)
      Projects.init(DataLoading.files.projects)
    })
    .then(() => {
      if(this.savedGame) {
        // ici on repeuple les données sauvées
        Places.load(this.savedGame.places)
        Axes.load(this.savedGame.axes)
        Projects.load(this.savedGame.projects)
        Criterias.load(this.savedGame.criterias)
        if(this.savedGame.sequenceManager) SequencesManager.stateFromJson(this.savedGame.sequenceManager)

        this.numPartie = this.savedGame.numPartie


      }
    })
    .then(() => {
      // ici on regarde s'il faut aller en ph1 ou 2 ou 3
      if(this.savedGame && this.savedGame.screen && this.savedGame.screen !== SCREENS.PHASE1) {
        return this.loadPhase2(true)
      } else {
        this.setScreen(SCREENS.PHASE1)
      }



    })
    .catch(err => {
      console.log(err)
    })
  }

  loadPhase2(loadSavedGame = false) {
    this.setScreen(SCREENS.LOADING)

    let axeId = Axes.selected.id

    return DataLoading
    .getAllFiles(window.CONFIG.phase2Files, window.CONFIG.root + axeId + "/")
    .then( () => {
      Criterias.init(DataLoading.files.criterias) // on écrase les critères
      Criterias.setAllVisible()
      Categories.init(DataLoading.files.categories)
      Cards.init(DataLoading.files.cards)
    })
    .then(() => Categories.loadDialsFiles(axeId))
    .then(() => {
      if(loadSavedGame) {
        // ici on repeuple les données sauvées
        Cards.load(this.savedGame.cards)
        Categories.load(this.savedGame.categories)
        console.log("Categories", toJS(Categories))
        if(this.savedGame.sequenceManager) SequencesManager.stateFromJson(this.savedGame.sequenceManager)

        Criterias.load(this.savedGame.criterias)


        this.setScreen(this.savedGame.screen)
      } else {
        this.setScreen(SCREENS.PHASE2)
      }
    })
    .catch(err => console.log(err))

  }

  loadPhase3() {
    this.setScreen(SCREENS.PHASE3)
  }

  loadPhase4() {
    this.setScreen(SCREENS.WRAPUP)
  }

  loadMap() {
    SGMSClient.analyticsApi.action("mainmenu.btn.map.select")

    this.setScreen(SCREENS.LOADING)

    DataLoading
    .getAllFiles(window.CONFIG.mapFiles, window.CONFIG.root)
    .then(() => {
      ToolkitMap.init(DataLoading.files.map)
      this.setScreen(SCREENS.TOOLKIT_MAP)

    })
  }

  get currentlyOnGameScreen() {

    return GAME_SCREENS.includes(this.screen)

  }




  loadQuiz() {
    SGMSClient.analyticsApi.action("mainmenu.btn.quizz.select")

    this.setScreen(SCREENS.LOADING)

    DataLoading
    .getAllFiles(window.CONFIG.quizFiles, window.CONFIG.root)
    .then(() => {
      const data = SequencesFile.createFromDataFile("quiz", DataLoading.files.quiz.filter(row => row.show))
      SequencesManager.addFile(data)
      this.setScreen(SCREENS.TOOLKIT_QUIZ)
    })
  }


  registerMeetingsEvents() {
    SGMSClient.sequenceManager.registerChangeCallback((event) => {

      if(event.Type === 'PERIOD_STARTED') {

        if(this.showMeetingOverlay) {
          this.showMeetingOverlay = false
          // unpause la speechapi !
          window.SpeechAPI.resume()
        }

        if(event.Period === 0 && event.PeriodProgress === 0) {
          this.savedGame = null
          this.loadPhase1()
        }

        if(event.Period === 1 && event.PeriodProgress === 0 && this.screen === SCREENS.PHASE1) {
          //select axe et project, puis lance phase 2 !
          this.savedGame = null

          if(!Axes.selected || !Projects.selected) {
            let { defaultAxe, defaultProject} = SGMSClient.currentMeeting.Config.Config

            Axes.select(defaultAxe)
            Projects.select(defaultProject)
          }

          this.loadPhase2(false)
        }

        if(event.Period === 2 && event.PeriodProgress === 0 && this.screen === SCREENS.PHASE2) {
          this.loadPhase3()
        }
        if(event.Period === 3 && event.PeriodProgress === 0 && this.screen === SCREENS.PHASE3) {
          this.loadPhase4()
        }
      }

      if(event.Type === 'PERIOD_STOPPED' || event.Type === 'PERIOD_PAUSED') {
        if(this.currentlyOnGameScreen) {
          this.showMeetingOverlay = true
          window.SpeechAPI.pause()
        }

      }

      if(event.Type === 'SEQUENCE_FINISHED') {
        this.showMeetingOverlay = false
        this.loadMenu()

      }



      console.log("la seq manager a été mis à jour...", event)
      console.log("SGMSClient.sequenceManager.periodIndex", SGMSClient.sequenceManager.periodIndex)
      console.log("SGMSClient.sequenceManager.periodsTotal", SGMSClient.sequenceManager.periodsTotal)
    })

    SGMSClient.addMessageListener((from_user_id, message) => {
      if(message.Name === 'SEND_STATUS') {
        this.meetingSendStatus()
      }
    })

  }

  meetingSendScores (score) {
    if(!SGMSClient.currentMeeting) return


    SGMSClient.sendScores({
      screen: this.screen,
      selectedAxe: Axes.selected ? Axes.selected.name : '-',
      selectedProject: Projects.selected ? Projects.selected.name : "-",
      score: score ? score : "-"
    })

  }


  meetingSendStatus() {
    let ready = this.showMeetingOverlay

    SGMSClient.sendMessage("USER_READY", ready, true)
  }

  // TODO
  restart() {
    this.setScreen(SCREENS.LOADING)
  }
}

export default new AppState()
