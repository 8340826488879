import { makeAutoObservable } from "mobx"
import AppState from "./AppState"
import Axes from "./Axes"
import Criterias from "./Criterias"
import DataLoading from "./DataLoading"
import FtueManager from "./FtueManager"
import SequencesManager, {SequencesFile} from "./SequencesManager/SequencesManager"
import SGMSClient from "./SGMS/SGMSClient"

class Place{
  dials = null
  unlockedDialsSequences = []
  notified = false

  forceUnlocked = false // permet de unlocked un endroit si on le skip

  constructor(data) {
    Object.assign(this, data)
    makeAutoObservable(this, {dials: false})
    this.notified = data.first_sequence_unlocked

    if(AppState.debugMode === "1") {
      this.notified = true
    }
  }


  reset() {
    this.notified = this.first_sequence_unlocked
    this.unlockedDialsSequences = []
  }

  loadDials() {
    if(this.dials) return Promise.resolve()

    let {root, ph1dialsFolder} = window.CONFIG
    // charger le fichier de dial
    let fileurl =  root +  "/" + ph1dialsFolder + this.id + ".data"

    return DataLoading.getFile(fileurl, this.id)
    .then(file => this.dials = file.data)

  }

  openDials() {

    return this.loadDials()
    .then(() => {
      const data = SequencesFile.createFromDataFile(this.id, this.dials)
      SequencesManager.addFile(data)

      if(this.first_sequence_unlocked) {
        let firstSeqId = SequencesManager.findFile(this.id).elements[0].SequenceId
        this.unlockSequence(firstSeqId)
      }

      if(AppState.debugMode === "1") {
        let firstSeqId = this.dials[0].SequenceID

        console.log("firstSeqId", firstSeqId)
        this.unlockSequence(firstSeqId)
      }
    })
    .then(() => {

      const {level_path, persos} = window.CONFIG.speech

      let first_talking_perso = this.persos[0]


      let perso_champ_id = this.persos.find(p => p !== this.correspondant)
      let perso_champ = persos[perso_champ_id]
      let perso_contrechamp = persos[this.correspondant]

      let level = !Axes.selected || Axes.selected.index < 2 ? level_path + this.id + ".level" : level_path + this.id + "_axe3.level"

      return new Promise( (resolve, reject) => {

        window.SpeechAPI.prepareScene(level, perso_champ, perso_contrechamp, first_talking_perso, () =>{
          if(this.notified) {
            SequencesManager.playFile(this.id, this.lastUnlockedSequence)
          }


          resolve()
        })
      })
    })

  }

  unlockSequence(seqId) {
    if(this.unlockedDialsSequences.indexOf(seqId) === -1) {
      this.unlockedDialsSequences.push(seqId)
      this.notified = true
    }
  }


  get persos() {
    if(!this.dials) return null

    let persos = []

    //  garder la séquence qui nous intéresse
    this.dials
    .filter(d => d.SequenceID === this.lastUnlockedSequence)
    .forEach(d => {
      if(
        persos.indexOf(d.Character) === -1 &&
        d.Character !== null
        // && d.Character !== this.correspondant
      ) {
        persos.push(d.Character)
      }

    })
    if(persos.length > 2) alert("Il y a trop de persos différents dans le séquence " + this.lastUnlockedSequence + ". Maximum: 2")

    // persos.push(this.correspondant) // on ajoute toujours le correspondant en 2eme, c'est le contrechamp

    return persos
  }


  get unlocked() {
    return this.notified || this.unlockedDialsSequences.length > 0 || this.forceUnlocked
  }

  get lastUnlockedSequence() {
    return this.unlockedDialsSequences[this.unlockedDialsSequences.length - 1]
  }

  get saveData() {
    return {
      id: this.id,
      unlockedDialsSequences: this.unlockedDialsSequences,
      notified: this.notified
    }
  }

}


class Places {

  selected = null
  constructor() {
    makeAutoObservable(this)
  }

  nbAsksForAxeChoicePopup = 0
  nbAsksForProjectChoicePopup = 0
  projectChoicePopupVisible = false

  init(data) {
    this.all = data
    .filter(p => p.id !== null)
    .map(p => new Place(p))




    SequencesManager.registerAction("OPEN_COMPASS", ([id]) => {

      let crit = Criterias.get(id)
      if(crit) {
        crit.notified = true
        crit.visible = true
        AppState.showBoussolePopup = true
      }
      else {
        console.log("Error : no criteria with id:", id)
      }
    })


    SequencesManager.registerAction("OPEN_AXE_CHOICE", () => {
      this.projectChoicePopupVisible = 1
      AppState.saveGame()
    })

    SequencesManager.registerAction("OPEN_PROJECT_CHOICE", () => {
      this.projectChoicePopupVisible = 2
      AppState.saveGame()
    })
  }

  reset() {
    // on veut pouvoir reset les Places si on revient au menu
    this.selected = null

    this.nbAsksForAxeChoicePopup = 0
    this.nbAsksForProjectChoicePopup = 0
    this.projectChoicePopupVisible = false

    this.all.map(p => p.reset())
  }

  get(id) {
    return this.all.find(c => c.id === id)
  }

  select(place) {
    SGMSClient.analyticsApi.navigationEnter("ph1.place." + place.id)
    this.selected = place
  }

  unlockSequences(data) {
    if(!data)
      return
    let unlocks = data.split(";")

    unlocks.forEach(unlock => {
      let [placeId, SequenceID] = unlock.split("/")

      let place = this.get(placeId)
      if(place) {
         place.unlockSequence(SequenceID)
         SGMSClient.analyticsApi.score("ph1.unlocksequences", placeId);
      }
    })
  }

  save() {
    return {
      all: this.all.map(p => p.saveData),
      nbAsksForAxeChoicePopup: this.nbAsksForAxeChoicePopup,
      nbAsksForProjectChoicePopup: this.nbAsksForProjectChoicePopup,
      projectChoicePopupVisible: this.projectChoicePopupVisible,
    }
  }
  load(data) {
    if(!data) return
    this.nbAsksForAxeChoicePopup = data.nbAsksForAxeChoicePopup
    this.nbAsksForProjectChoicePopup = data.nbAsksForProjectChoicePopup
    this.projectChoicePopupVisible = data.projectChoicePopupVisible

    data.all.forEach(savedPlace => {
      let place = this.get(savedPlace.id)
      if(place) {
        place.unlockedDialsSequences = savedPlace.unlockedDialsSequences
        place.notified = savedPlace.notified
      }
    })
  }


}


export default new Places()