import { makeAutoObservable, makeObservable, observable } from "mobx"
import SequencesManager, { SequencesFile } from "./SequencesManager/SequencesManager"

class FtueManager {

  visible = null
  seq = null
  onSequenceFinished = null

  constructor() {
    makeObservable(this, {
      visible: observable
    })
  }

  showSequence(id, onFinished = null) {
    this.onSequenceFinished = onFinished
    // on n'utilise pas les fonctions statiques car ca peut écraser une autre séquence en cours
    // on récupère donc des objets File et Sequence "à la main"
    let file = SequencesManager.findFile("ftue")
    if(file) {
      this.seq = file.createSequence(id)
      if(this.seq) {
        let el = this.seq.getCurrentElement()
        if(el) this.visible = el.raw
      }
      else {
        console.log("Sequence id does not exist", id)
        // this.seq = null
        if(this.onSequenceFinished) this.onSequenceFinished()

      }
    }
  }


  emit(event) {
    if (!this.visible) return
    if(this.visible.next_event === event) {
      this.next()
    }
  }

  next() {
    if(!this.seq) return
    if(this.seq.hasNext()) {
      let el = this.seq.next()
      if(el) this.visible = el.raw
    }
    else {
      this.hide()
    }
  }

  hide() {
    this.visible = null
    let cb = this.onSequenceFinished
    this.onSequenceFinished = null

    if(cb) cb()
  }

  init(json) {

    const data = SequencesFile.createFromDataFile("ftue", json)
    SequencesManager.addFile(data)

    SequencesManager.registerAction("SHOW_FTUE", ([id]) => {
      this.showSequence(id)
    })
  }
}


export default new FtueManager()