import { makeAutoObservable } from "mobx"
import Cards from "./Cards"
import DataLoading from "./DataLoading"
import SequencesManager, { SequencesFile } from "./SequencesManager/SequencesManager"

class Category {
  notified = false
  unlockedDialsSequences = []
  readDialsSequences = []
  sequencesQty = {} // permet de ne pas unlock les sequences tt de suite si il faut plusieurs

  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)

    if(this.sequencesNbUnlocksNecessary) {
      // il s'agit du nombre d'appels à unlock une séquence avant qu'elle s'unlocke vraiment
      // ex : Seq1 doit être appelée 2 fois avant de s'unlocker vraiment

      let seqs = this.sequencesNbUnlocksNecessary.split(";")
      seqs.forEach(seqdata => {
        let [seqId, qty] = seqdata.split("|")
        this.sequencesQty[seqId] = parseInt(qty)
      })


    }

  }

  unlockSequence(seqId) {
    if(this.sequencesQty[seqId]) {
      this.sequencesQty[seqId] = this.sequencesQty[seqId] - 1
      if(this.sequencesQty[seqId] > 0) return
    }

    if(this.unlockedDialsSequences.indexOf(seqId) === -1) {
      this.unlockedDialsSequences.unshift(seqId)
      this.notified = true
    }

  }

  loadDialFile(axeId) {
    let {root, dialsFolder} = window.CONFIG
    let fileurl =  root + axeId + "/" + dialsFolder + this.id + ".data"

    return DataLoading.getFile(fileurl, this.id)
    .then(file => {
      const data = SequencesFile.createFromDataFile(file.name, file.data)

      SequencesManager.addFile(data)

      if(this.ph2_initial_unlocked) {
        let firstSeqId = SequencesManager.findFile(this.id).elements[0].SequenceId
        this.unlockSequence(firstSeqId)
      }
    })
  }

  // gestion des dials
  get ph2_unlocked() {
    return this.unlockedDialsSequences.length > 0 || this.readDialsSequences.length > 0
  }
  get lastUnlockedSequence() {
    let seq = this.unlockedDialsSequences.pop()
    this.readDialsSequences.push(seq)
    return seq // this.unlockedDialsSequences[this.unlockedDialsSequences.length - 1]
  }



  get unlockableCardsQty() {
    // ici parcourir les dials et récupérer le nb de fois ou la col est pas vide
    let dials = SequencesManager.findFile(this.id)
    return dials.elements.reduce( (acc, el) => {
      return el.raw.unlock_cards ? acc + 1 : acc
    }, 0)
  }


  get cards() { return Cards.all.filter(c => c.category === this.id) }
  get playableCards() { return this.cards.filter(c => !c.isOmission)}

  /* data Phase2 */
  get receivedCards() { return this.playableCards.filter(c => c.ph2_picked)}

  /* data Phase3 */
  get locked() { return this.cards.filter(c => c.locked) }
  get bought() { return this.cards.filter(c => c.picked) }

  get cardsQty(){ return this.cards.length }
  get boughtQty(){ return this.bought.length }
  get lockedQty(){ return this.locked.length }
  get unBoughtQty(){ return this.cardsQty - this.boughtQty - this.lockedQty}
  get cardsLeft() { return Math.min(this.boughtQty - parseInt(this.min_card_quantity), 0) }


  get iconUrl() {
    return "./data/icones/" + this.icon + ".svg"
  }

  /************* SAVE */
  get saveData() {
    return {
      id: this.id,
      notified: this.notified,
      unlockedDialsSequences: this.unlockedDialsSequences,
      readDialsSequences: this.readDialsSequences,
      sequencesQty: this.sequencesQty,
      // history: SequencesManager.findFile(this.id).history
    }
  }
}

export default Category