import React, { useEffect, useState } from 'react';
import { Tweenable } from 'shifty';
import './Shared.css';
import arrow_enter from '../../images/arrow_enter.svg'
import Button from './Button/Button';


export function pad(val) {
  return val.toString().padStart(2, "0")
}

export const IconButton = ({onClick, disabled, style={}, className, children}) => {
  return (
    <button onClick={onClick} disabled={disabled} style={style} className={"icon-btn " + className}>
      {children}
    </button>
  );
};


export const AnimatedNumber = ({duration=1000, value, formatValue=v=>v}) => {
  const [animatedValue, setAnimatedValue] = useState(value);

  useEffect(() => {
    let tweenable = new Tweenable()
    tweenable.setConfig({
      from: { x: animatedValue },
      to: { x: value },
      duration,
      easing: 'easeOutQuad',
      step: state => setAnimatedValue(state.x)
    });
    tweenable.tween().catch(err => {})

    return () => {
      tweenable.cancel()
    }
  }, [value])



  return (
    <span>
      {formatValue(animatedValue)}
    </span>
  )
}

export const Value = ({className="", title, name=null, value}) => {
  return <div className={"value " + className} title={title}>
    {name && <span>{name}</span>}
    {value}
    </div>
}


export const EnterButton = (props) => {
  let cl = props.className ? props.className : ""
  cl += "enter-button"
  return <Button {...props} pill className={cl} ><img src={arrow_enter} /></Button>
}
