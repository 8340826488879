import React, { useRef, useState } from 'react';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Texts from '../../../js/Texts';
import Input from '../../Shared/Input/Input';
import { EnterButton } from '../../Shared/Shared';

export default ({onDone}) => {

  const emailRef =  useRef(null)
  const [error, setError] = useState(null);

  const [step, setStep] = useState(0);

  const [errorStep2, setErrorStep2] = useState("");

  function handleSubmit(e) {
    e.preventDefault()
    const data = new FormData(e.target)


    const email = data.get('email')
    emailRef.current = email

    SGMSClient.UserResetPasswordSendMail(window.CONFIG.server.Universe, email)
    .then(() => setStep(1))
    .catch(err => setError(err))
  }

  function handleSendNewPassword(e) {
    e.preventDefault()
    const data = new FormData(e.target)


    const code = data.get('code')
    const password = data.get('password')
    const password2 = data.get('password2')
    if(password !== password2) {
      setErrorStep2(Texts.get("menu_forgot_pass_passwords_dont_match"))
      return
    }

    SGMSClient.UserResetPassword(window.CONFIG.server.Universe, emailRef.current, password, code)
    .then(() => onDone() )
    .catch((err) =>  {
      setErrorStep2(err)
    })


  }

  return (
  <div className='LoginForm'>
    <h2>Reset password</h2>

    {step === 0  &&
    <form onSubmit={handleSubmit}>
      <div>
        <Input id="email" label={Texts.get("menu_forgot_pass_enter_email")} type="email" />
      </div>

      {error && <div className="error">{error}</div>}
      <EnterButton />
    </form>}

    {step === 1  &&
    <form onSubmit={handleSendNewPassword}>
      <Input id="password" label={Texts.get("menu_forgot_pass_enter_new_pwd")} type="password" />
      <Input id="password2" label={Texts.get("menu_forgot_pass_confirm_new_pwd")} type="password" />
      <Input id="code" label={Texts.get("menu_forgot_pass_enter_code")} type="text" />

      {errorStep2 && <div className="error">{errorStep2}</div>}
      <EnterButton />

    </form>}


  </div>)
}