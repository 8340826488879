import React from 'react';
import './ProjectCard.css';

import cadenas from '../../../images/lock_closed.svg'
const ProjectCard = ({locked, selected, className, onClick, title1, title2, description, character}) => {
  // const [state, setState] = useState(null);
  className = "ProjectCard " + className
  if(selected) className += " selected"
  if(locked) className += " locked"

  function handleClick() {
    onClick()
  }

  return (
    <div onClick={handleClick} className={className}>
      {title1}
      {title2}
      <div className="description">{description}</div>

      <svg viewBox="0 0 106.23723 68.835297" >
        <g transform="translate(-83.026804,-104.22854)">
          <path d="m 83.159096,117.51952 c 19.175924,-4.80582 79.613844,37.35515 105.972644,-13.22985 v 68.64187 H 83.159096 Z"/>
        </g>
      </svg>


      {character && <img className="character" src={`./data/ph1_characters/${character}.png`} alt="" />}

      {locked && <img className="cadenas" src={cadenas} />}
    </div>
  );
};

export default ProjectCard;
