import React, { useState, useEffect } from 'react'
import Texts from '../../../js/Texts';
import Button from '../../Shared/Button/Button';

import './Ph1SkipDialsPopup.css'

const Ph1SkipDialsPopup = ({onYes, onNo}) => {
  // const [state, setState] = useState(null);

  return (
    <div className="Ph1SkipDialsPopup-overlay">
      <div className="Ph1SkipDialsPopup">
        <h2>{Texts.get('choiceAxe_title')}</h2>
        <p>{Texts.get("choiceAxe_text")}</p>
        <div className="Ph1SkipDialsPopup-buttons">
          <Button pill onClick={onNo}>{Texts.get("choiceAxe_answer1")}</Button>
          <Button pill onClick={onYes}>{Texts.get("choiceAxe_answer2")}</Button>
        </div>
      </div>
    </div>
  );
};

export default Ph1SkipDialsPopup;
