import { latLngBounds } from "leaflet"
import { makeAutoObservable } from "mobx"
class ToolkitMapMarker {
  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }

  get coords() {
    return [this.lat, this.lng]
  }
  get links() {
    if(!this.website) return []
    return this.website.split(";").map(l => {
      return {
        href: l,
        text: l.replace("mailto:", "")
      }
    })
  }

  get logo_path() {
    const {root, toolkit_map_images_folder} = window.CONFIG
    return root + toolkit_map_images_folder + this.logo
  }

  get img_path() {
    const {root, toolkit_map_images_folder} = window.CONFIG
    return root + toolkit_map_images_folder + this.image
  }
}


class ToolkitMaps {
  all = []

  init(json) {
    this.all = json.map(elem => new ToolkitMapMarker(elem))
  }


  get bounds() {
    return latLngBounds( this.all.map(marker => marker.coords) )
  }

}


export default new ToolkitMaps()