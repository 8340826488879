import { makeAutoObservable, toJS } from "mobx"
import Criterias, {MIN, AVERAGE, MAX} from "./Criterias"

import Projects from "./Projects"

// Correspondances avec states du CMS pour la victoire
const C = {
  [MIN] : "R",
  [AVERAGE] : "B",
  [MAX] : "G",
  JOKER: "x"
}

const orders = [C.MAX,C.AVERAGE,C.MIN,C.JOKER]
function statesOrder (a, b) {
  let _a = orders.findIndex(el => el === a)
  let _b = orders.findIndex(el => el === b)
  if(_a < _b) return -1
  if(_a > _b) return 1
  return 0
}

class Axe {
  selected = false
  winConditions = null
  index = null
  constructor(json, index) {
    Object.assign(this, json)
    makeAutoObservable(this)
    this.index = index
    this.winConditions = this.winConditions.split(";").map(wc => wc.split("").sort(statesOrder))
  }

  get projects() {
    return Projects.all.filter(p => p.axe_id === this.id)
  }

  calculateVictory() {

    let cVals = Criterias.current
    .map(c => C[c.state])
    .sort(statesOrder)


    // on filtre les conditions de victoire, en gardant celle qui correspond à la condition actuelle du jeu
    let winningCombination = this.winConditions.filter(wc => {
      return wc.every((val, index) => {
        // val = une des lettre des win condition G/B/R/x
        let cVal = cVals[index] // les critères du jeu, G/B/R
        if(val === cVal || val === C.JOKER) return true
        return false

      })
    })

    return winningCombination.length > 0 // si il reste une combinaison gagnante, c'est bon (normalement il n'y en aura pas 2)
  }

}


class Axes {
  all = []

  init(json) {
    this.all = json
    .filter(elem => elem.id)
    .map((elem, index) => new Axe(elem, index))
  }

  select(axeId) {
    this.all.forEach(a => a.selected = false) // reset

    let a = this.get(axeId)
    a.selected = true

  }

  get selected() {
    return this.all.find(a => a.selected === true)
  }

  get ids() { return this.current.map(c => c.id)}
  get(id) { return this.all.find(c => c.id === id) }

  save() {
    if(this.selected) {
      return { id: this.selected.id }
    }
    return null
  }

  load(data) {

    if(data) {
      let a = this.get(data.id)
      if(a) {
        a.selected = true
      }
    }
  }
}


export default new Axes()