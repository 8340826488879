import React from 'react';
import Cards from '../../../js/Cards';
import Texts from '../../../js/Texts';
import Card from '../../Shared/Card/Card';
import CriteriaIcon from '../../Shared/CriteriaIcon/CriteriaIcon';
import './WrapupCardScreen.css';


const WrapupCardScreen = ({card}) => {
  return (
    <div className="WrapupCardScreen">

        <div className="col-left">

        {card.isCombo && <h3>{Texts.get("debrief_combo_title")}</h3>}
          {card.isCombo && card.comboRefIds.map(id =>
          <Card showPickButton={false} key={id} card={Cards.get(id)} />)}

          {!card.isCombo && <Card showPickButton={false} card={card} />}
        </div>

        <div className={"col-right " + card.type}>
          <h1>{card.title}</h1>
          <div className="wrapup-card-debrief">{card.debrief}</div>
          <div className="wrapup-card-criterias">
            {card.criterias
            .filter(crit => crit.value)
            .map(crit => {
              return (
              <div key={crit.id} className={"wrapup-card-criteria " + crit.id + (crit.value < 0 ? " negative" : " positive")}>

                <div className="l1">
                  <CriteriaIcon id={crit.id} selected={true} size={90} clickable />
                  <div className="crit-val">{crit.value}</div>
                </div>

                <div className="l2">{crit.name}</div>
              </div>)
            })}
          </div>

        </div>
    </div>
  );
};

export default WrapupCardScreen;
