import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import mentor from '../../images/ftue_mentor.png';
import naomi from '../../images/ftue_naomi.png';
import FtueManager from '../../js/FtueManager';
import './Ftue.css';


// note : fait appel à une lib externe, pour transformer un polygon en "rounded path" mais c'est en proto pour le moment
/*
function getRoundedPoints(bbs) {
  if(!bbs) return ""

  let ww = document.body.offsetWidth
  let wh = document.body.offsetHeight

  let arr = [
    "0 0",
    `${ww} 0`,
    `${ww} ${wh}`,
    `0 ${wh}`,
    "0 0",
  ]

  bbs.forEach(bb => {
    let pts = [
      `${bb.left - 10} ${bb.top - 10}`,
      `${bb.left - 10} ${bb.bottom + 10}`,
      `${bb.right + 10} ${bb.bottom + 10}`,
      `${bb.right + 10} ${bb.top - 10}`,
      `${bb.left - 10} ${bb.top - 10}`,
    ]
    if(arr[5]) pts.push(arr[5]) // revenir au premier point du premier carré

    arr = [...arr, ...pts]

  })

  let newpts = window.polygonWithRoundedCorners(arr, 10)
  return  newpts
}
*/

const Ftue = () => {

  let ftue = FtueManager.visible
  const [bbs, setBbs] = useState(null);

  const [refreshToken, setRefreshToken] = useState(0);

  useEffect(() => {
    document.body.classList.add("ftue-visible")
    return () => {
      document.body.classList.remove("ftue-visible")
    }
  }, []);

  useEffect(() => {
    const handleresize = () => {
      setRefreshToken(t => t+1)
    }
    window.addEventListener('resize', handleresize)

    return () => {
      window.removeEventListener('resize', handleresize)
    }
  }, []);




  useEffect(() => {
      setBbs(null)


      try {
        let els = ftue.highlighted_element
        .split(";")
        .map(el_classname => document.querySelector(el_classname))

        if(els.length > 0) {
          let _bbs = []
          els.forEach(el => {
            let _bb = el.getBoundingClientRect()
            _bbs.push(_bb)
          })

          setBbs(_bbs)
        }
        else {
          setBbs(null)
        }
      }
      catch(err) {
        // console.log("error", err)
        //

      }
return
  }, [ftue, refreshToken]);

  function getStyle() {
    if(bbs) {

      let arr = [
        "0 0",
        `100% 0`,
        `100% 100%`,
        `0 100%`,
        "0 0",
      ]

      bbs.forEach(bb => {
        let pts = [
          `${bb.left - 10}px ${bb.top - 10}px`,
          `${bb.left - 10}px ${bb.bottom + 10}px`,
          `${bb.right + 10}px ${bb.bottom + 10}px`,
          `${bb.right + 10}px ${bb.top - 10}px`,
          `${bb.left - 10}px ${bb.top - 10}px`,
        ]
        if(arr[5]) pts.push(arr[5]) // revenir au premier point du premier carré si plusieurs bbs

        arr = [...arr, ...pts]
      })

      arr = arr.join(",")


      return {clipPath: `polygon( evenodd, ${arr})`}

    }
  }

  let style= bbs ? getStyle() : {}


  let textStyle = {}
  if(bbs) {
    let c = bbs[0]
    let space_r = document.body.offsetWidth - c.right
    let space_under = document.body.offsetHeight - c.bottom

    let p = parseInt(ftue.position)
    switch(p) {
      case 1:
        textStyle={
          bottom: space_under + c.height,
          right: space_r + c.width
        }
        break;
      case 2:
        textStyle={
          bottom: space_under + c.height,
          left: c.left + c.width/2,
          transform: "translateX(-50%)"
        }
        break;
      case 3:
        textStyle={
          bottom: space_under + c.height,
          left: c.right
        }
        break;
      case 4:
        textStyle={
          top: c.top + c.height/2,
          left: c.right,
          transform: "translateY(-50%)"
        }

        break;
      case 5:
        textStyle={
          top: c.bottom,
          left: c.right
        }

        break;
      case 6:
        textStyle={
          top: c.bottom,
          left: c.left + c.width/2,
          transform: "translateX(-50%)"
        }
        break;
      case 7:
        textStyle={
          right: space_r + c.width,
          top: c.bottom,
        }
        break;
      case 8:
        textStyle={
          top: c.top + c.height/2,
          right: space_r + c.width,
          transform: "translateY(-50%)"
        }

        break;
    }








  }

  return (
    <div className={"Ftue" + (ftue.mentor ? " with-mentor " + ftue.title : "")}>
      <div style={style} className="Ftue-opaque" />

      <div className={"ftue-transparent " + ftue.options } onClick={() => FtueManager.next()}>

        <div className={"Ftue-content" + (bbs ? " with-highlight" : "")} style={textStyle}>
          <h1>{ftue.title}</h1>
          <div className="text">{ftue.text}</div>
        </div>

        <img src={mentor} className="mentor-image" alt="mentor image" />
        <img src={naomi}  className="naomi-image" alt="naomi image" />
      </div>
    </div>
  );
};

export default observer(Ftue)