import React, { useState, useEffect, useRef } from 'react'
import DataLoading from '../../../js/DataLoading';

import './InlineSvg.css'

const uid = function(){
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

const InlineSvg = ({url}) => {
  const [contenu, setContenu] = useState("");
  const mounted = useRef(false)

  const key = useRef(uid())



  useEffect(() => {

    mounted.current = true

    DataLoading
    .getFile(url, url)
    .then((file) => {
      if(mounted.current) setContenu(file.data)
    })
    .catch(err => console.log("InlineSvg error", url,  err))

    return () => { mounted.current = false }
  }, [url]);


  return (<div className="InlineSvg" dangerouslySetInnerHTML={{__html: contenu}} />)
}

export default InlineSvg;
