import { makeAutoObservable } from "mobx"
import Cards from "./Cards"
import Projects from "./Projects"

export const MIN = "MIN"
export const AVERAGE = "AVERAGE"
export const MAX = "MAX"



class Criteria {

  visible = false
  notified = false

  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)

    this.threshold_min = parseInt(this.threshold_min)
    this.threshold_max = parseInt(this.threshold_max)
  }


  get value() {
    let val = Cards.boughtCards.reduce( (val, card) => (val += card[this.id]), 0 )
    return val
  }

  get state() {
    if(this.value < this.threshold_min) return MIN
    if(this.value > this.threshold_max) return MAX
    return AVERAGE
  }
  get debrief() {
    if(this.state === MIN) return this.debrief_min
    if(this.state === MAX) return this.debrief_max
    return this.debrief_average
  }

}

class Criterias {
  all = []
  constructor() {
    makeAutoObservable(this)
  }

  init(json) {
    this.all = json
    .filter(c => c.id !== null)
    .map(c => new Criteria(c))
  }

  setAllVisible() { this.all.forEach(c => c.visible = true) }


  get current() { return this.all.filter(c => c.project === Projects.selected?.id) }
  get ids() { return this.current.map(c => c.id)}
  get(id) { return this.current.find(c => c.id === id) }

  get firstVisible() {
    return this.current.some(c => c.visible)
  }
  save() {
    return this.all.map(c => ({
      id: c.id,
      visible: c.visible,
      notified: c.notified
    }))
  }

  load(data) {
    if (!data) return
    data.forEach(savedC => {
      let c = this.get(savedC.id)

      if(c) {
        c.visible = savedC.visible
        c.notified = savedC.notified
      }
    })
  }
}


export default new Criterias()