import { makeAutoObservable, makeObservable, observable, configure } from "mobx"
const GMS = window.gms
const VC = GMS.VertxClient

configure({
  enforceActions: "never",
})

export const STATUS = {
  CONNECTED: "CONNECTED",
  OFFLINE: "OFFLINE"
}

export const EVENT_TYPES = {
  SEQUENCE_INFO: "SEQUENCE_INFO",
  SEQUENCE_INITIALIZED: "SEQUENCE_INITIALIZED",
  STATUS: "STATUS",
}

// Gere le timing et l'état de la période
// faudrait lui trouver un nouveau nom depuis qu'on a l'autre SequencesManager
class SequenceManager {

  periodIndex = null
  periodsTotal = null

  duration = null
  progress = null
  speedFactor = 1

  periodState = null


  currentTimeSecs = null
  timeout = null
  callbacks = []

  constructor() {
    makeAutoObservable(this)
  }

  registerChangeCallback(cb) {
    this.callbacks.push(cb)
  }

  update(event) {

    // console.log("update", event)
    // relou car les events ont tous une structure diffrente
    if(event.Type === EVENT_TYPES.SEQUENCE_INFO || event.Type === EVENT_TYPES.SEQUENCE_INITIALIZED) {
      let periodInfos = event.Sequence?.Periods?.[event.Sequence.CurrentPeriod]

      this.periodIndex = event.Sequence.CurrentPeriod
      this.periodsTotal = event.Sequence?.Periods?.length

      if(periodInfos !== undefined) {
        this.duration = periodInfos.DurationSec
        this.speedFactor = periodInfos.SpeedFactor
      }

      this.periodState = event.Sequence.State
      this.progress = event.Sequence.Progress

    }
    else if (event.Type === EVENT_TYPES.STATUS ) {
      this.progress = event.PeriodProgress
    }
    else {
      // c'est un event "EVENT" !
      if(event.PeriodDurationSec !== undefined) this.duration = event.PeriodDurationSec
      if(event.PeriodProgress !== undefined) this.progress = event.PeriodProgress
      if(event.PeriodSpeedFactor !== undefined) this.speedFactor = event.PeriodSpeedFactor
      if(event.Type !== undefined) this.periodState = event.Type
      if(event.Period !== undefined) this.periodIndex = event.Period
    }


    this.calculateSecs()
    this.callInterUpdate()

    this.callbacks.forEach(cb => cb(event))
  }
  calculateSecs() {
    if(this.duration === null) {
      this.currentTimeSecs = null
      return
    }
    let secs = this.duration * this.progress
    this.currentTimeSecs = Math.floor(secs)
  }

  callInterUpdate() {
    this.stop()
    if(this.speedFactor) {
      this.timeout = setTimeout(() => {
        if(this.periodState === "PERIOD_STARTED") {
          this.currentTimeSecs += 1
          this.callInterUpdate()
        }

      }, 1000 / this.speedFactor)
    }

  }



  stop() {
    clearTimeout(this.timeout)
    this.timeout = null
  }

}


// Gere la connecion au vertx, les infos du meeting, l'état de la connexion
class SGMSClient {

  analyticsApi = new window.gms.module.analytics.AnalyticsApi()

  client = null
  currentUser = null
  isAnonymous = false
  sequenceCmder = null
  meetings = []
  sequenceManager = null
  userCustomDatas = null

  currentMeeting = null
  currentMeetingUsers = []

  status = STATUS.CONNECTED

  // les ids des timeouts/intervals
  infosInterval = null
  offlineTimeout = null

  // les durées des timeouts
  infoIntervalDuration = 10000
  offlineTimeoutDuration = 2000


  constructor() {
    makeObservable(this, {
      currentMeetingUsers: observable,
      currentUser: observable,
      currentMeeting: observable,
      isAnonymous: observable,
    })

    this.onMessageReceived = this.onMessageReceived.bind(this)
  }


  init(ip, port) {
    this.client = VC.CreateVertxClient(ip, port);
    this.analyticsApi.init(this.client)
    this.sequenceCmder = this.client.sequencerCmdHelper.__class__

    this.sequenceManager = new SequenceManager()
    this.addMessageListener(this.onMessageReceived)

  }

  addMessageListener(cb) {

    function onmessage (meeting_id,from_user_id, message) {
      if(meeting_id === this.currentMeeting?._id) {
        try {
          let m = JSON.parse(message)
          cb(from_user_id, m)
        }
        catch(err) {
          console.log("err", err)
        }
      }
    }
    this.client.SIG_MeetingMessage.Add(onmessage.bind(this));
  }

  // vérifie la validité de universe/app
  universeAppGet(universe, app) {
    return new Promise( (resolve, reject) => {
      this.client.UniverseAppGet(universe, app, (success, msg, data) => {
        if(success) {
          resolve(data)
        }else {
          reject(msg)
        }
      })
    })
  }

  adminGetApplications(universe) {
    return new Promise( (resolve, reject) => {

      this.client.AdminGetApplications(universe, (success, msg, data) => {
        if(success) {
          let list = VC.GetJsonObject(data)
          resolve(list)
        }
        else {
          reject(msg)
        }
      })
    })
  }

  anonymousReconnect(universe, app, localStorageUserKey) {
    let savedData = localStorage.getItem(localStorageUserKey)

    if(savedData) {
      let sessionToken = null
      let userLogin = null
      try {
        const _d = JSON.parse(savedData)
        sessionToken = _d.sessionToken
        userLogin = _d.userLogin

      } catch(err) {}


      // étape 1 : on essaie de se log avec le token
      // si ca déconne, on essaie de se log avec les login anonymous

      // si ca déconne, on crée un nouvel anon et on se log avec
      // essai de log au GMS avec le sessiontoken précédent
      console.log("Found saved sessionToken, trying to connect...")
      return this.loginGmsToken(universe, app, sessionToken)
      .then(() => {
        console.log("connected using the token")
        this.isAnonymous = true
        // ça a marché !

      })
      .catch(err => {
        // probablement session token expiré
        console.log("login with token error:", err)
        const pwd = "ANONYMOUS_PWD"
        console.log("trying to reconnect with anonymous credentials")
        return this.login(universe, app, userLogin, pwd)
        .then((data) => {
          console.log("reconnect with anonymous credentials ok")
          this._setLocalStorageData(data.UserLogin, localStorageUserKey)
          this.isAnonymous = true
        })
        .catch(err => {
          console.log("login with saved credentials error:", err)
          return this.anonymousConnectAdd(universe, app, localStorageUserKey)
        })


      })

    } else {
      console.log("No saved anonymous user data")
      return this.anonymousConnectAdd(universe, app, localStorageUserKey)
    }
  }

  anonymousConnectAdd(universe, app, localStorageUserKey) {
    console.log("NEW ANONYMOUS USER")

    return new Promise( (resolve, reject) => {
      this.client.UserAnonymousConnectAdd(universe, app, (success, msg, data) => {
        if (success) {

          this._setUserData(data)
          .then(() => {
            this._setLocalStorageData(data.UserLogin, localStorageUserKey)
            this.isAnonymous = true

            resolve()
          })
        }
        else {
          reject(msg)
        }
      })
    })

  }


  loginKeycloak(universe, app, token) {
    return new Promise( (resolve, reject) => {
      this.client.UserConnectKeycloak(universe, app, token, (success, msg, data) => {
        if (success) {
          this._setUserData(data)
          .then(() => {
            this.isAnonymous = false
            resolve()
          })
          .catch(reject)
        } else {
          console.log("Failed to Connect !", msg, data);
          reject(msg)
        }
      })
    })
  }

  loginNonEncrypted(universe, app, nonEncryptedToken) {
    return new Promise((resolve, reject) => {
      this.client.UserConnectNonEncryptedToken(universe, app, nonEncryptedToken, (success, msg, data) => {
        if(success) {
          this._setUserData(data)
          .then(() => {
            this.isAnonymous = false
            resolve()
          })
          .catch(reject)

        } else {
          console.log("loginNonEncrypted: failed to Connect", msg)
          reject(msg)
        }
      })
    })
  }

  login(universe, app, login, password) {
    return new Promise( (resolve, reject) => {
      this.client.UserConnect(universe, app, login, password, (success, msg, data) => {
        if(success) {
          this._setUserData(data)
          .then(() => {
            this.isAnonymous = false
            resolve(data)
          })
        }
        else {
          console.log("UserConnect", msg)
          reject(msg)
        }
      })
    })
  }

  loginGmsToken(universe, app, gms_token) {
    return new Promise( (resolve, reject) => {
      this.client.UserConnectWithGmsToken(universe, app, gms_token, (success, msg, data) => {
        if(success) {
          this._setUserData(data)
          .then(() => resolve())
        }
        else {
          console.log("loginGmsToken", msg)
          reject(msg)
        }
      })
    })
  }


  _setLocalStorageData(login, key) {
    let sessionToken = this.client.getSessionToken()
    let ls_data = {
      sessionToken,
      userLogin: login
    }
    localStorage.setItem(key, JSON.stringify(ls_data))
  }

  /**
   * set le currentUser
   * set les datas en localstorage
   */
  _setUserData(data) {



    return this.getUserId()
    .then(id => {
      this.currentUser = data
      this.currentUser._id = id
      let cd = VC.GetJsonObject(data)
      if(Object.keys(cd).length > 0) this.userCustomDatas = cd
    })
  }


  userDisconnect() {
    return new Promise( (resolve, reject) => {
      this.client.UserDisconnect((success, msg, data) => {
        // NOTE(g) parfois y'a des erreurs alors que ca marche en fait alors on considère que ca marche tt le temps 😬

        this.currentUser = null
        resolve()


      })
    })
  }

  userCreate(universe, login, password, pseudo) {

    const user = {
      Login: login,
      Password: password,
      Mail: login,
      Pseudo: pseudo,
      Universe: universe,
      LastName: "",
      FirstName: ""
    }
    return new Promise( (resolve, reject) => {
      this.client.UserAdd(user, (success, msg, data) => {
        if(success) resolve()
        else reject(msg)
      })
    })
  }

  userUpdateCustomData(datas) {
    return new Promise( (resolve, reject) => {
      this.client.UserUpdateCustomData(JSON.stringify(datas), (success, msg, data) => {
        if(success) {
          this.userCustomDatas = datas
          resolve()
        }
        else {
          reject()
        }
      })

    })
  }

  /***********
   * reset password, step 1, envoyer une demande de mail
   */
  UserResetPasswordSendMail(universe, email) {
    return new Promise( (resolve, reject) => {
      this.client.UserResetPasswordSendMail(universe, email, (success, msg, data) => {
        if(success) {
          resolve(data)
        }
        else {
          reject(msg)
        }

      })
    })
  }

  // reset password, step 2, envoyer les infos avec le code reçu dans le mail du serveur
  UserResetPassword(universe, email, pwd, magic_key) {
    return new Promise( (resolve, reject) => {
      this.client.UserResetPassword(universe, email, pwd, magic_key, (success, msg, data) => {
        if(success) {
          resolve(data)
        }
        else {
          reject(msg)
        }
      })
    })
  }


  AdminGetCollection(collection, query, onProgress) {

    let i = 0, nbDocuments = 0;
    return new Promise( (resolve, reject) => {

      this.client.AdminGetCollection(collection, query, (success, msg, slice) => {

        if (slice) {
          if(slice.DocumentsFound) nbDocuments = slice.DocumentsFound
          else {
            onProgress({downloaded: i, total: nbDocuments}, slice)
            i++
          }
        }

        if(success) {
          resolve()
        }
        if(msg) {
          reject(msg)
        }



      })
    })



  }


  // returns the mongo id used in meetings sometime
  getUserId() {
    return new Promise( (resolve, reject) => {
      this.client.UserGetInfo((res, msg, data) => {
        if (res) {
          let userData = JSON.parse(data.DataArray);
          resolve(userData._id)
        } else {
          reject(msg)
        }
      })
    })
  }

  getUserInfo() {
    return new Promise( (resolve, reject) => {
      this.client.UserGetInfo((res, msg, data) => {
        if (res) {
          let userData = JSON.parse(data.DataArray);
          resolve(userData)
        } else {
          reject(msg)
        }
      })
    })
  }

  getUserMeetings() {
    return new Promise( (resolve, reject) => {
      this.client.MeetingGetAdministeredList( (success, msg, data) => {
        if(success) {
          let list = VC.GetJsonObject(data) // de la forme {id: name, id: name}
          let proms = Object.keys(list).map(id => this.meetingGetInfos(id))

          return Promise.all(proms)
          .then(data => {
            this.meetings = data
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
        }
        else {
          reject(msg)
        }
      })
    })
  }

  meetingGetInfos(meetingId) {
    return new Promise( (resolve, reject) => {
      this.client.MeetingGetInfos(meetingId, (success, msg, data) => {
        if(success) {
          let meetingInfos =  VC.GetJsonObject(data)

          if(!meetingInfos.MeetingCode) {
            this.meetingGetCode(meetingId)
            .then(codeData => {
              meetingInfos = {...meetingInfos, ...codeData}
              resolve(meetingInfos)
            })
            .catch(err => reject(err))
          } else {
            resolve(meetingInfos)
          }
        }
        else {
          reject(msg)
        }
      })
    })
  }

  meetingGetCode(meetingId) {
    return new Promise( (resolve, reject) => {
      this.client.MeetingGetCode(meetingId, (success, msg, codeData) => {
        if(success) {
          resolve(codeData)
        } else {
          reject(msg)
        }
      })
    })
  }

  meetingCreate(meetingName, config) {
    return new Promise( (resolve, reject) => {
      this.client.MeetingCreate(meetingName, "", config, (success, msg, data) => {
        if(success) {
          resolve(data.MeetingID)
        }
        else {
          reject(msg)
        }
      })
    })
  }

  meetingDestroy(meetingId) {
    return new Promise( (resolve, reject) => {
      this.client.MeetingDestroy(meetingId, (success, msg, data) => {
        if(success) {
          this.meetings = this.meetings.filter(m => m._id !== meetingId)
          resolve()
        }
        else reject(msg)
      })
    })
  }

  meetingGetWithCode(code, login) {
    return new Promise( (resolve, reject) => {
      this.client.MeetingAddUserWithCode(code, login, (success, msg, data) => {
        if(success) {
          this.meetingGetInfos(data.MeetingID)
          .then(meeting => resolve(meeting))
          .catch(err => reject(err))
        }
        else reject(msg)
      })
    })
  }


  meetingConnect(meeting) {
    return new Promise( (resolve, reject) => {
      this.client.UserMeetingConnect(meeting._id, (success, msg, data) => {
        if (success) {
          this.currentMeeting = meeting
          this.currentMeetingUsers = []
          resolve()
        }
        else reject(msg)
      })
    })
  }

  meetingDisconnect(meeting) {
    return new Promise( (resolve, reject) => {
      this.client.UserMeetingDisconnect(meeting._id, (success, msg, data) => {
        if (success) {
          this.currentMeeting = null
          this.currentMeetingUsers = []
          resolve()
        }
        else reject(msg)
      })
    })
  }



  meetingGetConnectedUsersAndScores(meetingId){
    return new Promise( (resolve, reject) => {
      this.client.UserMeetingGetConnected(meetingId, (success, msg, usersData) => {
        if(success) {
          this.client.UserMeetingGetScores(meetingId, (success, msg, scoresData) => {
            if(success) {
              let meetingUsers = VC.GetJsonObject(usersData)
              let newMeetingUsers = []
              let scores = VC.GetJsonObject(scoresData)
              for(let id in meetingUsers) {
                let prev = this.currentMeetingUsers.find(u => u.id === id)
                newMeetingUsers.push({id, pseudo: meetingUsers[id], scores: scores[id], status: prev && prev.status})
              }
              this.currentMeetingUsers = newMeetingUsers
              resolve()
            }
            else {
              reject(msg)
            }
          })
        } else {
          reject(msg)
        }
      })
    })
  }


  get meetingUsersNotReady() {
    return this.currentMeetingUsers.filter(u => u.status === false)
  }



  startInfosInterval() {
    this.askForInfos() // immediat



    this.infosInterval = setInterval(()=>{
      this.askForInfos()
    }, this.infoIntervalDuration)
  }

  stopInfosInterval() {
    console.log("stopInfosInterval")
    clearInterval(this.infosInterval)
  }

  askForInfos() {
    if(!this.client) return

    if(this.offlineTimeout) clearTimeout(this.offlineTimeout)
    this.offlineTimeout = setTimeout(() => {
      this.status = STATUS.OFFLINE
    }, this.offlineTimeoutDuration)

    const cmd = this.sequenceCmder.GetInfos()
    this.sendCmd(cmd);

  }


  onMessageReceived (from_user_id, message) {
    if(message.Name === "Score") {
      let user = this.currentMeetingUsers.find(user => user.id === from_user_id)
      if(user) user.scores = message.Data
    }

    else if(message.Name === "Sequencer") {
      const mData = JSON.parse(message.Data);
      // c'est un message de type event, on s'en sert pour la gestio de la séquence
      if (mData.Event) {
        this.sequenceManager.update(mData.Event)
      }

      // c'est un message de type info
      if(mData.Info) {
        // c'est un message de type sequenceInfo
        // ici on s'en sert pour
        // -la vérification qu'on est tjs connecté
        // - la gestion de la séquence
        if(mData.Info.Type === "SEQUENCE_INFO") {
          this.offlineTimeout = clearTimeout(this.offlineTimeout)
          this.status = STATUS.CONNECTED

          this.sequenceManager.update(mData.Info)
        }

      }
    }

    else if (message.Name === "USER_READY") {
      let user = this.currentMeetingUsers.find(u => u.id === from_user_id)
      if(user) user.status = message.Data
    }

  }


  sendCmd(cmd){
    if(!this.client) return
    return new Promise( (resolve, reject) => {
      this.client.UserMeetingSequencerCommand(this.currentMeeting._id, cmd, (success, msg, data) => {
        if(success) {
          resolve(data)
        }
        else {

          let seq = {
            id: 0,
            "Periods": this.currentMeeting.Config.MeetingPeriods.map(p => ({...p, SpeedFactor: 1}))
          }

          this.client.MeetingSequenceSet(this.currentMeeting._id, JSON.stringify(seq), (success, msg, data) => {
            if(success) {
              resolve(data)
            } else {
              this.status = STATUS.OFFLINE
              reject(msg)
            }
          })
        }
      })

    })
  }


  sendScores(scores) {
    this.sendMessage("Score", scores, true)
  }

  sendMessage(name, data = {}, toAdminOnly=false) {
    if(!this.currentMeeting) return
    let meetingId = this.currentMeeting._id
    const msg = { Name: name, Data: data }
    this.client.UserMeetingSendToAll(meetingId, JSON.stringify(msg), toAdminOnly, (success, msg, data) => {
      if(!success) {
        console.log("Error SendMessage", msg)
        this.status = STATUS.OFFLINE
      }
    })
  }


  play() {
    const cmd = this.sequenceCmder.StartPeriod()
    return this.sendCmd(cmd)
    .then(() => this.askForInfos())
  }

  pause() {
    const cmd = this.sequenceCmder.PausePeriod()
    return this.sendCmd(cmd)
    .then(() => this.askForInfos())
  }

  updateSpeed(speed) {
    const cmd = this.sequenceCmder.UpdateSpeedFactor(speed)
    return this.sendCmd(cmd)
    .then(() => this.askForInfos())
}

  stopPeriod() {
    const cmd = this.sequenceCmder.StopPeriod()
    return this.sendCmd(cmd)
    .then(() => this.askForInfos())
  }
  stopSequence() {
    const cmd = this.sequenceCmder.StopSequence()
    return this.sendCmd(cmd)
    .then(() => this.askForInfos())
  }

  setPeriod(periodIndex) {
    const cmd = this.sequenceCmder.SetPeriod(periodIndex)
    return this.sendCmd(cmd)
    .then(() => this.askForInfos())
  }

}



export default new SGMSClient()