import React, { useState, useEffect } from 'react'
import Button from '../../../Shared/Button/Button';

import close from '../../../../images/close.svg'

import './ImagePopup.css'

const ImagePopup = ({img, onClose}) => {
  // const [state, setState] = useState(null);

  return (
    <div className="ImagePopup-ctnr" onClick={onClose}>
    <div className="ImagePopup" onClick={e => e.stopPropagation()}>
      <Button pill className="ImagePopup-close" onClick={onClose}>
        <img src={close} alt="" />
      </Button>
      <img src={img} alt="" />
    </div>
    </div>
  );
};

export default ImagePopup;
