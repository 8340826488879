import React from 'react';
import AppState from '../../../js/AppState';
import './CriteriaIcon.css';

const CriteriaIcon = ({id, selected, size, clickable=false}) => {

  function handleClick() {
    if(clickable) AppState.showBoussolePopup = id
  }

  let className = "CriteriaIcon " + id

  if(selected) className += " selected"
  if(clickable) className += " clickable"

  return (
    <div
      onClick={handleClick}
      style={{width: size, height: size}} className={className} />
  );
};

export default CriteriaIcon;
