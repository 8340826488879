import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import AppState, { SCREENS } from "../../../js/AppState";
import Axes from "../../../js/Axes";
import Cards from "../../../js/Cards";
import Criterias from "../../../js/Criterias";
import Projects from "../../../js/Projects";
import SGMSClient from "../../../js/SGMS/SGMSClient";
import Texts from "../../../js/Texts";
import { objectToHtmlString } from "../../../js/utils";

import BurgerMenu from "../../Shared/BurgerMenu/BurgerMenu";
import Button from "../../Shared/Button/Button";

import Newspaper from "../Newspaper/Newspaper";
import WrapupCardScreen from "../WrapupCardScreen/WrapupCardScreen";

import "./Wrapup.css";


const Wrapup = () => {
  let carouselRef = useRef(null)

  const [page, setPage] = useState(0);

  useEffect(() => {
    console.log("useeffect wrapup")
    AppState.numPartie = AppState.numPartie + 1
    AppState.saveGame(true)
    // AppState.saveGame()
  }, []);





  let cards = Cards.boughtCards.filter(c => {
    let appears_in_combo = Cards.boughtCombos.filter(combo => combo.comboRefIds.find(_id => _id === c.id) !== undefined).length > 0
    return !appears_in_combo
  })

  // let subproject = Projects.selected.getSubproject()

  function handleNext() {
    SGMSClient.analyticsApi.action("conclusion.btn.nextpage.select")
    sendAnalyticsLeaveEvents()

    setPage(page => page + 1)
  }

  function handlePrev() {
    SGMSClient.analyticsApi.action("conclusion.btn.previouspage.select")
    sendAnalyticsLeaveEvents()

    setPage(page => page - 1)
  }

  useEffect(() => {
    if(carouselRef.current) {
      carouselRef.current.scrollTo({
        top: 0,
        left: carouselRef.current.offsetWidth * page,
        behavior: 'smooth'
      })
    }
  }, [page]);

  function sendAnalyticsLeaveEvents() {
    SGMSClient.analyticsApi.navigationLeave("conclusion.page" + page)
    if(page < cards.length) {
      SGMSClient.analyticsApi.contentHide("conclusion.card." + cards[page].id)
    }
  }

  useEffect(() => {
    SGMSClient.analyticsApi.navigationEnter("conclusion.page" + page)
    if(page < cards.length){
      SGMSClient.analyticsApi.contentShow("conclusion.card." + cards[page].id)
    }
  }, [page]);

  useEffect(() =>{
    let averageScore = (Criterias.get("participation").value + Criterias.get("inclusion").value
                        + Criterias.get("sustainability").value + Criterias.get("quality").value) / 4

    SGMSClient.analyticsApi.score(Projects.selected.id + ".participation", Criterias.get("participation").value)
    SGMSClient.analyticsApi.score(Projects.selected.id + ".inclusion", Criterias.get("inclusion").value)
    SGMSClient.analyticsApi.score(Projects.selected.id + ".sustainability", Criterias.get("sustainability").value)
    SGMSClient.analyticsApi.score(Projects.selected.id + ".quality", Criterias.get("quality").value)
    SGMSClient.analyticsApi.score(Projects.selected.id + ".average", averageScore)

  }, [])

  function handleQuit() {
    SGMSClient.analyticsApi.action("conclusion.btn.exit.select")
    AppState.loadMenu()
  }

  let victory = Axes.selected.calculateVictory()


  useEffect(() => {
    let averageScore = (Criterias.get("participation").value + Criterias.get("inclusion").value
                        + Criterias.get("sustainability").value + Criterias.get("quality").value) / 4
    let scores = {
      "Participation" : Criterias.get("participation").value,
      "Inclusion"     : Criterias.get("inclusion").value,
      "Sustainability": Criterias.get("sustainability").value,
      "Quality"       : Criterias.get("quality").value,
      "Victory"       : victory
    }

    AppState.meetingSendScores(objectToHtmlString(scores))
  }, []);

  return(
    <div className="Wrapup" >
      <div className="carousel">
        <div className="carousel-track" ref={carouselRef}>
          <div className="slide"> <Newspaper victory={victory} /></div>
          {cards.map(c => <div  key={c.id} className="slide"> <WrapupCardScreen card={c} /></div>)}
          <div className="slide">
            <div className="slide-popup">
              {victory && <h1>{Texts.get("ending_good_title")}</h1>}
              {!victory && <h1>{Texts.get("ending_bad_title")}</h1>}

              {victory && <p>{Texts.get("ending_good_text")}</p>}
              {!victory && <p>{Texts.get("ending_bad_text")}</p>}

              {victory && <Button pill onClick={handleQuit}>{Texts.get("ending_good_btn")}</Button>}
              {!victory && <Button pill onClick={handleQuit}>{Texts.get("ending_bad_btn")}</Button>}
            </div>
          </div>
        </div>


        {page > 0  && <div className="arrow prev" onClick={handlePrev}><FaChevronLeft /></div>}
        {page < cards.length + 1 && <div className="arrow next" onClick={handleNext}><FaChevronRight /></div>}

      </div>


      <BurgerMenu />
    </div>)


}

export default observer(Wrapup)
