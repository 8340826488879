import React from 'react';
import Card from '../../Shared/Card/Card';
import './CardsList.css';



const CardsList = ({cards, showPickButton=true}) => {

  return (
    <div className="CardsList">
      {cards.map((card, i) => <Card card={card} key={card.id + "_" + i} showPickButton={showPickButton} />)}
    </div>
  );
};

export default CardsList;
