import React from 'react';
import Texts from '../../js/Texts';
import CriteriaIcon from '../Shared/CriteriaIcon/CriteriaIcon';
import './LoadingScreen.css';

// on ne peut pas forcément utiliser les Criterias, ou les textes,
// car cet écran est utilisé avant qu'ils soient chargés (c'est un peu son but)
// du coup je texte les Texts et je fournis des textes par défaut

const LoadingScreen = () => {

  let defTexts = [
    {id: "criteria_sustainability_title", text: "Sustainability"},
    {id: "criteria_quality_title", text: "Quality"},
    {id: "criteria_participation_title", text: "Participation"},
    {id: "criteria_inclusion_title", text: "Inclusion"}
  ]

  let texts = defTexts
  .map(t => Texts.get(t.id))
  .map((t, i)=> {
    if(t === defTexts[i].id) return defTexts[i].text
    else return t
  })

  return (
    <div className="LoadingScreen">

      <CriteriaIcon size={80} id="sustainability" selected={true} />
      <CriteriaIcon size={80} id="quality" selected={true} />
      <CriteriaIcon size={80} id="participation" selected={true} />
      <CriteriaIcon size={80} id="inclusion" selected={true} />

      <div className="loading-titles">
        <div className="loading-sustainability">{texts[0]}</div>
        <div className="loading-quality">{texts[1]}</div>
        <div className="loading-participation">{texts[2]}</div>
        <div className="loading-inclusion">{texts[3]}</div>
      </div>


    </div>
  );
};

export default LoadingScreen;
