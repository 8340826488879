import React, { useState, useEffect } from 'react'
import Button from '../Button/Button';
import burger from "../../../images/burger.svg"
import './BurgerMenu.css'

import Texts from '../../../js/Texts';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import AppState, { SCREENS } from '../../../js/AppState';
import { observer } from 'mobx-react-lite';
import { formatTimezone } from 'dateformat';
import { formatTime } from '../../../js/utils';

const BurgerMenu = () => {




  const [opened, setOpened] = useState(false);

  function handleGoToMainMenu() {
    SGMSClient.analyticsApi.action("burgermenu.btn.mainmenu.select")
    AppState.loadMenu()
  }

  function togglePill() {
    SGMSClient.analyticsApi.action("burgermenu.toggle.open.select")
    setOpened(!opened);
  }


  return (
    <div className="BurgerMenu">
      <Button className="BurgerMenu-btn" pill onClick={togglePill}>
        <img src={burger} alt="burger icon" />
      </Button>

      {opened &&
      <div className="BurgerMenu-popup">
        <Button pill smallShadow onClick={handleGoToMainMenu}>{Texts.get("menu_btn_main_menu")}</Button>
        {/* <Button pill smallShadow className="disconnect-btn">{Texts.get("menu_btn_disconnect")}</Button> */}
      </div>}

      {SGMSClient.currentMeeting && AppState.currentlyOnGameScreen &&
      <Button className="BurgerMenu-clock">
       {formatTime(SGMSClient.sequenceManager.currentTimeSecs)} / {formatTime(SGMSClient.sequenceManager.duration)}
      </Button>}
    </div>
  );
};

export default observer(BurgerMenu)
