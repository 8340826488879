import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import AppState from '../../../js/AppState';
import Categories from '../../../js/Categories';
import Criterias from '../../../js/Criterias';
import SequencesManager from '../../../js/SequencesManager/SequencesManager';
import Boussole from '../../Shared/Boussole/Boussole';
import Message from '../Message/Message';
import './Messages.css';
import SGMSClient from '../../../js/SGMS/SGMSClient';


const Messages = () => {
  const ctnrRef = useRef(null)

  // on utilise ceci pour refresh le state, car les datas sont dans "sequencesManager" qui n'est pas observable
  // dupliquer les datas dans un state ici crée plus de bugs qu'autre chose
  const [refresh, setRefresh] = useState(0);

  const [showCards, setShowCards] = useState(false);
  const [showChoices, setShowChoices] = useState(false);



  let allMessages
  if(Categories.selected) {
    allMessages = SequencesManager.findFile(Categories.selected.id).getCurrentHistory()
  } else {
    allMessages = []
  }

  let currentMessage = allMessages[allMessages.length - 1]

  // à l'affichage d'une conversation : on scroll le + possible immédiatement
  useEffect(() => {
    setRefresh(r => r+1)
    scroll(false)
  }, [Categories.selected]);


  function next() {
    if(!Categories.selected.notified) return


    SGMSClient.analyticsApi.contentShow("ph2.message." + Categories.selected.id + '_' + currentMessage.id)

    if(currentMessage) {
      if(currentMessage.getViewCount() === 0) {
        Categories.unlockSequences(currentMessage.raw.unlock_sequences)
      }
    }


    if(currentMessage.raw.unlock_cards) setShowCards(true)

    if(SequencesManager.hasNext()) {
      SequencesManager.next()
      setShowCards(false)
      setShowChoices(false)
      setRefresh(r => r+1)
    } else {
      // ici c'est la fin
      let c = Categories.selected
      if(c.unlockedDialsSequences.length > 0) {
        SequencesManager.playFile(c.id, c.lastUnlockedSequence)
      }else {
        Categories.selected.notified = false
      }

      AppState.saveGame()
    }
  }

  function scroll(smooth) {
    if(AppState.debugMode) smooth = false

    ctnrRef.current.scrollTo({
      top: ctnrRef.current.scrollHeight,
      behavior: smooth ? 'smooth' : 'auto'
    })
  }

  function handleBoussoleClick(){
    AppState.showBoussolePopup = true;


    SGMSClient.analyticsApi.action("ph2.btn.typeExplenation.select")
    SGMSClient.analyticsApi.contentShow("typeExplenation")
    SGMSClient.analyticsApi.navigationEnter("popup.typeExplenation")
  }

  let selectedCriterias = Criterias.ids.filter(id => currentMessage?.raw[id])

  function handleCtnrClick() {
    if(!currentMessage) return

    if(currentMessage.raw.unlock_cards) {
      setShowCards(true)
      return
    }

    if(currentMessage.Type === "choice_title") {
      setShowChoices(true)
      return
    }

    SGMSClient.analyticsApi.action("ph2.message." + currentMessage.id + ".select")

    next()
  }

  return (
    <div className="Messages" >
      <div className="Messages-content" ref={ctnrRef} onClick={handleCtnrClick} >
        {allMessages.map((message, index) => {
          let showName = allMessages[index - 1] && (allMessages[index-1].raw.Character === message.raw.Character)
          let key = Categories.selected.id + "-" + message.id
          let isLast = index === allMessages.length - 1

          return (
            <Message
            showCards={showCards}
            showChoices={showChoices}
            key={key}
            isLast={isLast}
            showName={showName}
            message={message}
            onShown={() => scroll(true)}
            onClick={next} />
          )
        })}

        {/* {dials.length === 0 && <Message message={{raw: {Text: Texts.get('ph_2_select_conversation')}}} />} */}
      </div>

      {Categories.selected?.notified &&
      <div className="boussole-messages" onClick={handleBoussoleClick}>
        <Boussole selectedIds={selectedCriterias} size={50} />
      </div>}

    </div>
  );
};

export default observer(Messages)
