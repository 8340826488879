import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import icon_europe from "../../../images/menu/icon_Europe.svg";
import icon_play from "../../../images/menu/icon_play.svg";
import icon_quiz from "../../../images/menu/icon_quizz.svg";
import logo from "../../../images/menu/Logo_game.svg";
import AppState, { SCREENS } from '../../../js/AppState';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Texts from '../../../js/Texts';
import Button from '../../Shared/Button/Button';
import ChooseSavegamePopup from '../ChooseSavegamePopup/ChooseSavegamePopup';
import MenuBottom from '../MenuBottom/MenuBottom';
import GroupCode from './GroupCode/GroupCode';
import './MenuScreen.css';

const MenuButton = ({icon, disabled, subBtns = null, onClick, className}) => {
  return (
    <div className={"menu-btn " + className}>
      {!subBtns &&
      <Button pill disabled={disabled} onClick={onClick}>
        <img src={icon} alt="icon" />
      </Button>}
      {subBtns}
    </div>
  )
}


const MenuScreen = () => {

  const [showContinueGameBtns, setShowContinueGameBtns] = useState(false);


  function handlePlayClick()  {
    if(!AppState.savedGame){
      AppState.loadPhase1()
    } else {
      setShowContinueGameBtns(1)
    }
  }

  const subBtns1 = (
  <div className="sub-btns">
    <Button pill onClick={() => setShowContinueGameBtns(2)}>{Texts.get("menu_new_game")}</Button>
    <Button pill onClick={() => AppState.loadPhase1(false)}>{Texts.get("menu_continue_game")}</Button>
  </div>)

  const subBtns2 = (
  <div className="sub-btns">
    <h3>{Texts.get("menu_erase_game_confirm")}</h3>
    <Button pill onClick={() => AppState.loadPhase1(true)} >{Texts.get("yes")}</Button>
    <Button pill onClick={() => setShowContinueGameBtns(1)} >{Texts.get("no")}</Button>
  </div>)

  function logout(e) {
    e.preventDefault();
    const { LocalStorageUserKey, Universe, App } = window.CONFIG.server

    AppState.openid
    .logout() // logging out from KC
    .then(() => SGMSClient.userDisconnect()) // login out from SGMS
    .then(() => SGMSClient.anonymousReconnect(Universe, App, LocalStorageUserKey) ) // reco à l'anonymous
    .then(() => AppState.loadSavegame()) // recharge de la sauvegarde du LS
    .catch(err => console.error(err))
  }

  return (
    <div className="MenuScreen">
      <div className="menu-top">


        <div className="menu-top-left">
          {SGMSClient.currentUser && !SGMSClient.isAnonymous &&
          <GroupCode />}
        </div>

        {(!SGMSClient.currentUser || SGMSClient.isAnonymous) && <Button className="signin-btn" onClick={() => AppState.setScreen(SCREENS.LOGIN)} pill>{Texts.get("menu_sign_in")}</Button>}

        {SGMSClient.currentUser && !SGMSClient.isAnonymous &&
        <div className="loggedin-user">
          {SGMSClient.currentUser.UserPseudo}
          <a href="#" onClick={logout}>{Texts.get("menu_logout")}</a>
        </div>}
      </div>
      <div className="logo">
        <img src={logo} alt="game logo" />
      </div>

      <div className="menu-btns-ctnr">
        <div className="menu-btns-strip">
          <div className="menu-btns">
            <MenuButton className="menu-1" icon={icon_europe} onClick={() => AppState.loadMap()} />
            <MenuButton
            className="menu-2"
            icon={icon_play}
            disabled={SGMSClient.currentMeeting !== null}
            onClick={handlePlayClick}
            subBtns = {showContinueGameBtns === 1 ? subBtns1 : showContinueGameBtns === 2 ? subBtns2 : null}
            />
            <MenuButton className="menu-3" icon={icon_quiz} onClick={() => AppState.loadQuiz()} />
          </div>
        </div>
        <div className="menu-texts">
          <div className="menu-text">{Texts.get("menu_map")}</div>
          <div className="menu-text">{Texts.get("menu_game")}</div>
          <div className="menu-text">{Texts.get("menu_quiz")}</div>
        </div>
      </div>

      {AppState.menuShowSavegamePopup && <ChooseSavegamePopup />}

      <MenuBottom />
    </div>
  );
};

export default  observer(MenuScreen)
