import React from 'react';
import SGMSClient from '../../../../js/SGMS/SGMSClient';
import './CardPh2.css';


const CardPh2 = ({card, forceEmpty, onClick}) => {
  // const [state, setState] = useState(null);
  function handleClick(e) {
    SGMSClient.analyticsApi.action("ph2.card." + card.id + ".select")

    e.stopPropagation()

    if(onClick) onClick(card)
  }

  const empty = !card || forceEmpty
  return (
    <div className={"CardPh2" + (empty ? " empty" : "")} title={card.id} onClick={handleClick}>
      {!empty && <div className="title">{card.title}</div>}
      {empty && <div className="title title-big">?</div>}

    </div>
  );
};

export default CardPh2;
