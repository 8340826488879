import { makeAutoObservable } from "mobx";
import AppState, { SCREENS } from "./AppState";
import Categories from "./Categories";
import Project from "./Project";


class Projects {

  selected = null

  constructor() {
    makeAutoObservable(this)
  }

  init(projects) {
    this.all = []

    projects
    .filter(p => p.id !== null)
    .forEach(p => {

      if(p.name !== null) {
       this.all.push(new Project(p, this.all.length % 2))
      }
      else {
        // c'est une ligne de type "subproject"
        let proj = this.get(p.id)
        proj.addSubproject(p)
      }
    })
  }

  select(projectId) {
    this.selected = this.get(projectId)
  }

  get(id) {
    return this.all.find(p => p.id === id)
  }


  // en fin de ph3
  submit() {
    this.selected.pickComboCards()
    this.selected.pickOpposites()
  }

  get transverse_categories() {
    return Categories.all.filter(c => c.project === null)
  }

  save() {
    if(this.selected) {
      return {id: this.selected.id}
    }
    return null

  }

  load(data) {
    if(!data) return

    let p = this.get(data.id)
    if(p) { this.selected = p }

  }

}


export default new Projects()