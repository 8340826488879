// Generated by Haxe 4.1.4
(function ($hx_exports, $global) { "use strict";
$hx_exports["sequences"] = $hx_exports["sequences"] || {};
function $extend(from, fields) {
	var proto = Object.create(from);
	for (var name in fields) proto[name] = fields[name];
	if( fields.toString !== Object.prototype.toString ) proto.toString = fields.toString;
	return proto;
}
var HxOverrides = function() { };
HxOverrides.__name__ = true;
HxOverrides.substr = function(s,pos,len) {
	if(len == null) {
		len = s.length;
	} else if(len < 0) {
		if(pos == 0) {
			len = s.length + len;
		} else {
			return "";
		}
	}
	return s.substr(pos,len);
};
HxOverrides.now = function() {
	return Date.now();
};
var Lambda = function() { };
Lambda.__name__ = true;
Lambda.find = function(it,f) {
	var v = $getIterator(it);
	while(v.hasNext()) {
		var v1 = v.next();
		if(f(v1)) {
			return v1;
		}
	}
	return null;
};
Lambda.findIndex = function(it,f) {
	var i = 0;
	var v = $getIterator(it);
	while(v.hasNext()) {
		var v1 = v.next();
		if(f(v1)) {
			return i;
		}
		++i;
	}
	return -1;
};
Math.__name__ = true;
var Reflect = function() { };
Reflect.__name__ = true;
Reflect.field = function(o,field) {
	try {
		return o[field];
	} catch( _g ) {
		return null;
	}
};
Reflect.fields = function(o) {
	var a = [];
	if(o != null) {
		var hasOwnProperty = Object.prototype.hasOwnProperty;
		for( var f in o ) {
		if(f != "__id__" && f != "hx__closures__" && hasOwnProperty.call(o,f)) {
			a.push(f);
		}
		}
	}
	return a;
};
var Std = function() { };
Std.__name__ = true;
Std.string = function(s) {
	return js_Boot.__string_rec(s,"");
};
var StringTools = function() { };
StringTools.__name__ = true;
StringTools.replace = function(s,sub,by) {
	return s.split(sub).join(by);
};
var haxe_Exception = function(message,previous,native) {
	Error.call(this,message);
	this.message = message;
	this.__previousException = previous;
	this.__nativeException = native != null ? native : this;
};
haxe_Exception.__name__ = true;
haxe_Exception.caught = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value;
	} else if(((value) instanceof Error)) {
		return new haxe_Exception(value.message,null,value);
	} else {
		return new haxe_ValueException(value,null,value);
	}
};
haxe_Exception.__super__ = Error;
haxe_Exception.prototype = $extend(Error.prototype,{
	unwrap: function() {
		return this.__nativeException;
	}
});
var haxe_ValueException = function(value,previous,native) {
	haxe_Exception.call(this,String(value),previous,native);
	this.value = value;
};
haxe_ValueException.__name__ = true;
haxe_ValueException.__super__ = haxe_Exception;
haxe_ValueException.prototype = $extend(haxe_Exception.prototype,{
	unwrap: function() {
		return this.value;
	}
});
var haxe_ds_StringMap = function() {
	this.h = Object.create(null);
};
haxe_ds_StringMap.__name__ = true;
haxe_ds_StringMap.kvIterator = function(h) {
	var keys = Object.keys(h);
	var len = keys.length;
	var idx = 0;
	return { hasNext : function() {
		return idx < len;
	}, next : function() {
		idx += 1;
		var k = keys[idx - 1];
		return { key : k, value : h[k]};
	}};
};
var haxe_iterators_ArrayIterator = function(array) {
	this.current = 0;
	this.array = array;
};
haxe_iterators_ArrayIterator.__name__ = true;
haxe_iterators_ArrayIterator.prototype = {
	hasNext: function() {
		return this.current < this.array.length;
	}
	,next: function() {
		return this.array[this.current++];
	}
};
var js_Boot = function() { };
js_Boot.__name__ = true;
js_Boot.__string_rec = function(o,s) {
	if(o == null) {
		return "null";
	}
	if(s.length >= 5) {
		return "<...>";
	}
	var t = typeof(o);
	if(t == "function" && (o.__name__ || o.__ename__)) {
		t = "object";
	}
	switch(t) {
	case "function":
		return "<function>";
	case "object":
		if(((o) instanceof Array)) {
			var str = "[";
			s += "\t";
			var _g = 0;
			var _g1 = o.length;
			while(_g < _g1) {
				var i = _g++;
				str += (i > 0 ? "," : "") + js_Boot.__string_rec(o[i],s);
			}
			str += "]";
			return str;
		}
		var tostr;
		try {
			tostr = o.toString;
		} catch( _g ) {
			return "???";
		}
		if(tostr != null && tostr != Object.toString && typeof(tostr) == "function") {
			var s2 = o.toString();
			if(s2 != "[object Object]") {
				return s2;
			}
		}
		var str = "{\n";
		s += "\t";
		var hasp = o.hasOwnProperty != null;
		var k = null;
		for( k in o ) {
		if(hasp && !o.hasOwnProperty(k)) {
			continue;
		}
		if(k == "prototype" || k == "__class__" || k == "__super__" || k == "__interfaces__" || k == "__properties__") {
			continue;
		}
		if(str.length != 2) {
			str += ", \n";
		}
		str += s + k + " : " + js_Boot.__string_rec(o[k],s);
		}
		s = s.substring(1);
		str += "\n" + s + "}";
		return str;
	case "string":
		return o;
	default:
		return String(o);
	}
};
var sequences_Element = function(id,seqId,goto,type,actions,visibleIf) {
	this.id = id;
	this.SequenceId = seqId;
	this.Goto = goto;
	this.Type = type;
	this.VisibleIf = visibleIf;
	this.createVisibleCondition(visibleIf);
	this.Actions = [];
	if(actions != null) {
		var _actions2 = actions.split(";");
		var _g = 0;
		while(_g < _actions2.length) {
			var a = _actions2[_g];
			++_g;
			var _action3 = a.split("/");
			this.Actions.push(_action3);
		}
	}
};
sequences_Element.__name__ = true;
sequences_Element.createFromObject = function(data,columns) {
	var id = Reflect.field(data,columns.id);
	var seqId = Reflect.field(data,columns.SequenceId);
	var goto = Reflect.field(data,columns.Goto);
	var type = Reflect.field(data,columns.Type);
	var actions = Reflect.field(data,columns.Actions);
	var visibleIf = Reflect.field(data,columns.VisibleIf);
	var el = new sequences_Element(id,seqId,goto,type,actions,visibleIf);
	el.raw = data;
	return el;
};
sequences_Element.prototype = {
	isChoice: function() {
		return false;
	}
	,createVisibleCondition: function(visibleIf) {
		if(visibleIf == null || visibleIf == "") {
			return;
		}
		visibleIf = StringTools.replace(visibleIf,"once","(#@" + this.id + "# < 1)");
		this.visibleIfCondition = sequences_condition_ConditionUtils.createCondition(visibleIf);
	}
	,playActions: function() {
		var _g = 0;
		var _g1 = this.Actions;
		while(_g < _g1.length) {
			var a = _g1[_g];
			++_g;
			sequences_SequencesManager.instance.playAction(a[0],a.slice(1));
		}
	}
	,getFilename: function() {
		if(this.parentFile != null) {
			return this.parentFile.filename;
		} else {
			return "";
		}
	}
	,incrementViewCount: function() {
		sequences_SequencesManager.instance.variables.incrementViewCount(this.getFilename(),this.id);
	}
	,getViewCount: function() {
		return sequences_SequencesManager.instance.variables.getViewCount(this.getFilename(),this.id);
	}
	,isVisible: function() {
		if(this.visibleIfCondition != null) {
			var conditionResult;
			try {
				conditionResult = this.visibleIfCondition.evaluate(this);
			} catch( _g ) {
				var d = haxe_Exception.caught(_g).unwrap();
				var msg = "Exception while trying to evaluate 'visibleIf' condition for id \"" + this.id + "\" (file : " + (this.parentFile != null ? this.parentFile.filename : "<NULL>") + ") :";
				msg += Std.string(d);
				$global.console.error(msg);
				conditionResult = true;
			}
			return conditionResult;
		} else {
			return true;
		}
	}
};
var sequences_ElementChoice = function(id,seqId,goto,type,actions,visibleIf) {
	sequences_Element.call(this,id,seqId,goto,type,actions,visibleIf);
	this.choices = [];
	this.userAnswers = [];
};
sequences_ElementChoice.__name__ = true;
sequences_ElementChoice.createFromObject = function(data,columns) {
	var id = Reflect.field(data,columns.id);
	var seqId = Reflect.field(data,columns.SequenceId);
	var goto = Reflect.field(data,columns.Goto);
	var type = Reflect.field(data,columns.Type);
	var actions = Reflect.field(data,columns.Actions);
	var visibleIf = Reflect.field(data,columns.VisibleIf);
	var el = new sequences_ElementChoice(id,seqId,goto,type,actions,visibleIf);
	el.raw = data;
	return el;
};
sequences_ElementChoice.__super__ = sequences_Element;
sequences_ElementChoice.prototype = $extend(sequences_Element.prototype,{
	isChoice: function() {
		return true;
	}
	,addChoice: function(choice) {
		this.choices.push(choice);
	}
	,getAllChoices: function() {
		return this.choices;
	}
	,getVisibleChoices: function() {
		var visibles = [];
		var _g = 0;
		var _g1 = this.choices;
		while(_g < _g1.length) {
			var c = _g1[_g];
			++_g;
			if(c.isVisible()) {
				visibles.push(c);
			}
		}
		return visibles;
	}
	,getChoiceById: function(id) {
		return Lambda.find(this.choices,function(element) {
			return element.id == id;
		});
	}
	,getRemainingChoices: function() {
		var _gthis = this;
		var _g = [];
		var _g1 = 0;
		var _g2 = this.choices;
		while(_g1 < _g2.length) {
			var v = _g2[_g1];
			++_g1;
			if(_gthis.userAnswers.indexOf(v.id) == -1) {
				_g.push(v);
			}
		}
		return _g;
	}
	,addAnswer: function(id) {
		this.userAnswers.unshift(id);
	}
	,getAnswerId: function() {
		return this.getLastAnswerId();
	}
	,getLastAnswerId: function() {
		if(this.userAnswers.length < 1) {
			return null;
		} else {
			return this.userAnswers[0];
		}
	}
	,getLastAnswer: function() {
		if(this.userAnswers.length < 1) {
			return null;
		} else {
			return this.getChoiceById(this.userAnswers[0]);
		}
	}
	,getAllAnswerIds: function() {
		return this.userAnswers;
	}
});
var sequences_Sequence = function(startElement,id) {
	this.startElement = startElement;
	if(id != null) {
		this.id = id;
	} else if(startElement != null) {
		this.id = startElement.parentFile.filename + ":" + startElement.id;
	} else {
		this.id = "unknown";
	}
	this.started = false;
	this.ended = false;
	this.history = [];
};
sequences_Sequence.__name__ = true;
sequences_Sequence.prototype = {
	start: function() {
		this.started = true;
		if(this.startElement != null) {
			if(!this.startElement.isVisible()) {
				var nextElem = this.handleGoto(this.startElement);
				if(nextElem == null) {
					nextElem = this.getDefaultNextElement(this.startElement);
				}
				if(nextElem != null) {
					this.switchToCurrent(nextElem);
				} else {
					this.ended = true;
				}
			} else {
				this.switchToCurrent(this.startElement);
			}
		} else {
			this.ended = true;
		}
	}
	,end: function() {
		this.ended = true;
		this.nextElement = null;
		this.currentElement = null;
	}
	,getId: function() {
		return this.id;
	}
	,hasNext: function() {
		if(this.checkAndDumpErrors()) {
			return false;
		}
		if(!this.ended) {
			if(!this.started) {
				return this.startElementHasNext();
			} else {
				return this.handleHasNext();
			}
		} else {
			return false;
		}
	}
	,handleHasNext: function() {
		this.finaliseCurrent();
		return this.nextElement != null;
	}
	,startElementHasNext: function() {
		if(this.startElement == null) {
			return false;
		} else if(this.startElement.isVisible()) {
			return true;
		} else {
			var nextElem = this.handleGoto(this.startElement);
			if(nextElem == null) {
				nextElem = this.getDefaultNextElement(this.startElement);
			}
			return nextElem != null;
		}
	}
	,next: function() {
		if(this.checkAndDumpErrors()) {
			return null;
		}
		if(!this.ended) {
			if(!this.started) {
				this.start();
			} else {
				this.handleNext();
			}
		}
		return this.currentElement;
	}
	,handleNext: function() {
		this.finaliseCurrent();
		if(this.nextElement != null) {
			this.switchToCurrent(this.nextElement);
		} else {
			this.end();
		}
	}
	,switchToCurrent: function(elem) {
		this.history.push(elem);
		elem.parentFile.history.push(elem.id);
		this.currentElementFinalised = false;
		this.currentElement = elem;
	}
	,finaliseCurrent: function() {
		if(this.currentElementFinalised) {
			return;
		}
		if(this.currentElement.Type == sequences_ElementTypes.ChoiceTitle) {
			this.finaliseChoice(this.currentElement);
			if(this.checkAndDumpErrors()) {
				return;
			}
		}
		this.currentElement.playActions();
		this.currentElement.incrementViewCount();
		this.nextElement = this.handleGoto(this.currentElement);
		if(this.nextElement == null) {
			this.nextElement = this.getDefaultNextElement(this.currentElement);
		}
		this.currentElementFinalised = true;
	}
	,finaliseChoice: function(elemChoice) {
		elemChoice.playActions();
		elemChoice.incrementViewCount();
		this.currentElement = elemChoice.getLastAnswer();
		if(this.currentElement == null) {
			var ids = elemChoice.getAllAnswerIds().join(";");
			this.addError("choice \"" + elemChoice.id + "\" getLastAnswer() returns null => given user answers : [" + ids + "]");
		}
	}
	,handleGoto: function(elem) {
		var goto = elem.Goto;
		if(goto != null) {
			var filePath = "";
			var elemId;
			var fileDelimiterIndex = goto.indexOf(":");
			if(fileDelimiterIndex > -1) {
				filePath = goto.substring(0,fileDelimiterIndex);
				elemId = HxOverrides.substr(goto,fileDelimiterIndex + 1,null);
				var file = sequences_SequencesManager.instance.findFile(filePath);
				if(file != null) {
					return this.findGotoInFile(file,elemId);
				} else {
					return null;
				}
			} else {
				elemId = goto;
				return this.findGotoInFile(elem.parentFile,goto);
			}
		} else {
			return null;
		}
	}
	,findGotoInFile: function(file,goto) {
		var i = file.findFirstVisibleSequenceElementIndex(goto);
		if(i < 0) {
			i = file.findElementIndex(goto);
		}
		if(i > -1) {
			var elem = file.elements[i];
			if(elem.isVisible()) {
				return elem;
			}
		}
		return null;
	}
	,getDefaultNextElement: function(elem) {
		var file = elem.parentFile;
		var index = file.findElementIndex(elem.id);
		return this.findNextVisibleInSequence(file.elements,index,elem.SequenceId);
	}
	,findNextVisibleInSequence: function(elements,fromIndex,sequenceId) {
		var i = fromIndex + 1;
		if(i >= elements.length) {
			return null;
		}
		var elem;
		while(i < elements.length) {
			elem = elements[i];
			if(elem.SequenceId != sequenceId) {
				return null;
			} else if(elem.isVisible()) {
				return elem;
			} else {
				++i;
			}
		}
		return null;
	}
	,getCurrentElement: function() {
		if(this.checkAndDumpErrors()) {
			return null;
		}
		if(!this.started) {
			this.start();
		}
		return this.currentElement;
	}
	,getCurrentFile: function() {
		if(this.started) {
			if(this.currentElement != null) {
				return this.currentElement.parentFile;
			} else {
				return null;
			}
		} else if(this.startElement != null) {
			return this.startElement.parentFile;
		} else {
			return null;
		}
	}
	,getHistory: function() {
		return this.history;
	}
	,addError: function(e) {
		if(this.errors == null) {
			this.errors = [];
		}
		this.errors.push(e);
	}
	,hasError: function() {
		if(this.errors != null) {
			return this.errors.length > 0;
		} else {
			return false;
		}
	}
	,getErrors: function() {
		return this.errors;
	}
	,getLastError: function() {
		if(this.errors != null) {
			return this.errors[this.errors.length - 1];
		} else {
			return null;
		}
	}
	,traceErrors: function() {
		if(this.hasError()) {
			var msg = "** ERRORS for Sequence \"" + this.id + "\" :\n";
			msg += "********************************************\n";
			var _g = 0;
			var _g1 = this.errors;
			while(_g < _g1.length) {
				var e = _g1[_g];
				++_g;
				msg += "** " + e + "\n";
			}
			msg += "********************************************";
			$global.console.error(msg);
		}
	}
	,checkAndDumpErrors: function() {
		if(this.hasError()) {
			this.traceErrors();
			this.currentElement = null;
			this.nextElement = null;
			return true;
		} else {
			return false;
		}
	}
};
var sequences_ElementTypes = function() { };
sequences_ElementTypes.__name__ = true;
var sequences_SequencesFile = $hx_exports["sequences"]["SequencesFile"] = function(name,elems) {
	this.filename = name;
	this.history = [];
	var _g = [];
	var _g1 = 0;
	var _g2 = elems;
	while(_g1 < _g2.length) {
		var v = _g2[_g1];
		++_g1;
		if(v.id != null && v.id != "") {
			_g.push(v);
		}
	}
	this.elements = _g;
	var _g_current = 0;
	var _g_array = this.elements;
	while(_g_current < _g_array.length) {
		var _g1_value = _g_array[_g_current];
		var _g1_key = _g_current++;
		var i = _g1_key;
		var el = _g1_value;
		el.parentFile = this;
		if(el.Type == sequences_ElementTypes.ChoiceTitle) {
			var testIndex = i + 1;
			var elChoice = el;
			while(testIndex < this.elements.length && this.elements[testIndex].Type == sequences_ElementTypes.ChoiceAnswer) {
				elChoice.addChoice(this.elements[testIndex]);
				++testIndex;
			}
		}
	}
	sequences_SequencesManager.instance.variables.addFile(this);
	var _g = [];
	var _g1 = 0;
	var _g2 = this.elements;
	while(_g1 < _g2.length) {
		var v = _g2[_g1];
		++_g1;
		if(v.Type != sequences_ElementTypes.ChoiceAnswer) {
			_g.push(v);
		}
	}
	this.elements = _g;
};
sequences_SequencesFile.__name__ = true;
sequences_SequencesFile.createFromDataFile = function(name,data,columns) {
	if(columns == null) {
		columns = new sequences_utils_ElementColumns();
	}
	var result = new Array(data.length);
	var _g = 0;
	var _g1 = data.length;
	while(_g < _g1) {
		var i = _g++;
		var el = data[i];
		var element;
		var type = Reflect.field(el,columns.Type);
		if(type == sequences_ElementTypes.ChoiceTitle) {
			element = sequences_ElementChoice.createFromObject(el,columns);
		} else {
			element = sequences_Element.createFromObject(el,columns);
		}
		result[i] = element;
	}
	var elements = result;
	var file = new sequences_SequencesFile(name,elements);
	return file;
};
sequences_SequencesFile.prototype = {
	findFirstSequenceElementIndex: function(sequenceId) {
		return Lambda.findIndex(this.elements,function(elem) {
			return elem.SequenceId == sequenceId;
		});
	}
	,findFirstVisibleSequenceElementIndex: function(sequenceId) {
		return Lambda.findIndex(this.elements,function(elem) {
			if(elem.SequenceId == sequenceId) {
				return elem.isVisible();
			} else {
				return false;
			}
		});
	}
	,findElementIndex: function(id) {
		var i = Lambda.findIndex(this.elements,function(elem) {
			return elem.id == id;
		});
		return i;
	}
	,getElementById: function(id) {
		return Lambda.find(this.elements,function(el) {
			return el.id == id;
		});
	}
	,createSequence: function(sequenceId) {
		var i = this.findFirstVisibleSequenceElementIndex(sequenceId);
		if(i > -1) {
			return new sequences_Sequence(this.elements[i],this.filename + ":" + sequenceId);
		} else {
			return null;
		}
	}
	,getCurrentHistory: function() {
		var _gthis = this;
		var _this = this.history;
		var result = new Array(_this.length);
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = _gthis.getElementById(_this[i]);
		}
		return result;
	}
};
var sequences_Variables = function() {
	this.externalVariableGetter = null;
	this.viewCounts = new haxe_ds_StringMap();
};
sequences_Variables.__name__ = true;
sequences_Variables.prototype = {
	addFile: function(f) {
		var vars = new haxe_ds_StringMap();
		var _g = 0;
		var _g1 = f.elements;
		while(_g < _g1.length) {
			var el = _g1[_g];
			++_g;
			vars.h[el.id] = 0;
		}
		this.viewCounts.h[f.filename] = vars;
	}
	,get: function(valueId) {
		if(valueId == null || valueId == "") {
			return null;
		}
		switch(valueId.charAt(0)) {
		case "?":
			return this.getExternal(HxOverrides.substr(valueId,1,null));
		case "@":
			return this.getInternal(HxOverrides.substr(valueId,1,null));
		default:
			return null;
		}
	}
	,getExternal: function(valueId) {
		if(this.externalVariableGetter != null) {
			return this.externalVariableGetter(valueId);
		} else {
			return null;
		}
	}
	,getInternal: function(valueId,file) {
		if(file == null) {
			file = "";
		}
		var filePath = file;
		var elemId;
		var filePathDelimiterIndex = valueId.indexOf(":");
		if(filePathDelimiterIndex > -1) {
			filePath = valueId.substring(0,filePathDelimiterIndex);
			elemId = HxOverrides.substr(valueId,filePathDelimiterIndex + 1,null);
		} else {
			elemId = valueId;
		}
		var viewCount = this.getViewCount(filePath,elemId);
		if(viewCount == null) {
			return 0;
		} else {
			return viewCount;
		}
	}
	,getViewCount: function(file,elemId) {
		var fileViewCounts = this.viewCounts.h[file];
		if(fileViewCounts != null) {
			return fileViewCounts.h[elemId];
		} else {
			return null;
		}
	}
	,incrementViewCount: function(file,elemId,create) {
		if(create == null) {
			create = true;
		}
		var fileViewCounts = this.viewCounts.h[file];
		if(fileViewCounts == null) {
			if(!create) {
				return;
			}
			fileViewCounts = new haxe_ds_StringMap();
			this.viewCounts.h[file] = fileViewCounts;
		}
		var elemCount = fileViewCounts.h[elemId];
		if(elemCount == null) {
			if(!create) {
				return;
			}
			elemCount = 0;
		}
		++elemCount;
		fileViewCounts.h[elemId] = elemCount;
	}
	,stateToJson: function() {
		var jsonObj = { };
		var _g = haxe_ds_StringMap.kvIterator(this.viewCounts.h);
		while(_g.hasNext()) {
			var _g1 = _g.next();
			var fileId = _g1.key;
			var counts = _g1.value;
			jsonObj[fileId] = this.countsToJson(counts);
		}
		return jsonObj;
	}
	,countsToJson: function(map) {
		var jsonObj = { };
		var _g = haxe_ds_StringMap.kvIterator(map.h);
		while(_g.hasNext()) {
			var _g1 = _g.next();
			var elemId = _g1.key;
			var count = _g1.value;
			jsonObj[elemId] = count;
		}
		return jsonObj;
	}
	,stateFromJson: function(obj) {
		var fileIds = Reflect.fields(obj);
		var fileCounts;
		var _g = 0;
		while(_g < fileIds.length) {
			var id = fileIds[_g];
			++_g;
			if(Object.prototype.hasOwnProperty.call(this.viewCounts.h,id)) {
				fileCounts = this.viewCounts.h[id];
			} else {
				fileCounts = new haxe_ds_StringMap();
				this.viewCounts.h[id] = fileCounts;
			}
			this.fillCountsFromJson(fileCounts,Reflect.field(obj,id));
		}
	}
	,fillCountsFromJson: function(counts,mapObj) {
		var elemIds = Reflect.fields(mapObj);
		var _g = 0;
		while(_g < elemIds.length) {
			var id = elemIds[_g];
			++_g;
			counts.h[id] = Reflect.field(mapObj,id);
		}
	}
};
var sequences_SequencesManager = $hx_exports["sequences"]["SequencesManager"] = function() {
	this.files = new haxe_ds_StringMap();
	this.actions = new haxe_ds_StringMap();
	this.variables = new sequences_Variables();
};
sequences_SequencesManager.__name__ = true;
sequences_SequencesManager.traceInfo = function(msg,posInfos) {
	$global.console.log(msg);
};
sequences_SequencesManager.traceError = function(msg,posInfos) {
	$global.console.error(msg);
};
sequences_SequencesManager.traceTests = function() {
	$global.console.log("Message d'info");
	$global.console.error("Message d'erreur");
};
sequences_SequencesManager.prototype = {
	addFile: function(seqFile) {
		this.files.h[seqFile.filename] = seqFile;
	}
	,findFile: function(filename) {
		return this.files.h[filename];
	}
	,playSequence: function(filename,sequenceId) {
		var seq = null;
		var file = this.findFile(filename);
		if(file != null) {
			if(sequenceId == null) {
				seq = new sequences_Sequence(file.elements[0],filename + ":null(0)");
			} else {
				seq = file.createSequence(sequenceId);
			}
		}
		if(seq != null) {
			this.currentSequence = seq;
		}
		return seq;
	}
	,playElement: function(filename,elementId) {
		var seq = null;
		var file = this.findFile(filename);
		if(file != null) {
			if(elementId == null) {
				seq = new sequences_Sequence(file.elements[0],filename + ":null(0)");
			} else {
				var elem = file.getElementById(elementId);
				if(elem != null) {
					seq = new sequences_Sequence(elem,filename + ":" + elementId);
				}
			}
		}
		if(seq != null) {
			this.currentSequence = seq;
		}
		return seq;
	}
	,playFile: function(filename,sequenceId) {
		var seq = this.playSequence(filename,sequenceId);
		if(seq != null) {
			seq.getCurrentElement();
			return true;
		} else {
			return false;
		}
	}
	,hasNext: function() {
		if(this.currentSequence != null) {
			return this.currentSequence.hasNext();
		} else {
			return false;
		}
	}
	,next: function() {
		if(this.currentSequence != null) {
			return this.currentSequence.next();
		} else {
			return null;
		}
	}
	,registerAction: function(name,func) {
		this.actions.h[name] = func;
	}
	,playAction: function(name,args) {
		var action = this.actions.h[name];
		if(action != null) {
			action(args);
		} else {
			$global.console.log("This action does not exist, you must register it first: " + name);
		}
	}
	,getCurrentElement: function() {
		if(this.currentSequence != null) {
			return this.currentSequence.getCurrentElement();
		} else {
			return null;
		}
	}
	,getCurrentHistory: function() {
		if(this.currentSequence != null) {
			return this.currentSequence.getHistory();
		} else {
			return null;
		}
	}
	,stateToJson: function() {
		var variablesJson = this.variables.stateToJson();
		var historiesJson = { };
		var _g = haxe_ds_StringMap.kvIterator(this.files.h);
		while(_g.hasNext()) {
			var _g1 = _g.next();
			var fileId = _g1.key;
			var seqFile = _g1.value;
			historiesJson[fileId] = seqFile.history;
		}
		var jsonObj = { variables : variablesJson, histories : historiesJson};
		return jsonObj;
	}
	,stateFromJson: function(obj) {
		var variablesJson = Reflect.field(obj,"variables");
		this.variables.stateFromJson(variablesJson);
		var historiesJson = Reflect.field(obj,"histories");
		var fileIds = Reflect.fields(historiesJson);
		var historyJson;
		var historyArr;
		var history;
		var seqFile;
		var _g = 0;
		while(_g < fileIds.length) {
			var id = fileIds[_g];
			++_g;
			seqFile = this.files.h[id];
			if(seqFile != null) {
				historyJson = Reflect.field(historiesJson,id);
				if(((historyJson) instanceof Array)) {
					historyArr = historyJson;
					history = [];
					var _g1 = 0;
					while(_g1 < historyArr.length) {
						var tmp = historyArr[_g1];
						++_g1;
						history.push(tmp);
					}
					seqFile.history = history;
				}
			}
		}
	}
};
var sequences_condition_ConditionUtils = function() { };
sequences_condition_ConditionUtils.__name__ = true;
sequences_condition_ConditionUtils.createCondition = function(conditionStr) {
	if(sequences_condition_ConditionUtils.customConditionCreator != null) {
		return sequences_condition_ConditionUtils.customConditionCreator(conditionStr);
	} else {
		return sequences_condition_ConditionUtils.defaultCreateCondition(conditionStr);
	}
};
sequences_condition_ConditionUtils.defaultCreateCondition = function(conditionStr) {
	return new sequences_condition_JSEvalCondition(conditionStr);
};
sequences_condition_ConditionUtils.replaceLogicalOperators = function(s) {
	s = StringTools.replace(s,"and","&&");
	s = StringTools.replace(s,"AND","&&");
	s = StringTools.replace(s,"et","&&");
	s = StringTools.replace(s,"ET","&&");
	s = StringTools.replace(s,"or","||");
	s = StringTools.replace(s,"OR","||");
	s = StringTools.replace(s,"ou","||");
	s = StringTools.replace(s,"OU","||");
	s = StringTools.replace(s,"not","!");
	s = StringTools.replace(s,"NOT","!");
	s = StringTools.replace(s,"non","!");
	s = StringTools.replace(s,"NON","!");
	return s;
};
sequences_condition_ConditionUtils.replaceVariables = function(element,s) {
	var indexStart;
	var indexEnd;
	var variableId;
	var variableValue;
	while(s.indexOf("#") > -1) {
		indexStart = s.indexOf("#") + 1;
		indexEnd = s.indexOf("#",indexStart);
		variableId = s.substring(indexStart,indexEnd);
		if(variableId.charAt(0) == "@") {
			variableValue = sequences_condition_ConditionUtils.getInternalVariable(variableId,element.parentFile.filename);
		} else {
			variableValue = sequences_condition_ConditionUtils.getVariable(variableId);
		}
		s = s.substring(0,indexStart - 1) + Std.string(variableValue) + s.substring(indexEnd + 1);
	}
	while(s.indexOf("@") > -1) {
		indexStart = s.indexOf("@");
		indexEnd = s.indexOf(" ",indexStart);
		variableId = s.substring(indexStart,indexEnd);
		variableValue = sequences_condition_ConditionUtils.getInternalVariable(variableId,element.parentFile.filename);
		s = s.substring(0,indexStart) + Std.string(variableValue) + s.substring(indexEnd);
	}
	while(s.indexOf("?") > -1) {
		indexStart = s.indexOf("?");
		indexEnd = s.indexOf(" ",indexStart);
		variableId = s.substring(indexStart,indexEnd);
		variableValue = sequences_condition_ConditionUtils.getVariable(variableId);
		s = s.substring(0,indexStart) + Std.string(variableValue) + s.substring(indexEnd);
	}
	return s;
};
sequences_condition_ConditionUtils.getInternalVariable = function(variableId,file) {
	var variableValue = sequences_SequencesManager.instance.variables.getInternal(HxOverrides.substr(variableId,1,null),file);
	if(variableValue == null) {
		$global.console.log("Internal variable (id : " + variableId + ") value is null. Using 0 instead.");
		variableValue = 0;
	}
	return variableValue;
};
sequences_condition_ConditionUtils.getVariable = function(variableId) {
	var variableValue = sequences_SequencesManager.instance.variables.get(variableId);
	if(variableValue == null) {
		$global.console.log("Variable (id : " + variableId + ") value is null. Using 0 instead.");
		variableValue = 0;
	}
	return variableValue;
};
var sequences_condition_JSEvalCondition = function(s) {
	this.baseString = s;
};
sequences_condition_JSEvalCondition.__name__ = true;
sequences_condition_JSEvalCondition.prototype = {
	evaluate: function(element) {
		if(this.baseString == "") {
			return true;
		}
		var formula = sequences_condition_ConditionUtils.replaceVariables(element,this.baseString);
		formula = sequences_condition_ConditionUtils.replaceLogicalOperators(formula);
		return eval(formula);
	}
};
var sequences_utils_ElementColumns = function() {
	this.VisibleIf = "VisibleIf";
	this.Actions = "Actions";
	this.Type = "Type";
	this.Goto = "Goto";
	this.SequenceId = "SequenceID";
	this.id = "ID";
};
sequences_utils_ElementColumns.__name__ = true;
sequences_utils_ElementColumns.prototype = {
	clone: function() {
		var cl = new sequences_utils_ElementColumns();
		cl.id = this.id;
		cl.SequenceId = this.SequenceId;
		cl.Goto = this.Goto;
		cl.Type = this.Type;
		cl.Actions = this.Actions;
		cl.VisibleIf = this.VisibleIf;
		return cl;
	}
};
function $getIterator(o) { if( o instanceof Array ) return new haxe_iterators_ArrayIterator(o); else return o.iterator(); }
if(typeof(performance) != "undefined" ? typeof(performance.now) == "function" : false) {
	HxOverrides.now = performance.now.bind(performance);
}
String.__name__ = true;
Array.__name__ = true;
js_Boot.__toStr = ({ }).toString;
sequences_ElementTypes.ChoiceTitle = "choice_title";
sequences_ElementTypes.ChoiceAnswer = "choice_answer";
sequences_Variables.internalPrefix = "@";
sequences_Variables.externalPrefix = "?";
sequences_SequencesManager.version = "2.2.5";
sequences_SequencesManager.instance = new sequences_SequencesManager();
sequences_condition_ConditionUtils.defaultVariableDelimiter = "#";
})(typeof exports != "undefined" ? exports : typeof window != "undefined" ? window : typeof self != "undefined" ? self : this, typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this);// eslint-disable-line
