import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import AppState from '../../../js/AppState';
import Axes from '../../../js/Axes';
import FtueManager from '../../../js/FtueManager';
import Places from '../../../js/Places';
import Projects from '../../../js/Projects';
import ScaleManager from '../../../js/ScaleManager';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Sounds from '../../../js/Sounds';
import Texts from '../../../js/Texts';
import { EnterButton } from '../../Shared/Shared';
import ProjectCard from '../ProjectCard/ProjectCard';
import './ProjectChoicePopup.css';


const ProjectChoicePopup = () => {

  const step = Places.projectChoicePopupVisible

  // la FTUE à l'ouverture
  useEffect(() => {
    if(step === 1) {
      if(AppState.numPartie <= 1) FtueManager.showSequence("phase1_choiceAxe_firstTime")
      else FtueManager.showSequence("phase1_choiceAxe_secondTime")
    }
  }, []);


  function handleGo() {
    if(step === 1) {
      SGMSClient.analyticsApi.choice("ph1.axes.select", Axes.selected.id)
      Places.unlockSequences(Axes.selected.additionalSequencesUnlocks)
      Places.projectChoicePopupVisible = false

      let index = Axes.selected.index + 1
      let ftue_id = `phase1_axe${index}_intro`
      FtueManager.showSequence(ftue_id)


      Sounds.playMusic("loop3")

      AppState.saveGame()
    }

    if(step === 2) {
      SGMSClient.analyticsApi.choice("ph1.projects.select", Projects.selected.id)
      SGMSClient.analyticsApi.score("ph1.selectedaxes", Axes.selected.id)
      SGMSClient.analyticsApi.score("ph1.selectedproject", Projects.selected.id)


      let a_index = Axes.selected.index + 1

      let p_index = Projects.selected.index + 1
      let ftue_id = `phase1_axe${a_index}_project${p_index}`


      FtueManager.showSequence(ftue_id, () => {
        if(!SGMSClient.currentMeeting) {
          AppState.loadPhase2()
          .then(() => {AppState.saveGame()})
        }
        else {
          AppState.showMeetingOverlay = true
        }

      })

    }

    AppState.meetingSendScores()
  }


  if(step === 2 && !Axes.selected) {
    console.log("il faut select un axe avant !!!")
    // TODO gérer ca autrement ??
    return null

  }

  function handleAxeCardClick(axe, locked) {

    if(locked) {
      FtueManager.showSequence("blockedAxe")
    }else {
      Axes.select(axe.id);
      SGMSClient.analyticsApi.action("ph1.btn.Axe." + axe.id + ".select")

    }
  }

  function handleProjectCardClick(project) {
    Projects.select(project.id);

    SGMSClient.analyticsApi.action("ph1.btn.Project." + project.id + ".select")
  }

  return (
      <div className="ProjectChoicePopup" style={ScaleManager.style}>

        {step === 1 &&
        <div className="cards">
          {Axes.all.map(axe => {

            let locked = AppState.numPartie <= 1 ? axe.locked : false

            // override si on est en meeting, et que l'axe est forcé
            if(SGMSClient.currentMeeting) {
              let { defaultAxe, forceChoice} = SGMSClient.currentMeeting.Config.Config

              locked = false // on unlocke tous les choix pour pas de diff entre les joueurs qui jouent pour la première fois ou pas

              if(forceChoice && defaultAxe !== axe.id) locked = true // sauf si forced, du coup on locke les autre !
            }

            return (
            <ProjectCard
            locked={locked}
            selected={Axes.selected === axe}
            onClick={() => handleAxeCardClick(axe, locked)}
            title1={<h2>{Texts.get("project_card_title")}</h2>}
            title2={<h1>{axe.name}</h1>}
            description={axe.description}
            character={axe.character}
            id={axe.id}
            key={axe.id} />)

          })}
        </div>}

        {step === 2 &&
        <div className="cards">
          {Axes.selected.projects
          .map(projet => {
            let locked = false

            // override si on est en meeting, et que l'axe/projet est forcé, alors on lock les autres!
            if(SGMSClient.currentMeeting) {
              let { defaultProject, forceChoice} = SGMSClient.currentMeeting.Config.Config
              if(forceChoice && defaultProject !== projet.id) locked = true
            }


            return (
            <ProjectCard
            className="project"
            locked={locked}
            selected={projet === Projects.selected}
            onClick={() => handleProjectCardClick(projet)}
            title1={<h3>{Axes.selected.name}</h3>}
            title2={<h2>{projet.name}</h2>}
            description={projet.description}
            character={projet.character}
            id={projet.id}
            key={projet.id} />)
          })}
        </div>}

        <div className="button-next">
          <EnterButton
            onClick={handleGo}
            disabled={(step === 1 && !Axes.selected) || (step === 2 && !Projects.selected)} />
        </div>
      </div>

  );
};
export default observer(ProjectChoicePopup)
