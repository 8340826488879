import { observer } from "mobx-react-lite";
import React from "react";
import AppState, { SCREENS } from "../../js/AppState";
import SGMSClient from "../../js/SGMS/SGMSClient";
import Texts from "../../js/Texts";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoginScreen from "../Menu/LoginScreen/LoginScreen";
import MenuScreen from "../Menu/MenuScreen/MenuScreen";
import HomePhase1 from "../phase1/HomePhase1/HomePhase1";
import HomePhase2 from "../phase2/HomePhase2/HomePhase2";
import Phase3Game from "../phase3/Phase3Game/Phase3Game";
import Wrapup from "../phase4/Wrapup/Wrapup";
import PopupsContainer from "../PopupsContainer/PopupsContainer";
import Map from "../Toolkit/Map/Map";
import QuizScreen from "../Toolkit/QuizScreen/QuizScreen";
import "./App.css";


const App = () => {
  return (
    <div className="App" >

      {AppState.screen === SCREENS.LOADING && <LoadingScreen />}
      {AppState.screen === SCREENS.TOOLKIT_MAP && <Map />}
      {AppState.screen === SCREENS.TOOLKIT_QUIZ && <QuizScreen />}

      {AppState.screen === SCREENS.MENU && <MenuScreen />}
      {AppState.screen === SCREENS.LOGIN && <LoginScreen />}

      {AppState.screen === SCREENS.PHASE1 && <HomePhase1 />}
      {AppState.screen === SCREENS.PHASE2 && <HomePhase2 />}

      {AppState.screen === SCREENS.PHASE3 && <Phase3Game />}

      {AppState.screen === SCREENS.WRAPUP && <Wrapup />}
      <PopupsContainer />

      {AppState.showMeetingOverlay && <div className="meeting-paused">{Texts.get('multiplayerGamePaused')}</div>}

      <div className="mobile-alert" />


      <div className="version">v{process.env.REACT_APP_VERSION} {SGMSClient.isAnonymous && "*"} </div>
      {AppState.debugMode && <div className="debugMode">Debug mode on</div>}
    </div>
  )
}

export default observer(App);
