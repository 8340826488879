import { makeAutoObservable } from "mobx"
import Axes from "./Axes"

class Character {
  constructor(json) {
    Object.assign(this, json)
    makeAutoObservable(this)
  }
  get position() {
    if(Axes.selected) {
      if(Axes.selected.character === this.id) return "right"
    }
    if(this.profile_pic) return "left"
    return ""
  }
  get profile_pic_url() {
    let {root, profile_pics_folder} = window.CONFIG
    if(!this.profile_pic) return null



    return `${root}${profile_pics_folder}${this.profile_pic}`
  }
}


class Characters {
  all = []

  init(json) {
    this.all = json
    .filter(elem => elem.id)
    .map(elem => new Character(elem))
  }

  get ids() { return this.current.map(c => c.id)}


  get(id) { return this.all.find(c => c.id === id) }

}


export default new Characters()