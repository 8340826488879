import Papa from 'papaparse'

const papaconfig = {
  delimiter: ";",	// auto-detect
  newline: "\n",	// auto-detect
  header: true,
  skipEmptyLines: true,
  dynamicTyping: true,
  transformHeader: (h) => h.trim()
}

class DataLoading {
  files = {}

  parseDataFile(content) {
    if(content.infos && content.infos.version === 2) {
      let coltypes = {}
      content.columns.forEach(c => coltypes[c.name] = c.type)
      return content.data.map(obj => {
        for(let k in obj) {
          if(coltypes[k] === "numeric" && obj[k]) obj[k] = parseFloat(obj[k])
        }
        return obj
      })
    } else {
      // v1
      return content.data.map(line => {
        let lineObj = {}
        content.columns.forEach(({title, type}, index) => {
          let value = line[index]
          value = value === "" ? null : value
          if(type === "numeric" && value) {
            value = parseFloat(value)
          }
          lineObj[title] = value
        })
        return lineObj
      })
    }
  }


  getFile(url, name) {

    if(this.files[name] !== undefined) return Promise.resolve({data: this.files[name], name})



    let extension = url.split('.').pop()

    return fetch(url)
    .then(res => {
      switch(extension) {
        case 'csv' :
        case 'txt' :
        case 'svg' :
          return res.text()
        case 'data' :
        case 'json' :
        case 'config' :
          return res.json()
        case 'pdf' :
          return res.arrayBuffer()
      }
    })
    .then(data => {
      if(extension === "data") data = this.parseDataFile(data)
      if(extension === "csv") data = Papa.parse(data, papaconfig).data.filter(line => line.id !== null);

      this.files[name] = data

      return {data, name}
    })
  }

  getAllFiles(genericFiles, root) {

    let promises = []
    for (let name in genericFiles) {
      promises.push( this.getFile(root + genericFiles[name], name) )
    }


    return Promise.all(promises)
  }
}

export default new DataLoading()
