import { observer } from 'mobx-react-lite';
import React from 'react';
import { FaBan, FaCheck, FaExclamation, FaLock, FaPlus, FaQuestion } from 'react-icons/fa';
import Texts from '../../../js/Texts';
import InlineSvg from '../InlineSvg/InlineSvg';

import './SideCategory.css';



const SideCategory = ({onSelect, selected, checked, notif = false, phaseNb, category}) => {

  let cl = "SideCategory"
  cl += selected ? " selected" : ""
  cl += checked ? " completed" : ""
  cl += notif ? " notified" : ""

  return (
    <div onClick={onSelect} className={cl}>

      <div className="SideCategory-linebubble">
        {phaseNb===3 &&
        <>
          <div className="line" />
          <div className={"bubble" + (checked ? " checked" : "" )}>
            {checked && !notif && <FaCheck />}
            {notif && <FaExclamation />}
          </div>
        </>}

        {phaseNb===2 &&
        <div className="icon">
          <InlineSvg url={category.iconUrl} />
        </div>}
      </div>

      <div className="SideCategory-content">
        <div className="SideCategory-title">
          {phaseNb===3 && <div className="icon"><InlineSvg url={category.iconUrl} /></div>}
          <h3># {category.short_name}</h3>
        </div>

        {phaseNb===3 && category.min_card_quantity > 0 &&
        <div className="cards-left">{Texts.get("cards-left")} {category.min_card_quantity} {checked && <FaCheck />}</div>}

        <div className="cards-minimap">

          {phaseNb === 2 && category.playableCards.map(c => {
            if(c.ph2_picked) return <span key={c.id} className="minimap-check" ><FaCheck /></span>
            return <span key={c.id} className="minimap-plus" ><FaQuestion /></span>
          })}
          {phaseNb === 3 && category.playableCards.map(c => {
            if(c.picked) return <span key={c.id} className="minimap-check" ><FaCheck /></span>
            if(c.locked) return <span key={c.id} className="minimap-lock" ><FaLock /></span>
            if(c.tooComplex) return <span key={c.id} className="minimap-ban" ><FaBan /></span>
            return <span key={c.id} className="minimap-plus" ><FaPlus /></span>
          })}
        </div>
      </div>


    </div>
  );
};

export default  observer(SideCategory)