import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AppState, { SCREENS } from "../../../js/AppState";
import Axes from "../../../js/Axes";
import Categories from "../../../js/Categories";
import FtueManager from "../../../js/FtueManager";
import Projects from "../../../js/Projects";
import SGMSClient from "../../../js/SGMS/SGMSClient";
import Sounds from "../../../js/Sounds";
import DebugZone from "../../DebugZone/DebugZone";
import BurgerMenu from "../../Shared/BurgerMenu/BurgerMenu";
import InlineSvg from "../../Shared/InlineSvg/InlineSvg";
import SideCategory from "../../Shared/SideCategory/SideCategory";
import SidePanel from "../../Shared/SidePanel/SidePanel";
import CardsColumn from "../CardsColumn/CardsColumn";
import Messages from "../Messages/Messages";
import "./HomePhase2.css";

const HomePhase2 = () => {

  let showColLeft = !( Categories.selected && Categories.selected.notified )

  let threads = [...Projects.selected.ph2_categories, ...Projects.transverse_categories]

  useEffect(() => {
    AppState.saveGame()
    FtueManager.showSequence("phase2_intro")
  }, [Axes.selected.id])

  useEffect(() => {
    if(!showColLeft) return
    // passage à l'axe 3 après avoir vu la ftue


    if(Projects.selected.ph2NotBoughtCards.length === 0) {

      let id = `phase2_${Axes.selected.id}_${Projects.selected.id}_outro`
      FtueManager.showSequence(id, () => {
        if(!SGMSClient.currentMeeting) {
          AppState.loadPhase3()
        }
        else {
          AppState.showMeetingOverlay = true
        }
      })
    }

    if(Categories.notifiedQty > 0) {
      Sounds.play("notif_thread")
    }

  },[showColLeft])


  return (
    <div className={"phase2"  + (showColLeft ? " col-left-visible" : "")}>


    <SidePanel top={<h2>{Projects.selected.name}</h2>} visible={showColLeft}>
      {threads
      .filter(categ => categ.ph2_unlocked)
      .map(c =>
      <SideCategory
      phaseNb={2}
      selected={c.id === Categories.selected?.id}
      checked={c.receivedCards.length === c.playableCards.length} // todo
      onSelect={() => Categories.ph2_select(c)}
      notif={c.notified}
      category={c}
      key={c.id} />)}
    </SidePanel>



    <div className="col2">
      <h3>
        {Categories.selected && <>
          <span className="icon"><InlineSvg url={Categories.selected.iconUrl} /></span>
          <span className="short-title"># {Categories.selected.short_name}</span>
          <span className="long-title">{Categories.selected.name}</span>
        </>}
      </h3>


      <div className="col2-bottom">
        <Messages />
        <CardsColumn />
      </div>
    </div>

    <BurgerMenu />


    <DebugZone>
      <button onClick={() => AppState.saveGame(true)}>SET SAVE PARTEI2</button>
      <button onClick={() => AppState.saveGame()}>SAVE GAME</button>
      <button onClick={() => AppState.setScreen(SCREENS.PHASE3)}>Phase 3</button>
    </DebugZone>
  </div>
  )
}

export default observer(HomePhase2);
