import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import Cards from "../../../js/Cards";
import Categories from "../../../js/Categories";
import InlineSvg from "../InlineSvg/InlineSvg";
import { IconButton } from "../Shared";
import './Card.css';
import PickButton from "./PickButton/PickButton";

import {FaChevronLeft} from 'react-icons/fa'
import Texts from "../../../js/Texts";
import AppState from "../../../js/AppState";
import CriteriaIcon from "../CriteriaIcon/CriteriaIcon";

const UnlockTipRow = ({card_id}) => {

  let card = Cards.get(card_id)
  let card_categ = Categories.get(card.category)

  if(!card_categ) return null // pour la categ transverse qui va lister des cartes de l'autre projet : categ==null


  return (
  <div className="UnlockTipRow">
    <div className="UnlockTipRow-icon">
      <InlineSvg url={card_categ.iconUrl} />
    </div>
    {card.title}
  </div>
  )

}

const Card = ({card, showPickButton = true, showDesc=true}) => {
  let card_categ = Categories.get(card.category)

  const [showLockingReasons, setShowLockingReasons] = useState(false);

  useEffect(() => {
    if(!card.locked) setShowLockingReasons(false)
  }, [card.locked]);

  let lr = card.locked_reasons

  let cl = "Card"
  if(showPickButton) {
    cl += card.picked ? " picked" : ""
    cl += card.tooComplex ? " tooComplex" : ""
    cl += card.locked ? " locked" : ""
  }



  return (
  <div className={cl} title={card.id}>

    {showLockingReasons && lr &&
    <div className="locking-reasons" onClick={() => setShowLockingReasons(false)}>
      <h2>{Texts.get("card_unlock_tips_title")}</h2>
      <div className="unlocks-tips-list">
        {lr.needs.length > 0 &&
        <div className="card-needs">
          {Texts.get("card_unlock_tips_to_activate")}
          <div>
            {lr.needs.map(c_id => <UnlockTipRow card_id={c_id} key={c_id} /> )}
          </div>
        </div>}
        {lr.locks.length > 0 &&
        <div className="card-needs">
          {Texts.get("card_unlock_tips_to_deactivate")}
          <div>
            {lr.locks.map(c_id => <UnlockTipRow card_id={c_id} key={c_id} /> )}
          </div>
        </div>}
      </div>

      <IconButton className="back-btn"><FaChevronLeft /></IconButton>
    </div>}

    <div className="card-header">


      <div className="card-icon">
        {card_categ && <InlineSvg url={card_categ.iconUrl} />}
      </div>

      <div className="card-header-criterias">
        {card.criterias
        .filter(c => c.value !== 0)
        .map(c => <CriteriaIcon id={c.id} clickable selected={true} size={40} />)}
      </div>
    </div>

    <div clamp={3} className="card-title">{card.title}</div>

    {showDesc && <div clamp={5} className="card-desc">{card.description}</div>}

    <div className="card-bottom">
      {showPickButton && <PickButton card={card} onLockedClick={() => setShowLockingReasons(true)} />}

      {showDesc && card.complexity &&
      <div className="card-complexity">
        <span>+{card.complexity}</span>
        </div>}
    </div>
  </div>)
}



export default observer(Card)
