import LEP from '@jeanbenitez/logical-expression-parser'
import AppState from './AppState'
import Cards from "./Cards"
import Categories from "./Categories"


class SubProject {
  cards= []
  success_text = ""
  failure_text = ""
  constructor(data) {
    this.cards = data.subproject_cards_filter.split(';')
    this.success_text = data.subproject_final_success
    this.failure_text = data.subproject_final_failure
    this.newspaper_image = data.newspaper_image
  }

  isValid() {
    // on intersecte le tableau des cartes ici, et le tableau des cartes achetées
    const intersection = Cards.boughtCardsIds.filter(cId => this.cards.includes(cId))
    return intersection.length > 0 // au moins une carte de select
  }

}

class Project {
  subprojects = []
  index = null
  constructor(json, index) {
    Object.assign(this, json)

    this.index = index

    // + propre
    delete this.subproject_cards_filter
    delete this.subproject_final_success
    delete this.subproject_final_failure
  }

  addSubproject(subproj) {
    this.subprojects.push(new SubProject(subproj))
  }

  getSubproject() {
    if(AppState.debugMode) return this.subprojects[0]
    return this.subprojects.find(sp => sp.isValid())
  }

  get categories() { return Categories.all.filter(c => c.project === this.id || c.project === null) }

  get ph2_categories() { return Categories.all.filter(c => c.project === this.id) }


  get allCategsValid() { return this.categories.every(c => c.cardsLeft === 0) }

  get cards() { return this.categories.reduce( (acc, categ) => { return [...acc, ...categ.cards] }, [])}
  get playableCards() { return this.categories.reduce( (acc, categ) => { return [...acc, ...categ.playableCards] }, [])}



  get ph2NotBoughtCards() { return this.playableCards.filter(c => !c.ph2_picked)}
  get notBoughtCards() { return this.playableCards.filter(c => !c.picked)}


  get currentComplexity() {
    return Cards.boughtCards.reduce((comp, card) => comp + card.complexity , 0)
  }

  pickComboCards() {
    Cards.comboCards.forEach(c => {
      let reqs = c.comboRefIds.join(" AND ")
      let toPick = LEP.parse(reqs, p => Cards.boughtCardsIds.includes(p))
      if(toPick) c.pick()
    })
  }

  pickOpposites() {
    // comparons les cartes NON achetées (de la ph3) de CE projet, avec les OPPOSITES cards
    this.notBoughtCards.forEach(notBoughtCard => {
      if(notBoughtCard.locked) return

      Cards.oppositeCards.forEach(oppositeCard => {
        if(notBoughtCard.id === oppositeCard.id) {
          oppositeCard.pick()
        }
      })
    })
  }



}

export default Project