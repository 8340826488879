import React from 'react'

import Projects from '../../../js/Projects';
import Criteria from '../Criteria/Criteria';

import './Newspaper.css'
import title from '../../../images/journal/titre.png'
import { observer } from 'mobx-react-lite';
import BalanceText from 'react-balance-text'

const Newspaper = ({victory}) => {

  let subproject = Projects.selected.getSubproject()

  let victoryText = victory ? subproject.success_text : subproject.failure_text



  return (
    <div className="Newspaper" >
      <div className="Newspaper-sheets" />


      <div className="Newspaper-title">
      <div className="Newspaper-title-stripes" />
        <img src={title} alt="" />
      <div className="Newspaper-title-stripes" />
      </div>

      <div className="Newspaper-content">
        <div className="Newspaper-left">
          <h1><BalanceText>{victoryText}</BalanceText></h1>
          <div className="Newspaper-castle-picture">
            <img src={"./data/images/ph4_newspaper/" + subproject.newspaper_image} alt="" />
          </div>
        </div>
        <div className="Newspaper-right">
          <Criteria id="participation" />
          <Criteria id="inclusion" />
          <Criteria id="sustainability" />
          <Criteria id="quality" />
        </div>
      </div>
    </div>
  );
};

export default observer(Newspaper);
