import React, { useState } from 'react';
import logo from "../../../images/menu/Logo_game.svg";
import AppState, { SCREENS } from '../../../js/AppState';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Texts from '../../../js/Texts';
import BurgerMenu from '../../Shared/BurgerMenu/BurgerMenu';
import Button from '../../Shared/Button/Button';
import MenuBottom from '../MenuBottom/MenuBottom';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import './LoginScreen.css';
import RegisterForm from './RegisterForm';

const FORMS = {
  LOGIN: 0,
  REGISTER: 1,
  FORGOT_PWD: 2
}

const LoginScreen = () => {

  const [form, setForm] = useState(FORMS.LOGIN);

  const [error, setError] = useState(null);

  function changeForm(e, newform) {
    e.preventDefault()
    setForm(newform)
  }

  function loginTier(provider) {
    SGMSClient.userDisconnect() // déco de l'anonymous
    .then(() => AppState.openid.startCodeFlow(window.CONFIG.keycloak.redirectUri, provider))
    .then(data => {
      const {Universe, App} = window.CONFIG.server
      return SGMSClient.loginNonEncrypted(Universe, App, data.access_token)
    })
    .then(() => AppState.setScreen(SCREENS.MENU))
    .then(() => AppState.checkProfileSavegame())
    .catch(err => {
      setError("An error happened")
      console.log("error", err)
    })
  }

  function handleForgotPwd(e) {
    e.preventDefault()
    setForm(FORMS.FORGOT_PWD)
  }


  return (
    <div className="screen LoginScreen">
      <BurgerMenu />
      <div className="login-top">
        <img src={logo} alt="game logo" />

      </div>

      <div className="login-center">
        {form !== FORMS.FORGOT_PWD &&
        <>
          <div className="social-btns">
            <Button onClick={() => loginTier("google")} pill className="google-btn">{Texts.get("menu_login_google")}</Button>
            {/* <Button onClick={() => loginTier("facebook")} pill className="facebook-btn">{Texts.get("menu_login_facebook")}</Button> */}


            {error && <div className="LoginForm-error">{error}</div>}
          </div>
          <div className="separator">{Texts.get("menu_or")}</div>
        </>}

        {form === FORMS.LOGIN && <LoginForm />}
        {form === FORMS.REGISTER && <RegisterForm onDone={() => setForm(FORMS.LOGIN)} />}
        {form === FORMS.FORGOT_PWD && <ForgotPasswordForm onDone={() => setForm(FORMS.LOGIN)}/>}

        {form === FORMS.LOGIN &&
        <div className="login-after-form">
          <a href="#" onClick={e => changeForm(e, FORMS.REGISTER)}>{Texts.get("menu_create_account")}</a>
          <a href="#" onClick={handleForgotPwd}>{Texts.get("menu_forgot_password")}</a>
        </div>}

        {form !== FORMS.LOGIN &&
        <div className="login-after-form">
          <a href="#" onClick={e => changeForm(e, FORMS.LOGIN)}>{Texts.get("menu_back")}</a>
        </div>}

      </div>

      <MenuBottom />


    </div>
  );
};

export default LoginScreen;
