import { makeAutoObservable, toJS } from "mobx"

class ScaleManager {
  scale = 1
  _style = {} // ne pas utiliser direct, il faut le toJS()

  get style() { return toJS(this._style) }

  constructor() {
    makeAutoObservable(this)
  }

  init(w, h) {
    this.recalculate(w, h)
    window.addEventListener("resize", () => this.recalculate(w, h))
  }


  recalculate(w, h){


    let ratio = w/h
    let windowRatio = window.innerWidth / window.innerHeight

    let style = {
      transformOrigin: "top left",
      width: w,
      height: h
    }
    let scale = 1

    if(windowRatio > ratio) {
      let w = window.innerHeight * ratio
      scale = window.innerHeight / h
      let margin = (window.innerWidth - w) / 2
      style.marginLeft = margin

    } else {
      let h = window.innerWidth / ratio
      scale = window.innerWidth / w
      let margin = (window.innerHeight - h) / 2
      style.marginTop = margin
    }


    style.transform = "scale("+scale+")"


    this._style = style
    this.scale = scale

  }
}


export default new ScaleManager()