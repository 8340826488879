// permet de récupérer sans erreur une propriété imbriquée d'un objet qui n'existe peut-être pas
export const safeGetProp = p => o => p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o)

export const formatTime = (s) => {
  let min = Math.floor(s / 60)
  if(min) s -= min * 60

  return min + ":" + Math.trunc(s).toString().padStart(2, "0")
}


// transforme un objet key:value en une string jolie
export function objectToHtmlString(obj) {

  let str = "<div>"
  for(let k in obj) {
    str += `<div>${k}: ${obj[k]}</div>`
  }
  str += "</div>"

  return str

}