import marked from 'marked';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import quiz_title from "../../../images/Quizz_text.svg";
import AppState, { SCREENS } from '../../../js/AppState';
import SequencesManager from '../../../js/SequencesManager/SequencesManager';
import BurgerMenu from '../../Shared/BurgerMenu/BurgerMenu';
import Button from '../../Shared/Button/Button';
import { EnterButton } from '../../Shared/Shared';
import ImagePopup from './ImagePopup/ImagePopup';
import './QuizScreen.css';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Texts from '../../../js/Texts';


const {root, toolkit_quiz_images_folder} = window.CONFIG

const Answer = ({a, showCorrect, selectedAnswer, onClick}) => {
  let cl = "choice-text"
  if(showCorrect) {
    cl += " inactive"
    if(a.raw.correct_answer) cl += " correct"
    if(selectedAnswer === a.id && !a.raw.correct_answer) cl += " wrong"
  }
  return (
  <Button onClick={onClick} className={cl} >
    {a.raw.Text}
  </Button >)

}

/*
le principe
c'est le moment de la question : les datas sont mises dans "currentDial"
puis on répond : la question est mise dans "question", le feedback dans "currentDial"
*/


const QuizScreen = () => {

  const feedbackRef = useRef(null)
  const answersRef = useRef(null)
  const questionsCountRef = useRef(null)

  const [question, setQuestion] = useState(null);
  const [showCorrect, setShowCorrect] = useState(false);
  const [score, setScore] = useState(0);
  const [questionCount, setQuestionCount] = useState(1);
  const [currentDial, setCurrentDial] = useState(null);
  const [finished, setFinished] = useState(false);

  const [fullscreenImage, setFullscreenImage] = useState(null)

  // NOTE(g) useMemo pour ne calculer ce ratio que quand la valeur de finished change.
  // C'est plus pour le test car ca ne consomme pas tant que ça, cette division
  const ratio = useMemo(() => {
    return score / questionsCountRef.current
  }, [finished])

  useEffect(() => {
    SequencesManager.playFile("quiz")
    questionsCountRef.current = countQuestions(SequencesManager.currentSequence.getCurrentFile().elements)

    setCurrentDial(SequencesManager.getCurrentElement())
  }, []);


  useEffect(() => {
    if(question) {
      scrollToFeedback()
      SGMSClient.analyticsApi.contentShow("quizz.question" + questionCount + ".feedback")
    }
  }, [question]);

  function countQuestions(data) {
    return data.filter(row => row.Type === "choice_title").length
  }

  function parseMarkdown(t) {
    let opts = { linkTargetBlank: true, noParagraph: true}
    const renderer = new marked.Renderer();

    if(opts.linkTargetBlank) {
      const linkRenderer = renderer.link;
      renderer.link = (href, title, text) => linkRenderer.call(renderer, href, title, text).replace(/^<a /, '<a target="_blank" rel="nofollow" ')
    }
    if(opts.noParagraph) { renderer.paragraph = text => text + '\n' }
    return marked(t, {renderer})
  }

  function _next() {
    if(SequencesManager.hasNext()) {
      if(currentDial.Type ===  "choice_title") {
        setQuestion(currentDial)
      } else {
        setQuestion(null)
        setShowCorrect(false)
      }
      let next = SequencesManager.next()
      setCurrentDial(next)
    } else {
      setCurrentDial(null)
      setQuestion(null)
      setShowCorrect(false)
      setFinished(true)

    }
  }

  function handleNextClick() {
    SGMSClient.analyticsApi.contentHide("quizz.question" + questionCount + ".feedback")
    setQuestionCount(qc=>qc+1)



    if(finished) {
      SGMSClient.analyticsApi.score("quizz.score", score)
      AppState.setScreen(SCREENS.MENU)
      return
    }

    if(currentDial.Type === "choice_title") return
    _next()
  }

  function handleChoiceClick(a) {
    SGMSClient.analyticsApi.action("quizz.btn.answer" + a.id + ".select")
    SGMSClient.analyticsApi.choice("quizz.question" + questionCount, a.id)
    let resultInt = (a.raw.correct_answer? 1 : 0)
    SGMSClient.analyticsApi.score("quizz.question" + questionCount, resultInt)

    if(currentDial.userAnswers && currentDial.userAnswers.length > 0) return
    currentDial.addAnswer(a.id)
    if(a.raw.correct_answer) setScore(s => s+1)

    setShowCorrect(true)
    answersRef.current.scrollIntoView({ behavior: 'smooth' })
    setTimeout(_next, AppState.debugMode ? 1 : 1000)
  }

  function getImagesPaths(images) {
    if(!images) return []
    return images.split(";").map(image_name => {
      return root + toolkit_quiz_images_folder + image_name
    })
  }

  function scrollToFeedback() {
    if(question) feedbackRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  function setFullScreen(a) {
        setFullscreenImage(a);
  }


  return (
    <div className="QuizScreen" >
      <div className="quiz-col-1">
        <img className="quiz-title" src={quiz_title} />
      </div>
      <div className="quiz-col-2">

        <div className={"quiz-ctnr" + (finished ? " finished" : "")}>
          {!finished && <div className="QuizScreen-top">

            <div className="score">
              {Texts.get("quiz_score")} <span>{score}</span> / {questionsCountRef.current}
            </div>

          </div>}


          {/* affiche la question "répondue" quand le feedback est affiché aussi */}
          {question &&
          <div className="question">
            <div className="question-text">
              <div className="question-images">
                {getImagesPaths(question.raw.images)
                .map(img => <img key={img} src={img} alt="" onClick={() => setFullScreen(img)}/>)}
              </div>

              <div className="text" dangerouslySetInnerHTML={{__html: parseMarkdown(question.raw.Text)}} />
            </div>

            {question.Type === "choice_title" &&
            <div className="question-choices">
              {question.getAllChoices().map(a =>
              <Answer key={a.id} a={a} selectedAnswer={question.getAnswerId()} showCorrect={true} />)}
            </div>}
          </div>}


          {currentDial &&
          <div className="question" >
            <div className={"question-text" + (question ? " feedback" : "")} ref={feedbackRef}>
              <div className="question-images">
                {getImagesPaths(currentDial.raw.images)
                .map(img => <img onLoad={scrollToFeedback} key={img} src={img} alt="" onClick={() => setFullScreen(img)} />)}
              </div>
              <div className="text" dangerouslySetInnerHTML={{__html: parseMarkdown(currentDial.raw.Text)}} />
            </div>
            {currentDial.Type === "choice_title" &&
            <div className="question-choices" ref={answersRef}>
              {currentDial.getAllChoices().map(a =>
              <Answer key={a.id} a={a} showCorrect={showCorrect} selectedAnswer={showCorrect && currentDial.getAnswerId()} onClick={() => handleChoiceClick(a)} />)}
            </div>}
          </div>}

          {finished &&
          <div className="QuizScreen-end">
            <div className="score">
              <span>{score}</span>/{questionsCountRef.current}
            </div>
          </div>}


          {(question || finished) &&
          <div className="QuizScreen-bottom">
            <div className="button-next">
              <EnterButton onClick={handleNextClick} />
            </div>
          </div>}


        </div>
      </div>


      <div className="quiz-col-3"></div>

      {fullscreenImage &&
      <ImagePopup img={fullscreenImage} onClose={() => setFullScreen(null)} />}

      <BurgerMenu />



  </div>);
};

export default QuizScreen;
