import React from 'react';
import './Input.css';


const Input = ({id, label, defaultValue = null, type="text"}) => {
  return (
    <>
      {label && <label className="Input-label" htmlFor={id}>{label}</label>}
      <input className="Input" defaultValue={defaultValue}  name={id} id={id} type={type} />
    </>

  );
};

export default Input;
