import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import AppState from '../../../js/AppState';
import Axes from '../../../js/Axes';
import Cards from '../../../js/Cards';
import Categories from '../../../js/Categories';
import FtueManager from '../../../js/FtueManager';
import Projects from '../../../js/Projects';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Texts from '../../../js/Texts';
import { objectToHtmlString } from '../../../js/utils';
import BurgerMenu from '../../Shared/BurgerMenu/BurgerMenu';
import SideCategory from '../../Shared/SideCategory/SideCategory';
import SidePanel from '../../Shared/SidePanel/SidePanel';
import Category from '../Category/Category';
import Header from './Header/Header';
import './Phase3Game.css';



const Phase3Game = () => {
  useEffect(() => {
    AppState.saveGame()
    if(AppState.numPartie === 1) FtueManager.showSequence("ph3-intro-seq")

  }, [])

  const submitDisabled = !AppState.debugMode && !Projects.selected.allCategsValid

  useEffect(() => {
    if(!submitDisabled) {
      FtueManager.showSequence("ph3_submit_unlocked")
    }


  }, [submitDisabled]);


  function handleSubmit(){
    if(submitDisabled) {
      FtueManager.showSequence("ph3_submit_locked")
    }else {
      let ftue_id = Axes.selected.index === 2 ? "phase3_axe3_outro": "phase3_axe1and2_outro"
      FtueManager.showSequence(ftue_id, () => {

        Projects.submit()

        if(SGMSClient.currentMeeting) { AppState.showMeetingOverlay = true }
        else { AppState.loadPhase4() }



      })
    }
  }


  useEffect(() => {
    let score = {
      "Complexity": Projects.selected.currentComplexity,
      "Chosen cards quantity": Cards.boughtCards.length
    }




    AppState.meetingSendScores(objectToHtmlString(score))

  }, [Projects.selected.currentComplexity]);



  return (
    <div className="Phase3Game">
      <SidePanel top={<h2>{Projects.selected.name}</h2>} >
        {Projects.selected.categories.map(c =>
        <SideCategory
        phaseNb={3}
        category={c}
        selected={c === Categories.ph3_selected}
        checked={c.cardsLeft >= 0}
        onSelect={() => Categories.ph3_select(c, true)}

        key={c.id} />)}

        <div className={"submit-button" + (submitDisabled ? " disabled" : "")}>
          <div className="submit-icon"><FaPaperPlane /></div>
          <button
          onClick={handleSubmit}
          >{Texts.get("ph3_submit_file")}</button>
        </div>
      </SidePanel>


      <div className="col-right">
        <Header />
        <div className="categories">
          {Projects.selected
          .categories.map(categ => <Category categ={categ} key={categ.id} />)}
        </div>
      </div>

      <BurgerMenu />
    </div>
  );
};

export default  observer(Phase3Game)
