import React, { useState, useEffect } from 'react'
import Button from '../../Shared/Button/Button';

import './ChooseSavegamePopup.css'
import dateformat from 'dateformat'
import SGMSClient from '../../../js/SGMS/SGMSClient';
import AppState from '../../../js/AppState';
import Texts from '../../../js/Texts';


const ChooseSavegamePopup = () => {

  function formatDate(ts) {
    let d = new Date(ts)

    return dateformat(d, Texts.get("menu_choose_save_date_format"))
  }


  function handleLocaleClick() {
    // c'est déja la locale, donc on ferme juste la popup
    AppState.menuShowSavegamePopup = false
  }

  function handleProfileClick() {
    // c'est déja la locale, donc on ferme juste la popup
    AppState.savedGame = SGMSClient.userCustomDatas
    AppState.menuShowSavegamePopup = false
  }



  return (

    <div className="ChooseSavegamePopup-overlay">
      <div className="ChooseSavegamePopup">

        <div className="ChooseSavegamePopup-text">
          {Texts.get("menu_choose_save")}
        </div>
        <div className="ChooseSavegamePopup-buttons">
          <div className="btn-ctnr">
            <Button pill onClick={handleProfileClick}>{Texts.get("menu_choose_save_profile")} </Button>
            <span>({formatDate(SGMSClient.userCustomDatas.date)})</span>
          </div>
          <div className="btn-ctnr">
          <Button pill onClick={handleLocaleClick}>{Texts.get("menu_choose_save_locale")}</Button>
            <span>({formatDate(AppState.savedGame.date)})</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseSavegamePopup;
