import React, { useState, useEffect } from 'react'
import logo_succubus from "../../../images/menu/Logo_succubus.svg";
import Texts from '../../../js/Texts';
import TextPopup from '../TextPopup/TextPopup';
import './MenuBottom.css'

import logo_europe from '../../../images/menu/logo_europe.png'

const MenuBottom = () => {
  const [gdprPopupVisible, setGdprPopupVisible] = useState(false);
  const [creditsPopupVisible, setCreditsPopupVisible] = useState(false);
  function openGDPRPopup(e) {
    e.preventDefault()
    setGdprPopupVisible(true)
  }

  function openCreditsPopup(e) {
    e.preventDefault()
    setCreditsPopupVisible(true)
  }

  return (
    <>
    <div className="menu-bottom">
      <div className="menu-bottom-left">
        <img src={logo_succubus} alt="logo succubus" /> <span dangerouslySetInnerHTML={{__html: Texts.get('succubus_baseline')}} />
      </div>
      <div className="menu-bottom-center">
         <img src={logo_europe} alt="logo_europe" />
      </div>

      <div className="menu-bottom-right">

        <a onClick={openCreditsPopup} href="#">{Texts.get("menu_bottom_credits")}</a>
        <a onClick={openGDPRPopup} href="#">{Texts.get("menu_bottom_gdpr")}</a>
      </div>
    </div>

    {gdprPopupVisible &&
    <TextPopup title={Texts.get("GDPR_title")} content={Texts.get("GDPR_content")} onClose={() => setGdprPopupVisible(false)} />}

    {creditsPopupVisible &&
    <TextPopup title={Texts.get("credits_title")} content={Texts.get("credits_content")} onClose={() => setCreditsPopupVisible(false)} />}

    </>
  );
};

export default MenuBottom;
