import React, { useEffect, useRef } from 'react';
import Characters from '../../../js/Characters';
import Boussole from '../../Shared/Boussole/Boussole';
import Places from '../../../js/Places';
import './DialFocus.css';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Criterias from '../../../js/Criterias';
import AppState from '../../../js/AppState';

import BalanceText from 'react-balance-text'


const DialFocus = ({currentText, message, showChoices, onClick}) => {
  let char = Characters.get(message.raw.Character)
  const isCorrespondant = char.id === Places.selected.correspondant


  function handleClick() {
    SGMSClient.analyticsApi.action("ph1.message." + Places.selected.id + "_" + message.id + ".select")
    onClick()
  }

  function handleChoiceClick(e, a) {
    e.stopPropagation()

    if(message.userAnswers.length !== 0) return

    SGMSClient.analyticsApi.action("ph1.answer." + Places.selected.id + "_" + a.id + ".select")
    SGMSClient.analyticsApi.choice("ph1.dialog.answer", Places.selected.id + "_" + a.id)

    message.addAnswer(a.id)
    onClick(a)
  }

  function handleBoussoleClick(e) {
    e.stopPropagation()
    AppState.showBoussolePopup = true
  }

  let selectedCriterias = Criterias.ids.filter(id => message.raw[id])

  let className = "DialFocus"
  className += showChoices ? " show-choices" : ""
  className += Criterias.firstVisible  ? " boussole-visible" : ""
  className += isCorrespondant  ? " contrechamp" : " champ"

  return (
    <div className={className} onClick={handleClick}>

      <div className="DialFocus-dial" >
        {char && <div className="DialFocus-name" >{char.name}</div>}

        <div className="DialFocus-text"  >
          <BalanceText>{currentText}</BalanceText>

          {showChoices &&
          <div className="DialFocus-choices">
            {message.getAllChoices().map(a => {
              let answered = message.userAnswers.indexOf(a.id) !== -1
              let disabled = message.userAnswers.length !== 0

              return (
              <div key={a.id}
              className={"DialFocus-choice" + (answered ? " answered" : "")}
              disabled={disabled}
              onClick={(e) => handleChoiceClick(e, a)}>
                <BalanceText>{a.raw.Text}</BalanceText>
              </div>)
            })}
          </div>}
        </div>

      </div>

      {Criterias.firstVisible &&
      <div className="DialFocus-boussole" onClick={handleBoussoleClick} >
        <Boussole selectedIds={selectedCriterias} size={60} />
      </div>}

    </div>
  );
};

export default DialFocus;
