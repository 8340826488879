import { observer } from 'mobx-react-lite';
import React from 'react';
import Categories from '../../../js/Categories';
import Card from '../../Shared/Card/Card';
import CardPh2 from './CardPh2/CardPh2';
import './CardsColumn.css';


const CardsColumn = () => {

  let empties = []
  let slotsNb = Categories.selected ? Categories.selected.playableCards.length - Categories.selected.receivedCards.length : 3


  for(let i = 0; i < slotsNb; i++) {
    empties.push()
  }

  let cards = []
  if(Categories.selected) {
    cards = Categories.selected.playableCards.sort((a, b) => {
      if(a.ph2_picked && !b.ph2_picked) return -1
      if(!a.ph2_picked && b.ph2_picked) return 1
      return 0

    })

  }
  return (
    <div className="CardsColumn">
      <div className="CardsColumn-content">
        {Categories.selected &&
        Categories.selected.playableCards
        .map(c => {
          if(!c.ph2_picked) return <CardPh2 card={c} forceEmpty  key={c.id} />
          return <Card card={c} key={c.id} showPickButton={false} showDesc={false} />
        })}

      </div>
    </div>
  );
};

export default observer(CardsColumn)
