import { observer } from 'mobx-react-lite';
import React from 'react';
import Criterias from "../../../js/Criterias";
import CriteriaIcon from '../../Shared/CriteriaIcon/CriteriaIcon';
import './Criteria.css';



const Criteria = ({id}) => {
  let C = Criterias.get(id)

  if(!C) return null

  return (
  <div className={"Criteria " + C.state}>

    <div className="Criteria-col1">
      <div className="val">{C.value}</div>
    </div>

    <div className="Criteria-col2">
      <strong>{C.name}</strong>
      <div className="criteria-debrief" dangerouslySetInnerHTML={{__html: C.debrief}} />
    </div>

    <div className="Criteria-col3">
      <CriteriaIcon id={id} size={30} clickable />
    </div>


  </div>)
}


export default observer(Criteria)
