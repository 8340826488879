
/**
 * Il y a deux manières de se connecter à keycloak
 *
 * 1) via les interfaces de keycloak. Pour se faire, il faut ouvrir un lien (authorization endpoint {server_keycloak}/protocol/openid-connect/auth) qui présentera les pages de login à l'utilisateur.
 * Le serveur redonnera les creds à l'application via la uri de redirection (@see createAuthUrl).
 * On est obliger de procéder de cette manière pour les identity providers tierce (google, facebook)
 *
 * 2) via l'api de keycloak, on envoie une requete sur le token endpoint avec l'id et le pass de l'user (@see login)
 *
 *
 * useful links:
 * openid specs: https://openid.net/specs/openid-connect-core-1_0.html#AuthorizationEndpoint
 * documentation (avec exemple) de l'api keycloak: https://documenter.getpostman.com/view/7294517/SzmfZHnd
 * code de l'adapteur de keycloak js: https://github.com/keycloak/keycloak/tree/master/adapters/oidc/js/src/main/resources
 * index de l'implementation openid de keycloak (dépend du realm): https://keycloak-succubus.cleverapps.io/auth/realms/TestThomas/.well-known/openid-configuration
 * doc coda: https://coda.io/d/Succubus-ERP_d9hDyqH77d7/Keycloak_suTZH#_luJa8
 */

import { makeAutoObservable } from "mobx"
import jwt_decode from "jwt-decode";

export default class Openid {

  /**
   * @param {Object} {
   *   @param {String} baseUrl, url de l'indendity provider, exemple: "http://localhost:8080"
   *   @param {String} realm, Nom du realm keycloak, Souvent le nom de l'univers, exemple: "test"
   *   @param {String} clientId, Nom du client keycloak, Souvent le nom de l'univers, mais peut diférer du nom du realm exemple: "test"
   * }
   */
  config = undefined
  /**
   * @param {Object} {
   *   @param {String} id_token, contient certaines informations basique lié à l'utilisateur
   *   @param {String} access_token, sert à se connecter aux apis (SGMS, keycloak)
   *   @param {String} refresh_token, permet d'obtenir un autre access_token si il est expiré
   *   ...
   * }
   */
  tokens = undefined

  constructor(config) {
    this.config = config
    this.retrieveTokens()
    makeAutoObservable(this)
  }

  /**
   * these urls are specific to keycloak, they might change depending on idendity provider (google, okta, ...)
   * but the behavior stay the same only the urls changes
   */
  get realmEndpoint() {
    return `${this.config.baseUrl}/auth/realms/${this.config.realm}`
  }
  get endpoints() {
    return {
      authorization: `${this.realmEndpoint}/protocol/openid-connect/auth`,
      token: `${this.realmEndpoint}/protocol/openid-connect/token`,
      registration: `${this.realmEndpoint}/clients-registrations/openid-connect`,
      userInfo: `${this.realmEndpoint}/protocol/openid-connect/userinfo`,
      userProfile: `${this.realmEndpoint}/account`,
      logout: `${this.realmEndpoint}/protocol/openid-connect/logout`,
      forgotPass: `${this.realmEndpoint}/login-actions/reset-credentials`
    }
  }

  //----------------------  Auth flow  ----------------------//

  /**
   * @param {string} redirectUri
   * Une fois que le serveur aura identifié l'utilisateur, le serveur ouvrira l'uri avec le code ou les tokens (selon la responseType) dans l'url
   * (ex: redirect_uri = http://localhost:3000/login/ le serveur renverra les creds dans l'url http://localhost:3000/login/#code=xxxx&access_token=yyyyy)
   * penser à authoriser la redirectUri depuis le serveur openid
   * @param {string} responseType optional defaults to "code"
   * Le type de credentials que l'on veut recevoir. code, token, id_token. Une combinaison des trois est possible
   * (ex de valeurs possible: 'code', 'token id_token', 'code token id_token'. Toutes les combinaisons sont acceptables)
   * selon le response_type la page qui recevra les creds (redirect_uri) devra traiter les résultats différements. on appelle ça un flow il en existe trois (Authorization Code, Implicit et Hybrid)
   * Pour le Authorisation Code flow, seulement le code est récupérer par redirect_uri (response_type='code')
   * Le code devra être échanger au près du token endpoint contre le token et l'id_token @see codeToToken
   * Pour l'Implicit flow, le token et l'id_token sont récupérer par redirect_uri (response_type='token id_token')
   * il n'y à rien à faire (à part sauvegarder les tokens), Cependant les tokens sont visibles dans l'url et donc dans l'historique de l'utilisateur (spoiler c'est une faille de sécurité, un nonce est envoyé avec la première requete pour limiter les risques)
   * C'est pour cela que le flow conseillé est l'Authorization Code flow. C'est le flow par défaut
   * petite précision (token = access_token) de la doc sur la différence entre access_token et id_token https://auth0.com/docs/tokens
   * @param {string} scope optional defaults to "openid profile email"
   * Claim de base, par défaut 'openid profile email', devrait être amplement suffisant
   * https://auth0.com/docs/scopes/openid-connect-scopes
   * @param {string} idpHint optional, nom de l'idp désiré (google, facebook, ...)
   * permet de skipper la page de connexion de keycloak et de passer directement à la page de connexion de l'idp désiré (ex: google, facebook)
   *
   * there are also other hint that are not implemented, you can just add them to the url
   * - login_hint to prefill username on the form
   * - ...
   */
  createAuthUrl(redirectUri, responseType, scope, idpHint) {
    const dflt = { // default is a reserved word in js
      responseType: "code",
      scope: "openid profile email",
    }

    let url = `${this.endpoints.authorization}?`
    url += "redirect_uri=" + encodeURIComponent(redirectUri)
    url += "&client_id=" + encodeURIComponent(this.config.clientId)
    url += "&scope=" + encodeURIComponent(scope || dflt.scope)
    url += "&response_type=" + encodeURIComponent(responseType || dflt.responseType)
    url += "&prompt=consent"

    if(idpHint) url += "&kc_idp_hint="+ idpHint

    return url
  }

  createForgotPasswordUrl() {
    let url = `${this.endpoints.forgotPass}?`
    url += "client_id=" + encodeURIComponent(this.config.clientId)
    return url;
  }

  /**
   * Will handle Authorization Code Flow login process
   * It opens a popup and display authentification interfaces via auth endpoint.
   * will also exchange code for tokens
   * tokens will be stored in this.tokens and will be returned (if auth is succesful)
   * error will also be returned in promise (catch) if auth failed
   *
   * Implicit flow is not handled because it is not secure enough (tokens are exposed in url)
   */
  startCodeFlow(redirectUri, idpHint, popupConfig) {
    return new Promise((resolve, reject) => {
      const popupDefault = {
        title: "Connectez-vous",
        features: "menubar=no, status=no, scrollbars=no, width=600, height=600",
      }
      const popup = Object.assign({}, popupDefault, popupConfig)
      const url = this.createAuthUrl(redirectUri, null, null, idpHint)
      console.log("url", url)
      const w = window.open(url, popup.title, popup.features)

      // détecter la fermeture de la popup
      let closedInterval = undefined;
      closedInterval = setInterval(() => {
        if(!w.closed) return;
        reject({error: 'closed_by_user'})
        clearInterval(closedInterval)
      }, 200);



      const retrieveToken = e => {

        console.log("e.data", e.data)
        if (e.origin !== window.origin) return;
        if (e.data.code === undefined) reject({error: "code_not_found"})

        clearInterval(closedInterval)
        w.close(); // fermeture de la popup

        if(e.data.code) {
          this.codeToToken(e.data.code, redirectUri)
          .then(data => {

            console.log("codeToToken >", data)

            if(!data.access_token) return reject(data) //auth failed
            this.setTokens(data)
            resolve(data)
          })
          .catch(err => reject(err))
        }
      }

      window.addEventListener("message", retrieveToken, {once: true})
    })
  }

  /**
   * exchange the code for tokens
   * https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/token-exchange/token-exchange.adoc
   * @param {string} code
   * @param {string} redirectUri used to get the token
   */
  codeToToken(code, redirectUri) {

    console.log("codeToToken", redirectUri)
    return fetch(this.endpoints.token, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=authorization_code&client_id=${this.config.clientId}&code=${code}&redirect_uri=${redirectUri}`
    }).then(res => res.json())
  }


  //----------------------  API FLOW  ----------------------//

  login(username, password) {
    return fetch(this.endpoints.token, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `client_id=${this.config.clientId}&grant_type=password&username=${username}&password=${password}`})
        .then(res => res.json())
        .then(tokens => this.parseTokens(tokens))
  }

  logout() {
    if (!this.tokens) {
      console.error("no tokens were found, user might not be connected")


      return Promise.resolve()

    }

    const queryParams = [
      `client_id=${this.config.clientId}`,
      `refresh_token=${encodeURIComponent(this.tokens.refresh_token)}`,
    ];

    return fetch(this.endpoints.logout, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryParams.join('&'),
    }).then(() => {
      this.tokens = undefined
      window.localStorage.removeItem(window.CONFIG.keycloak.tokenStorageKey)
    })
  }

  /**
   * example of data retrieved: {
   *  "sub": "9ec1a56b-a6e8-459d-a9ec-3bd5766c6d30",
   *  "email_verified": false,
   *  "name": "Thomas Le Floch",
   *  "preferred_username": "thomas.lefloch@succubus.fr",
   *  "given_name": "Thomas Le",
   *  "family_name": "Floch",
   *  "email": "thomas.lefloch@succubus.fr"
   *}
   */
  fetchUserInfo() {
    if (!this.tokens) return new Promise((resolve, reject) => reject("no tokens were found, user might not be connected"))

    return fetch(this.endpoints.userInfo, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${this.tokens.access_token}`,
        Accept: 'application/json',
      }
    }).then(res => res.json())
  }

  /**
   * exemple of data retrieved: {
   *  "username": "thomas.lefloch@succubus.fr",
   *  "firstName": "Thomas Le",
   *  "lastName": "Floch",
   *  "email": "thomas.lefloch@succubus.fr",
   *  "emailVerified": false,
   *  "attributes": {}
   *}
   */
  fetchUserProfile() {
    if (!this.tokens) return new Promise((resolve, reject) => reject("no tokens were found, user might not be connected"))


    return fetch(this.endpoints.userProfile, {
      method: 'GET',
      headers: {
        Authorization: `bearer ${this.tokens.access_token}`,
        Accept: 'application/json',
      }
    }).then(res => res.json())
  }

  refreshAccessToken(refreshToken) {
    return fetch(this.endpoints.token, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=refresh_token&refresh_token=${refreshToken || this.tokens.refresh_token}&client_id=${this.config.clientId}`
    }).then(res => res.json())
    .then(tokens => this.parseTokens(tokens))
  }

  setTokens(tokens) {
    window.localStorage.setItem(window.CONFIG.keycloak.tokenStorageKey, JSON.stringify(tokens))
    this.tokens = tokens
  }

  // TODO: handle user cancel all sessions
  retrieveTokens() {
    const savedTokens = JSON.parse(window.localStorage.getItem(window.CONFIG.keycloak.tokenStorageKey))
    if (!savedTokens) return;

    const decodedAccessToken = jwt_decode(savedTokens.access_token)
    if (decodedAccessToken.exp * 1000 > Date.now()) { // access_token still good
      this.tokens = savedTokens;
      return
    }

    const decodedRefreshToken = jwt_decode(savedTokens.refresh_token)
    if(decodedRefreshToken.exp * 1000 > Date.now()) { // access_token expired, but refresh_token still good
      this.refreshAccessToken(savedTokens.refresh_token)
      return
    }

    window.localStorage.removeItem(window.CONFIG.keycloak.tokenStorageKey) //both expired
  }

  parseTokens(data) {
    if(!data.access_token) throw data
    this.setTokens(data);
    return data
  }
}