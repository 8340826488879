
import LEP from '@jeanbenitez/logical-expression-parser'
import { makeAutoObservable, reaction } from "mobx"
import AppState from './AppState'
import Cards from "./Cards"
import Categories from "./Categories"
import Criterias from "./Criterias"
import Projects from "./Projects"
import Sounds from './Sounds'

export const CARDS_STATE = {
  PICKABLE: "PICKABLE",
  TOO_COMPLEX: "TOO_COMPLEX",
  LOCKED: "LOCKED",
  PICKED_PREV: "PICKED_PREV",
  PICKED_NOW: "PICKED_NOW",
}


class Card {

  // phase 2
  ph2_picked = false


  // phase 3
  picked = false
  pickedIndex = null

  constructor (json) {
    Object.assign(this, json)
    this.id = this.id.toString() // j'ai eu des conversions sauvages en int!

    // NOTE : release la carte si elle se relocke (on a déselectionné la carte qui l'unlockait par exemple)
    reaction( () => this.locked, () => {this.onLockedChange() })
    makeAutoObservable(this)
    this.verify()
  }

  verify() {
    // check categ exists
    if(this.category && !Categories.get(this.category)) {
      console.warn("carte", this.id, "mauvaise categ", this.category);
    }
  }

  get prerequisites_satisfied() {
    return this.prerequisites ? LEP.parse(this.prerequisites, p => Cards.boughtCardsIds.includes(p)) : true
  }
  get is_excluded() {
    return this.is_excluded_if ? LEP.parse(this.is_excluded_if, p => Cards.boughtCardsIds.includes(p)) : false
  }

  get locked() {
    return !this.prerequisites_satisfied || this.is_excluded
  }

  get locked_reasons() {

    if(!this.locked) return null

    let pre_arr = []
    let exc_arr = []


    if(!this.prerequisites_satisfied) {

      LEP.parse(this.prerequisites, p => {
        let val = Cards.boughtCardsIds.includes(p)
        if(!val) pre_arr.push(p)
        return val
      })
      // let pre_req = LEP.ast(this.prerequisites)

    }

    if(this.is_excluded_if) {
      LEP.parse(this.is_excluded_if, p => {
        let val = Cards.boughtCardsIds.includes(p)
        if(val) exc_arr.push(p)
        return val
      })
    }


    let ob =  {
      needs: pre_arr,
      locks: exc_arr
    }
    if(this.id === "8_BIS_J") {
      console.log(ob)
    }

    return ob






  }
  onLockedChange() {
    if(this.locked && this.picked !== false) {
      this.release()
    }
  }

  pick() {
    Sounds.play("add_card")
    this.picked = true
    // AppState.saveGame()
  }
  release() {
    Sounds.play("suppri_card")
    this.picked = false
    // AppState.saveGame()
  }


  get isBonus() { return this.type === "BONUS" }
  get isMalus() { return this.type === "MALUS" }
  get isCombo() { return this.isBonus || this.isMalus }

  get isOmission() { return this.type === "OMISSION" }
  get comboRefIds() { return this.isCombo && this.id.split("+") }
  get tooComplex() { return Projects.selected.currentComplexity + this.complexity > Projects.selected.max_complexity }


  get criterias() {
    return Criterias.current.map(c => ({id: c.id, value: this[c.id], name: c.name}))
  }

  get saveData() {
    return {
      id: this.id,
      type: this.type,
      ph2_picked: this.ph2_picked,
      picked: this.picked,
      pickedIndex: this.pickedIndex
    }
  }

  loadData(data) {
    this.ph2_picked = data.ph2_picked
    this.picked = data.picked
    this.pickedIndex = data.pickedIndex
  }
}
export default Card;
