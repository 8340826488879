import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react'
import AppState from '../../../../js/AppState';
import SGMSClient from '../../../../js/SGMS/SGMSClient';
import Texts from '../../../../js/Texts';
import Button from '../../../Shared/Button/Button';
import Input from '../../../Shared/Input/Input';
import { EnterButton } from '../../../Shared/Shared';

import './GroupCode.css'

const GroupCode = () => {
  // const [state, setState] = useState(null);
  const [showGroupCodeInput, setshowGroupCodeInput] = useState(false);

  const [error, setError] = useState(null);
  function handleSubmit(e) {
    e.preventDefault()


    const data = new FormData(e.target)
    const code = data.get('code')

    const login = SGMSClient.currentUser.UserLogin

    SGMSClient.meetingGetWithCode(code, login)
    .then(meeting => SGMSClient.meetingConnect(meeting))
    .then(() => {
      // ici on est connect au meeting
      setshowGroupCodeInput(false)
      console.log("meeting", toJS(SGMSClient.currentMeeting))
      AppState.registerMeetingsEvents()
      AppState.meetingSendScores()
    })
    .catch(err => {
      console.log("err", err)
      setError("An error happened while connecting to group")
    })

  }

  function handleCancel() {
    setshowGroupCodeInput(false)
  }

  function handleDisconnect(){
    SGMSClient.meetingDisconnect(SGMSClient.currentMeeting)
    .then(() => {
      console.log("disconnected")
      setshowGroupCodeInput(false)

    })
    .catch(err => {
      console.log("err", err)
    })
  }

  return (
    <div className="GroupCode">
      {!showGroupCodeInput && !SGMSClient.currentMeeting &&
      <button onClick={() => setshowGroupCodeInput(v => !v)} className='link-button'>{Texts.get("group_code")}</button>}

      {!showGroupCodeInput && SGMSClient.currentMeeting &&
      <div>
        {Texts.get("connected_to_group")} <strong>{SGMSClient.currentMeeting.MeetingName}</strong>
        <button className='link-button' onClick={handleDisconnect}>{Texts.get("menu_btn_disconnect")}</button>
      </div>}

      {showGroupCodeInput &&
      <form className='group-code-form' onSubmit={handleSubmit}>

        <Input id="code" />
        {error && <div className="error">{error}</div>}
        <div className='group-code-btns'>
          <Button type="reset" onClick={handleCancel} className="group-code-cancel" pill smallShadow>{Texts.get("cancel")}</Button>
          <EnterButton smallShadow className="group-code-valid " />
        </div>


      </form>}

    </div>
  );
};

export default observer(GroupCode)
