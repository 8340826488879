import React from 'react'
import Button from '../../Shared/Button/Button'
import close_icon from "../../../images/close.svg"

import './TextPopup.css'

const TextPopup = ({title, content, onClose}) => {

  return (
  <div className="TextPopup-ctnr">
    <div className="TextPopup">
      <Button pill className="TextPopup-close" onClick={onClose}>
        <img src={close_icon} alt="" />
      </Button>

      <div className="TextPopup-content">
        <div className="TextPopup-title" dangerouslySetInnerHTML={{__html: title}} />
        <div className="TextPopup-text" dangerouslySetInnerHTML={{__html: content}} />
      </div>
    </div>
  </div>)
}

export default TextPopup;
