import { observer } from 'mobx-react-lite';
import React from 'react';
import AppState from '../../js/AppState';
import FtueManager from '../../js/FtueManager';
import BoussolePopup from '../BoussolePopup/BoussolePopup';
import Ftue from '../Ftue/Ftue';
import './PopupsContainer.css';


const PopupsContainer = () => {
  return (
    <div className="PopupsContainer">
      {AppState.showBoussolePopup && <BoussolePopup /> }
      {FtueManager.visible && <Ftue />}
    </div>
  );
};

export default observer(PopupsContainer)
