import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import AppState from '../../../js/AppState';
import Places from '../../../js/Places';
import SequencesManager from '../../../js/SequencesManager/SequencesManager';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import BurgerMenu from '../../Shared/BurgerMenu/BurgerMenu';
import DialFocus from '../DialFocus/DialFocus';
import Phase1Map from '../Phase1Map/Phase1Map';
import ProjectChoicePopup from '../ProjectChoicePopup/ProjectChoicePopup';
import './HomePhase1.css';
import SGMSClient from '../../../js/SGMS/SGMSClient';

import Button from '../../Shared/Button/Button';

import skip from "../../../images/skip.svg"
import Ph1SkipDialsPopup from '../Ph1SkipDialsPopup/Ph1SkipDialsPopup';
import Axes from '../../../js/Axes';
import Sounds from '../../../js/Sounds';
import DebugZone from '../../DebugZone/DebugZone';

const HomePhase1 = () => {
  let currentMessage = SequencesManager.getCurrentElement()

  const [loading, setLoading] = useState(true);
  const [showDials, setShowDials] = useState(false);
  const [refreshToken, setRefreshToken] = useState(1);
  const [text, setText] = useState("");
  const {audios_path} = window.CONFIG.speech
  const [showSkipPopup, setShowSkipPopup] = useState(false);
  const [showChoices, setShowChoices] = useState(false);
  const [debugSkipDials, setDebugSkipDials] = useState(false);
  const [canGoNext, setCanGoNext] = useState(null); // null |  true | false

  useEffect(() => {
    if(Axes.selected) Sounds.playMusic("loop3")
    else Sounds.playMusic("loop2")
  }, []);

  // DIALS STUFF
  const openflDivRef = useRef(window.document.querySelector("#openfl_phase1"))
  useEffect(() => {
    // ouvre le premier dial en auto
    if(AppState.numPartie <= 1) {
      let selPlace = Places.all.find(p => p.first_sequence_unlocked && p.notified)
      if(selPlace) handlePlaceClick(selPlace)
      else {setLoading(false)}
    }
    else {setLoading(false)}

    window.SpeechAPI.setTextCallback(text => {
      setText(text)
    })

    return () => {
      window.SpeechAPI.closeCurrentScene()
      SequencesManager.currentSequence = null
    }
  }, []);


  useEffect(() => {
    if(currentMessage) {
      setShowChoices(false)
      setText("")



      let char = currentMessage.raw.Character
      let anim = currentMessage.raw.AttitudeTimings ? currentMessage.raw.AttitudeTimings : currentMessage.raw.Animation
      let text = currentMessage.raw.Text
      let textTimings = currentMessage.raw.TextTimings
      let audio_path = audios_path + currentMessage.raw.Audio

      Places.unlockSequences(currentMessage.raw.unlock_sequences)

      if(debugSkipDials) {
        if(currentMessage.Type === "choice_title") {
          let a_id = currentMessage.getAllChoices()[0].id
          currentMessage.addAnswer(a_id)
        }
        handleNext()
        return
      }


      SGMSClient.analyticsApi.contentShow("dialog.message." + Places.selected.id + '_' + currentMessage.id)

      window.SpeechAPI.showContent(char, text, textTimings, anim, audio_path, () => {
        if(currentMessage.Type !== "choice_title") handleNext() // passage auto à la suite
        else setShowChoices(true)
      })
    }

  }, [currentMessage]);


  // a la fermeture de la boussole, si il fallait faire next, on le fait
  useEffect(() => {
    if(!AppState.showBoussolePopup) {

      // canGoNext peut être null, true ou false.
      // Si null : pas de next. Sinon oui, mais soit dial suivant, soit fin de la conversation
      if(canGoNext !== null) {
        reallyGoNext(canGoNext)
      }

      setCanGoNext(null)
    }
  }, [AppState.showBoussolePopup]);

  // gestion de l'affichage ou pas du canvas de dials openfl
  useEffect(() => {

    if(showDials) {
      openflDivRef.current.style.opacity = 1
      openflDivRef.current.style.pointerEvents = "auto"
    } else {
      openflDivRef.current.style.opacity = 0
      openflDivRef.current.style.pointerEvents = "none"
    }


    return () => {
      openflDivRef.current.style.opacity = 0
      openflDivRef.current.style.pointerEvents = "none"
    }
  }, [showDials]);


  useEffect(() => {
    if (Places.projectChoicePopupVisible ) {
      closeDials()
    }
  }, [Places.projectChoicePopupVisible])


  function handlePlaceClick(place) {
    if(!place.notified) return

    setLoading(true)

    Places.select(place)

    place.openDials()
    .then(()=> {
      setLoading(false)
      setShowDials(true)
      setRefreshToken(t => t+1)
    })
  }

  function handleMessageClick(choice) {

    if(!choice) window.SpeechAPI.skipSegment()
    else {
      setShowChoices(false)
      handleNext()
    }

  }

  function handleNext() {

    setText("")
    let hasNextDial = SequencesManager.hasNext() // ceci peut afficher la boussole

    if(!AppState.showBoussolePopup) { // si elle est affichée, on ne fait pas next()
      reallyGoNext(hasNextDial)
    } else {
      setCanGoNext(hasNextDial) // on enregistre le fait qu'il fallait faire next
    }

  }

  function reallyGoNext(hasNextDial) {
    if(hasNextDial) {
      SequencesManager.next()
      setRefreshToken(t => t+1)
    } else {
      setDebugSkipDials(false)
      closeDials()
    }
  }

  function closeDials(cb) {
    window.SpeechAPI.closeCurrentScene(() => {


      if(Places.selected) {
        Places.selected.notified = false
        SGMSClient.analyticsApi.navigationLeave("ph1.place." + Places.selected.id)

      }

      SequencesManager.currentSequence = null // obligé de faire ca pour fermer la séquence !
      setRefreshToken(t => t+1)
      setShowDials(false)
      AppState.saveGame()

      if(cb) cb()
    })
  }

  function _skip() {

    setShowSkipPopup(false)

    Places.all.forEach(p => {
      p.forceUnlocked = true
      p.notified = false
    })

    Places.projectChoicePopupVisible = 1

  }
  function _skip2() {
    Places.projectChoicePopupVisible = 2
  }

  return (
    <div className="HomePhase1">
      {!currentMessage && <Phase1Map onClick={handlePlaceClick} />}

      {/* la div openfl est dans index.html => ca permet de l'instancier une seule fois */}

      {currentMessage && text !== "" &&
      <DialFocus
      currentText={showChoices ? currentMessage.raw.Text : text}
      message={currentMessage}
      showChoices={showChoices}
      onClick={handleMessageClick} />}

      {Places.projectChoicePopupVisible && <ProjectChoicePopup />}


      <DebugZone>
        {/* <button className="skip-button" onClick={() => {setDebugSkipDials(true); handleNext();}}>Skip to the end</button> */}
        <button className="skip-button" onClick={_skip}>Choose an axis</button>
        {Axes.selected && <button className="skip-button" onClick={_skip2}>choose a project</button>}
      </DebugZone>

      <BurgerMenu />

      {AppState.numPartie > 1 && !Axes.selected && !Places.projectChoicePopupVisible &&
      <Button className="skip-dials-btn" pill onClick={() => setShowSkipPopup(true)}>
        <img src={skip} alt="skip" />
      </Button>}

      {showSkipPopup && <Ph1SkipDialsPopup onYes={_skip} onNo={() => setShowSkipPopup(false)} /> }



      {loading && <LoadingScreen />}
    </div>
  );
};

export default observer(HomePhase1)
