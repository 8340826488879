import React from 'react';
import Criterias from '../../../js/Criterias';
import CriteriaIcon from '../CriteriaIcon/CriteriaIcon';
import './Boussole.css';


const Boussole = ({selectedIds = "all", size}) => {
  return (
    <div className="Boussole">
      {Criterias.ids
      .map(cId =>
      <CriteriaIcon id={cId} key={cId} size={size} selected={selectedIds === "all" ? true : selectedIds.includes(cId)} /> )}
    </div>
  );
};

export default Boussole
