import React, { useRef, useState } from 'react';
import AppState, { SCREENS } from '../../../js/AppState';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Texts from '../../../js/Texts';
import Input from '../../Shared/Input/Input';
import { EnterButton } from '../../Shared/Shared';


export default () => {
  const [error, setError] = useState(null);
  const formRef = useRef(null)

  function handleSubmit (e) {
    e.preventDefault()
    setError(null)

    const data = new FormData(formRef.current)

    const email = data.get('email')
    const password = data.get('password')
    const {Universe, App} = window.CONFIG.server

    SGMSClient.userDisconnect()
    .then(() => SGMSClient.login(Universe, App , email, password))
    .then(() => AppState.setScreen(SCREENS.MENU))
    .then(() => AppState.checkProfileSavegame())
    .catch(err => setError(err))
  }

  return (
  <div className='LoginForm'>
    <form onSubmit={handleSubmit} ref={formRef}>
      <div>
        <Input id="email" type="email" label={Texts.get("menu_form_mail")}/>
      </div>

      <div>
        <Input id="password" type="password" label={Texts.get("menu_form_password")}/>
      </div>

      {error && <div className="LoginForm-error">{error}</div>}
      <EnterButton />
    </form>

  </div>)
}