import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import Categories from '../../../js/Categories';
import CardsList from '../CardsList/CardsList';
import './Category.css';

const Category = ({categ}) => {
  const elemRef = useRef(null)

  const [descriptionVisible, setDescriptionVisible] = useState(false);

  const selected = Categories.ph3_selected === categ
  useEffect(() => {
    if(selected && Categories.ph3_disableSpy) {
      elemRef.current.scrollIntoView({behavior: "smooth"});
    }
  }, [selected]);


  useEffect(() => {
    let categoriesCtnrElement = elemRef.current.parentElement
    let obs = new IntersectionObserver((elems) => {
      let d = elems[0]
      if(d.isIntersecting && !Categories.ph3_disableSpy) Categories.ph3_select(categ)
    }, {
      root: categoriesCtnrElement,
      threshold: 0,
      rootMargin: "-50% 0px"
    })

    obs.observe(elemRef.current)

    return () => { obs.disconnect() }
  }, []);


  function handleDescriptionBtnClick(e) {
    setDescriptionVisible(e.target.getBoundingClientRect())
  }

  let style = {}
  if(descriptionVisible) {
    style = {
      top: descriptionVisible.top,
      right: document.body.offsetWidth - descriptionVisible.left - descriptionVisible.width,
    }
  }


  let cards = categ.playableCards
  if(cards.length === 0) return null

  return (
    <div className={"Category" + (selected ? " selected" : "")}  ref={elemRef}>
      {descriptionVisible &&
      <div className="description-overlay" onClick={() => setDescriptionVisible(false)}>
        <div className="category-description" style={style} onClick={(e) => e.stopPropagation()} >
          <div dangerouslySetInnerHTML={{__html: categ.description}} />
          <div className="close" onClick={() => setDescriptionVisible(false)}>&times;</div>
        </div>
      </div>}

      <h3>
        {categ.name}
        <span className="description-btn" onClick={handleDescriptionBtnClick}>?</span>
      </h3>
      <CardsList cards={cards} />


    </div>
  );
};

export default observer(Category)
