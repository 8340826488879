import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import Characters from '../../../js/Characters';
import Places from '../../../js/Places';
import ScaleManager from '../../../js/ScaleManager';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import './Phase1Map.css';

const Phase1Map = ({onClick}) => {

  const mapRef = useRef(null)

  function clickHandler(p) {
    SGMSClient.analyticsApi.action("ph1.btn.map." + p.id + ".select")
    onClick(p);
  }

  const invertScale = 1 / ScaleManager.scale

  return (
    // <div className="Phase1Map"  ref={mapRef} >
    <div className="Phase1Map" style={ScaleManager.style} ref={mapRef} >
      {Places.all.map(p => {
        let place_style = {left: p.x+"%", top: p.y + "%", transform: `translateZ(0)`}

        let cl = "place"
        cl += p.unlocked ? " unlocked" : ""
        cl += p.notified ? " notified" : ""

        let char = Characters.get(p.correspondant)


        return (
        <div className={cl} style={place_style} key={p.id} onClick={() => { clickHandler(p) }}>
          <img className="active_img" src={`./data/images/ph1map/${p.id}.png`} alt=""  />
          <img className="inactive_img" src={`./data/images/ph1map/disabled_${p.id}.png`} alt=""  />
          <div className="place-name" style={{transform: `translateX(-50%) scale(${invertScale}) `}}>
            {p.notified && <div className="profile-pic" style={{backgroundImage: `url(${char.profile_pic_url})` }} />}
            {p.unlocked && !p.notified && <span>✓</span>}
            {p.name}
          </div>
        </div>)
      })}


      {/* <div className="trees" /> */}
    </div>
  );
};

export default observer(Phase1Map)
