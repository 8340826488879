import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import AppState from '../../../js/AppState';
import Cards from '../../../js/Cards';
import Categories from '../../../js/Categories';
import Characters from '../../../js/Characters';
import Card from '../../Shared/Card/Card';
import './Message.css';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import FtueManager from '../../../js/FtueManager';
import Sounds from '../../../js/Sounds';

function getCards(message) {
  if(message.raw.unlock_cards) {
    let ids = message.raw.unlock_cards.split(";")
    let cards = ids.map(cardId => Cards.get(cardId)).filter(c => c !== undefined)

    if(cards.length > 0) return cards
  }
  return null
}

const Message = ({message, showCards, showChoices, isLast, showName, onShown, onClick}) => {
  let char = Characters.get(message.raw.Character)
  const show_dots = char?.position === "left" && Categories.selected?.notified && isLast && !AppState.debugMode


  const [showDots, setShowDots] = useState(show_dots);
  const [mounting, setMounting] = useState(Categories.selected?.notified);

  const unlockedCards = useRef(0)
  const msgref = useRef(null)

  const cards = getCards(message)

  useEffect(() => {
    if(showCards) {
      FtueManager.showSequence("phase2_carte")
      setShowDots(false)
    }

    if(showChoices) {
      setShowDots(false)
    }

  }, [showCards, showChoices]);

  useEffect(() => {
    if(!isLast) setShowDots(false)
  }, [isLast]);

  useEffect(() => {
    if(char.id === "system") return
    // pour pas avoir tous les sons en même temps qd on réaffiche une categ déja lue
    if(Categories.selected && !Categories.selected.notified) return
    if(!isLast) return

    if(char && char.position === "left" && !showDots) {
      Sounds.play("message_in")
    }

    if(char && char.position === "right") {
      Sounds.play("message_out")
    }

  }, [showDots]);


  // passage auto à la suite si char==system !
  useEffect(() => {
    if(char && char.id === "system" && isLast) {
      onClick()
    }
    else if(AppState.debugMode) {

      setTimeout(() => {
        if(cards) {
          return
          // cards.forEach(c => c.ph2_picked = true)
        }
        if(message.Type === "choice_title") {
          return
          // let a = message.getAllChoices()[0]
          // message.addAnswer(a.id)
        }


        if(isLast) onClick()

      }, 40)

    }
  }, []);


  useEffect(() => { setMounting(false) }, []);

  useEffect(() => {
    if(showDots) {
      let time = Math.min(message.raw.Text.length * 10, 1000)
      let dots_timeout = setTimeout(() => {
        setShowDots(false)
      }, time)
      return () => { clearTimeout(dots_timeout) }
    }
  }, [showDots]);


  // ici on gere quand trigger un scroll
  useEffect(() => {
    if(isLast) onShown()
  }, [showDots, showCards, showChoices]);


  function handleChoiceClick(e, a) {
    e.stopPropagation()

    if(message.userAnswers.length !== 0) return
    SGMSClient.analyticsApi.action("ph2.answer." + message.id + "_" + a.id + ".select")
    SGMSClient.analyticsApi.choice("ph2.answer." + message.id, a.id)
    message.addAnswer(a.id)
    onClick()
  }


  function handleCardClick(card) {
    // console.log("card", card.id, isLast, card.ph2_picked)
    SGMSClient.analyticsApi.action("ph2.card." + card.id + ".select")
    SGMSClient.analyticsApi.score("cardpicked", card.id)

    if(!isLast) return

    if(!card.ph2_picked) {

      Sounds.play("click_card")
      card.ph2_picked = true
      unlockedCards.current++
    }

    // on passe au next quand on a ph2_unlock toutes les cartes de ce message
    if(unlockedCards.current === cards.length) {
      onClick()
    }
  }


  let cl = "Message "
  if(message.Type) cl += message.Type + " "
  if(char?.position) cl += char.position
  if(char) cl += " " + char.id

  if(isLast) cl += " is-last"
  if(isLast && mounting) cl += " mounting"

  let style = {}
  if(char) style.background = char.background_color
  if(char) style.color = char.color


  let imgBg = char ? char.profile_pic_url : null

  return (
    <div className={cl} ref={msgref}>

      {char?.name && !showName &&
      <div className="Message-name">
        {char.name}
      </div>}

      <div className="l2">
        {imgBg && <div className="Message-pic" style={{backgroundImage: `url(${imgBg})`}} />}

        {showDots &&
        <div className="Message-text loading" style={style}>
          <div className="dot" /><div className="dot" /><div className="dot" />
        </div>}

        {!showDots &&
        <div className="Message-text" style={style}>
          {message.raw.Text}
        </div>}

      </div>

      {cards && (showCards || !isLast) &&
      <div className="Message-cards">
        {cards.map(c => {
          let cl = "Message-card"
          cl += c.ph2_picked ? " picked" : ""

          return (
          <div className={cl} onClick={() => handleCardClick(c)} key={c.id} >
            <Card card={c} showPickButton={false} showDesc={false} />
          </div>)
        })}
      </div>}

      {message.Type === "choice_title" && !showDots && isLast && showChoices &&
      <div className="Message-choices">
        {message.getAllChoices()
        .map(a => {
          let answered = message.userAnswers.indexOf(a.id) !== -1
          let disabled = message.userAnswers.length !== 0

          return (
          <div
          key={a.id}
          className={"choice" + (answered ? " answered" : "")}
          disabled={disabled}
          onClick={(e) => handleChoiceClick(e, a)}>
            {a.raw.Text}
          </div>)
        })}
      </div>}



    </div>
  );
}

export default observer(Message)
