import { observer } from 'mobx-react-lite'
import React from 'react'
import { FaBan, FaLock, FaPlus, FaTimes } from 'react-icons/fa'
import FtueManager from '../../../../js/FtueManager'
import SGMSClient from '../../../../js/SGMS/SGMSClient'
import Texts from '../../../../js/Texts'
import { IconButton } from '../../Shared'
import './PickButton.css'

const PickButton = ({card, onLockedClick}) => {

  if(card.locked) {
    return (
    <div className="PickButton PickButton-text locked-button" onClick={onLockedClick}>
      <FaLock className="icon" />
      {Texts.get("locked")}
    </div>
  )}

  function handleSelectCardClick() {
    card.pick()
    FtueManager.emit("CARD_SELECT")
    SGMSClient.analyticsApi.action("ph3.btn.card." + card.id + ".select")
  }

  function handleUnselectCardClick() {
    card.release()
    FtueManager.emit("CARD_UNSELECT")

    SGMSClient.analyticsApi.action("ph3.btn.card." + card.id + ".unselect")
  }

  if(card.picked) { return (
    <IconButton className="x-button" onClick={handleUnselectCardClick}>
      <FaTimes />
    </IconButton>
  )}

  if(card.tooComplex) {
    return (
    <div className="PickButton PickButton-text too-complex-button">
      <FaBan className="icon" />
      {Texts.get("complexity_too_high")}
    </div>
  )}

  return (
    <IconButton className='plus-button' onClick={handleSelectCardClick}>
      <FaPlus />
    </IconButton>
  )
}

export default observer(PickButton)
