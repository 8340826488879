import { makeAutoObservable, toJS } from "mobx"
import AppState from "./AppState"
import Category from "./Category"
import Projects from "./Projects"
import SequencesManager from "./SequencesManager/SequencesManager"
import SGMSClient from "./SGMS/SGMSClient"

class Categories {
  selected = null

  constructor() {
    makeAutoObservable(this)
  }

  init(categs) {
    this.all = categs
    .filter(c => c.id !== null)
    .filter(c => c.project === Projects.selected.id || c.project === null) // null pour les transverse
    .map(c => new Category(c))
  }

  loadDialsFiles(axeId) {
    let proms = this.all.map(categ => categ.loadDialFile(axeId))
    return Promise.all(proms)
  }

  get(id) {
    return this.all.find(c => c.id === id)
  }

  ph2_select(category) {
    SGMSClient.analyticsApi.action("ph2.btn.category." + category.id + ".select")

    if(category && category.notified) {
      SequencesManager.playFile(category.id, category.lastUnlockedSequence)
    }
    this.selected = category
  }

  unlockSequences(data) {
    if(!data) return
    let unlocks = data.split(";")

    unlocks.forEach(unlock => {
      let [categId, SequenceID] = unlock.split("/")

      let categ = this.get(categId)
      if(categ) categ.unlockSequence(SequenceID)
    })
  }

  get notifiedQty() {
    return this.all.filter(c => c.notified).length
  }

  /*** phase 3 */

  ph3_selected = null
  ph3_disableSpy = false
  disableSpyTimeout = null
  ph3_select(category, disableSpy = false) {
    if(disableSpy) {
      SGMSClient.analyticsApi.action("ph3.btn.category." + category.id + ".select")

      this.ph3_disableSpy = true
      clearTimeout(this.disableSpyTimeout)
      this.disableSpyTimeout = setTimeout(() => {
        this.ph3_disableSpy = false

      }, 1000)
    }

    this.ph3_selected = category
  }


  save() {
    return {
      selected: this.selected ? this.selected.id : null,
      all: this.all && this.all.map(c => c.saveData),
    }
  }

  load(data) {
    if(!data) return
    data.all.forEach(savedCateg => {
      let categ = this.get(savedCateg.id)
      if(categ) {
        categ.notified = savedCateg.notified
        categ.unlockedDialsSequences = savedCateg.unlockedDialsSequences
        categ.readDialsSequences = savedCateg.readDialsSequences
        categ.sequencesQty = savedCateg.sequencesQty
      }
    })

    this.selected = this.get(data.selected)
  }



}


export default new Categories()