import {Howl, Howler} from 'howler';

class Sound {
  sound = null
  constructor(json) {
    Object.assign(this, json)

    this.path = this.path.split(";")

    this.sound = new Howl({
      src: this.path,
      loop: this.loop,
      volume: this.volume ? this.volume : 1,

      onloaderror: () => {console.log("error loading sound", this.id)},
      onplayerror: (err) => {console.log("error playing sound", this.id, err)}
    });

  }

  play() {
    if(!this.sound.playing()) {
      this.sound.play()
      return this
    }
  }
  stop() {
    this.sound.stop()
  }

}


class Sounds {
  all = []

  currentsound = null
  currentMusic = null

  init(json) {
    this.all = json
    .filter(elem => elem.id).map(elem => new Sound(elem))
  }

  get ids() { return this.current.map(c => c.id)}


  get(id) { return this.all.find(c => c.id === id) }


  play(id) {
    let s = this.get(id)

    if(s) {
      this.currentsound = s.play()
    } else {
      console.log("Sound", id, "does not exist")
    }
  }

  playMusic(id) {
    let s = this.get(id)

    if(this.currentMusic && this.currentMusic.id !== id) this.currentMusic.stop()

    if(s) {
      this.currentMusic = s.play()
    } else {
      console.log("Sound", id, "does not exist")
    }


  }

}


export default new Sounds()