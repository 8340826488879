import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './SidePanel.css';


const SidePanel = ({top, visible=true, children, onClosed}) => {
  const ref = useRef(null)

  return (
  <CSSTransition in={visible} unmountOnExit classNames="SidePanel" nodeRef={ref} timeout={500} onExited={onClosed}>
    <div className="SidePanel" ref={ref}>
      {top && <div className="SidePanel-top">
        {top}
      </div>}
      <div className="SidePanel-content">
        {children}
      </div>
    </div>
  </CSSTransition>
  );
};

export default SidePanel;
