import React, { useEffect } from 'react';
import AppState from '../../js/AppState';
import Criterias, { CRIT_VISIBILITY } from '../../js/Criterias';
import SGMSClient from '../../js/SGMS/SGMSClient';
import Texts from '../../js/Texts';
import Boussole from '../Shared/Boussole/Boussole';
import './BoussolePopup.css';



const BoussolePopup = () => {

  useEffect(() => {


    return () => {
      Criterias.all.forEach(c => c.notified = false)
    }
  }, []);

  function handleClick() {
    AppState.showBoussolePopup = false;

    SGMSClient.analyticsApi.action("helper.btn.typeExplenation.exit.select")
    SGMSClient.analyticsApi.contentHide("typeExplenation")
    SGMSClient.analyticsApi.navigationLeave("popup.typeExplenation")
  }

  if(AppState.showBoussolePopup !== true) {
    Criterias.get(AppState.showBoussolePopup).notified = true
  }

  let selectedIconsIds = Criterias.current.filter(c => c.visible || c.notified).map(c => c.id)

  return (
    <div className="BoussolePopup" onClick={handleClick}>
      <div className="crit-descriptions" style={{width: 300, height: 300}}>
        {Criterias.current.map(crit => {
          let id = crit.id

          let className = `crit-desc ${id}`
          if(crit.visible) className += " visible"
          if(crit.notified) className += " notified"

          return (
          <div className={className} key={id}>
            <div className="crit-desc-title">{Texts.get(`criteria_${id}_title`)}</div>
            <div className="crit-desc-content">
              {Texts.get(`criteria_${id}_description`)}
            </div>
          </div>)
        })}
      </div>


      <Boussole size={150} selectedIds={selectedIconsIds} />


    </div>
  );
};

export default BoussolePopup;
