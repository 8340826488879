import React, { useState } from 'react'

import './DebugZone.css'

const DebugZone = ({children}) => {
  const [visible, setVisible] = useState(false);
  return (
    <div
    onMouseLeave={() => setVisible(false)}
    className={"DebugZone" + (visible ? " visible" : "")} onClick={() => setVisible(true)}>
      {visible && children}
    </div>
  );
};

export default DebugZone;
