import { observer } from 'mobx-react-lite';
import React from 'react';
import Projects from '../../../../js/Projects';
import Texts from '../../../../js/Texts';
import { AnimatedNumber } from '../../../Shared/Shared';
import './Header.css';


const Header = () => {

  let complexity = Projects.selected.currentComplexity
  let totalComplexity = Projects.selected.max_complexity

  const percentage = (complexity * 100 / totalComplexity)

  const barStyle = {
    width: `calc(150px + ${percentage}%)`
  }




  return (
    <div className="Header">
      <div className="Header-text"> {Texts.get("ph3_header-text")} <span>
      <AnimatedNumber duration={2000} value={complexity} formatValue={v => v.toFixed(0)} />/{totalComplexity} </span></div>
      <div className="progress-ctnr">
        <div className="progress-bar" style={barStyle} ></div>
      </div>
    </div>
  );
};

export default observer(Header)
