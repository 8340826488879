import React, { useRef, useState } from 'react';
import AppState, { SCREENS } from '../../../js/AppState';
import SGMSClient from '../../../js/SGMS/SGMSClient';
import Texts from '../../../js/Texts';
import Input from '../../Shared/Input/Input';
import { EnterButton } from '../../Shared/Shared';


export default ({onDone}) => {
  const formRef = useRef(null)
  const [error, setError] = useState(null);

  function handleSubmit (e) {
    e.preventDefault()
    setError(null)

    const data = new FormData(formRef.current)

    const email = data.get('email')
    const password = data.get('password')
    const confirmPassword = data.get('confirm-password')
    const pseudo = data.get('pseudo')

    if(password !== confirmPassword) {
      setError("The 2 password fields don't match")
      return
    }


    const {Universe} = window.CONFIG.server

    SGMSClient.userCreate(Universe, email, password, pseudo)
    .then(() => {
      onDone()
    }).catch(err => {
      setError(err)
    })

  }

  return (
  <div className='LoginForm'>
    <form onSubmit={handleSubmit} ref={formRef}>
      <div>
        <Input id="email" type="email" label={Texts.get("menu_form_mail")}/>
      </div>

      <div>
        <Input id="pseudo" type="text" label={Texts.get("menu_form_pseudo")}/>
      </div>

      <div>
        <Input id="password" type="password" label={Texts.get("menu_form_password")}/>
      </div>

      <div>
        <Input id="confirm-password" type="password" label={Texts.get("menu_form_confirm_password")}/>
      </div>
      {error && <div className="LoginForm-error">{error}</div>}
      <EnterButton />
    </form>


  </div>)
}