import { makeAutoObservable, toJS } from "mobx"
import Card from "./Card"

class Cards {
  all = []

  constructor() {
    makeAutoObservable(this)
  }

  init(cards) {
    this.all = cards
    .filter(c => c.id !== null)

    .map(c => new Card(c))
  }
  releaseAll() {
    this.all.forEach(c => c.release())
  }

  get(id, type=null) {
    return this.all.find(c => c.id === id && c.type === type)
  }


  get oppositeCards() {return this.all.filter(c => c.isOmission)}
  get comboCards() { return this.all.filter(c => c.isCombo) }

  get boughtCards() { return this.all.filter(c => c.picked) }

  get boughtCardsIds() { return this.boughtCards.map(c => c.id) }
  get boughtCombos() { return this.comboCards.filter(c => c.picked) }



  save() {
    return {
      all : this.all.map(c => c.saveData)
    }
  }

  load(data) {
    if(!data) return
    data.all.forEach(savedCard => {
      let c = this.get(savedCard.id, savedCard.type)
      if(c) {
        c.loadData(savedCard)
      }
    })
  }
}

export default new Cards()