import React from 'react';
import './Button.css';


const Button = ({children, onClick, type="", disabled=false, className="", style={}, pill=false, smallShadow=false}) => {
  // const [state, setState] = useState(null);
  let cl = "Button " + className
  cl += pill ? " pill" : ""
  cl += smallShadow ? " small-shadow" : ""

  return (
    <button className={cl} type={type} disabled={disabled} onClick={onClick} style={style}>
      {children}
    </button>
  );
};

export default Button;
